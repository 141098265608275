import { Flex, Box } from "rebass/styled-components";
import { useEffect, useRef } from "react";
import lottiejs from "./lottie";
import animationData from "./animations";
import { rgba } from "polished";

interface Props {
  [x: string]: any;
}

const Loader = (props: Props) => {
  const { ratio, opacity, sx, ...rest } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      var params = {
        container: ref.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData
      };
      lottiejs.loadAnimation(params);
    }
  }, [ref]);

  return (
    <Flex
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: rgba(255, 255, 255, opacity),
        ...sx
      }}
      {...rest}
    >
      <Box
        ref={ref}
        overflow="hidden"
        sx={{
          marginLeft: `calc(${100 - ratio}% /2)`,
          marginRight: `calc(${100 - ratio}% /2)`,
          opacity: 1,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
      />
    </Flex>
  );
};

Loader.defaultProps = {
  ratio: "20",
  opacity: 0.8
};

export default Loader;

import { Text } from "rebass/styled-components";
import Trans from "next-translate/Trans";
import Link from "./Link";

interface Terms {
  i18nKey: string;
}

const TermsOfServiceAgreement = (props: Terms) => {
  return (
    <Text
      as="p"
      sx={{
        color: "labradorBlack",
        "a:-webkit-any-link": {
          color: "springGreen"
        }
      }}
    >
      <Trans
        {...props}
        components={{ 1: <Link href="/terms" target="_blank" /> }}
      />
    </Text>
  );
};

export default TermsOfServiceAgreement;

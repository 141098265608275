import { Flex, Box } from "rebass/styled-components";
import { useRouter } from "next/router";
import Link from "next/link";
import Icon from "./Icon";
import { useContext } from "react";
import { MetaContext } from "../meta";
import parse from "url-parse";
import Head from "next/head";

interface Props {
  totalPages: number;
  currentPage?: number;
  maxPages: number;
  onPaginate?(any): any;
  children?: React.ReactNode;
}

interface PaginationItemProps {
  page: number;
  active?: boolean;
  children: React.ReactNode;
  [x: string]: any;
  showPaginationMetaLink?: boolean;
}

const Pagination = (props: Props) => {
  const router = useRouter();

  const { totalPages, maxPages, onPaginate } = props;
  let { currentPage } = props;

  if (currentPage) {
    if (currentPage < 1) {
      currentPage = 1;
    }
  } else {
    currentPage = 1;
  }

  let startPage: number, endPage: number;
  if (totalPages <= maxPages) {
    startPage = 1;
    endPage = totalPages;
  } else {
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    i => startPage + i
  );

  const PaginationItem = ({
    page,
    sx = {},
    active = false,
    showPaginationMetaLink,
    ...props
  }: PaginationItemProps) => {
    const params = {
      page: page > 1 ? page : undefined
    };

    const { onPaginate } = props;

    const parsedUrl = parse(router.asPath, true);

    let queryParams = router ? { ...router.query, ...params } : params;
    const routeParams = { ...parsedUrl.query, ...params };

    routeParams &&
      Object.keys(routeParams).forEach(
        key => routeParams[key] == null && delete routeParams[key]
      );

    let rel = null;

    if (page === currentPage - 1) {
      rel = "prev";
    } else if (page === currentPage + 1) {
      rel = "next";
    }

    let link;
    if (onPaginate === undefined) {
      const routeName = parsedUrl ? parsedUrl.pathname : "/";
      link = (
        <Link shallow href={{ pathname: routeName, query: routeParams }}>
          <a rel={rel}>{props.children}</a>
        </Link>
      );
    } else {
      link = (
        <a
          rel={rel}
          onClick={() => {
            onPaginate(page);
          }}
        >
          {props.children}
        </a>
      );
    }

    if (page <= 0 || page > totalPages) {
      link = <a>{props.children}</a>;
    } else if (page == currentPage) {
      link = <a rel="current">{props.children}</a>;
    }

    return (
      <>
        {rel == "prev" &&
          onPaginate === undefined &&
          showPaginationMetaLink &&
          page >= 1 && (
            <Head key="prev">
              <link rel="prev" href={parsedUrl.href} />
            </Head>
          )}
        {rel == "next" &&
          onPaginate === undefined &&
          showPaginationMetaLink &&
          page <= totalPages && (
            <Head key="next">
              <link rel="next" href={parsedUrl.href} />
            </Head>
          )}
        <Box
          as="li"
          fontSize={[2, 5]}
          className={active && "active"}
          sx={{
            borderWidth: "1px 0px 1px 1px",
            width: "48px",
            height: "48px",
            boxSizing: "border-box",
            borderColor: "gray",
            borderStyle: "solid",
            textAlign: "center",
            "&.active": {
              bg: "springGreen",
              borderColor: "springGreen",
              a: {
                color: "samoyedWhite"
              }
            },
            a: {
              color: "labradorBlack",
              textDecoration: "none",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              display: "block",
              lineHeight: "48px",
              fontSize: 2
            },
            ...sx
          }}
        >
          {link}
        </Box>
      </>
    );
  };

  const prev = () => {
    const page = currentPage - 1;
    return (
      <PaginationItem
        page={page}
        key={page}
        onPaginate={onPaginate}
        sx={{
          borderRadius: "paginationPrev",
          borderWidth: "1px 0px 1px 1px"
        }}
        showPaginationMetaLink
      >
        <Icon name="prev" fontSize={0}></Icon>
      </PaginationItem>
    );
  };

  const next = () => {
    const page = currentPage + 1;
    return (
      <PaginationItem
        page={page}
        key={page}
        onPaginate={onPaginate}
        sx={{
          borderRadius: "paginationNext",
          borderWidth: "1px 1px 1px 1px"
        }}
        showPaginationMetaLink
      >
        <Icon name="next" fontSize={0}></Icon>
      </PaginationItem>
    );
  };

  const items = () => {
    let pageItems = [];
    pages.forEach(function(val) {
      pageItems.push(
        <PaginationItem
          page={val}
          key={val}
          onPaginate={onPaginate}
          active={val == currentPage}
        >
          {val}
        </PaginationItem>
      );
    });

    return pageItems;
  };

  if (pages.length > 1) {
    return (
      <Flex
        as="ul"
        flexWrap="nowrap"
        sx={{ listStyle: "none", justifyContent: "center" }}
        mx={1}
        mt={3}
        p={0}
      >
        {prev()}
        {items()}
        {next()}
      </Flex>
    );
  } else {
    return null;
  }
};

export default Pagination;

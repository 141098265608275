
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ErrorProps } from "next/error";
import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text, Heading } from "rebass/styled-components";
import Layout from "../components/Layout";
import Logo from "../components/common/Logo";
import Link from "../components/common/Link";
import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { onError } from "../lib/error-tracking";

function CustomError({ statusCode }: ErrorProps) {
  const { t } = useTranslation("error");

  const isOnClient = !statusCode;

  const boxBgColor =
    isOnClient || statusCode !== 404 ? "inuOrange" : "pugYellow";

  const errorMessage = isOnClient
    ? t("unexpectedError")
    : statusCode === 404
    ? t("notFoundError")
    : t("unexpectedError", { statusCode });

  return (
    <Layout withTransparentHeader immersive hideHeader title={errorMessage}>
      <Flex
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        textAlign="center"
        sx={{
          background: "url('/images/patterns/dog-hair.svg')",
          backgroundRepeat: "repeat",
          backgroundSize: "200px"
        }}
      >
        <Box
          bg={boxBgColor}
          maxWidth="600px"
          minHeight={["100vh", 0]}
          padding="8em 4em"
        >
          <Flex mb={4} justifyContent="center">
            <Logo
              color="samoyedWhite"
              height="60px"
              width="200px"
              name="logo"
            />
          </Flex>

          <Heading
            as="h1"
            color="white"
            fontSize="40px"
            lineHeight="1.5em"
            mb={4}
          >
            {errorMessage}
          </Heading>

          <Link
            href={{
              pathname: "/search"
            }}
          >
            <Text
              color="white"
              sx={{
                color: "springGreen",
                fontWeight: "bold",
                fontSize: 4,
                textDecoration: "underline"
              }}
            >
              {t("goBack")}
            </Text>
          </Link>
        </Box>
      </Flex>
    </Layout>
  );
}

 const _getServerSideProps = ({ res, req, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  if (statusCode != 404) {
    const error =
      err ||
      ({
        errorClass: `Unknown ${statusCode} error`,
        errorMessage: `On: ${req.headers.host + req.url} \nReferer: ${
          req.headers.referer
        }`
      } as NotifiableError);

    Bugsnag.notify(error, onError);
  }

  return { props: { statusCode } };
};

export default CustomError;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/_error',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  
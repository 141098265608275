import { Flex, Image, Text, Box } from "rebass/styled-components";
import theme from "../../lib/theme";

function TopBanner({
  localeCountry,
  isMobile,
  isHome
}: {
  localeCountry: string;
  isMobile: boolean;
  isHome: boolean;
}) {
  let bannerText = "";
  if (!isHome) {
    if (localeCountry === "da")
      bannerText =
        "Dogley er nu en del af Gudog-familien! Du kan logge ind med dit Dogley login. Find en kærlig hundepasser i nærheden af dig ved brug af Gudog. Facebook login er ikke tilgængelig i øjeblikket. Hvis du ønsker at logge ind nu, kan du gøre det med din e-mail og nulstille dit password.";
    if (localeCountry === "no")
      bannerText =
        "Dogley er nå en del av Gudog-familien! Du kan logge inn med Dogley-pålogging. Finn en kjærlig hundepasser i nærheten av dig og Gudog. Pålogging med Facebook er ikke tilgjengelig for øyeblikket. Hvis du vil logge på nå, kan du gjøre det med e-posten din og tilbakestille passordet ditt.";
    if (localeCountry === "sv")
      bannerText =
        "Dogley är nu en del av Gudog-familjen! Du kan logga in med Dogley-inloggning. Hitta en kärleksfull hundvakt i närheten av dig gennom Gudog. Inloggning med Facebook är för tillfället inte tillgängligt. Om du vill logga in nu kan du göra det med din e-postadress och återställa ditt lösenord.";
  } else {
    if (localeCountry === "da")
      bannerText =
        "Dogley er nu en del af Gudog-familien! Du kan logge ind med dit Dogley login.";
    if (localeCountry === "no")
      bannerText = "Du kan logge inn med din Dogley-pålogging.";
    if (localeCountry === "sv")
      bannerText =
        "Dogley är nu en del av Gudog-familjen! Du kan logga in med din Dogley-inloggning.";
  }

  return (
    <Flex variant="topBanner" sx={isMobile && theme.variants.topBannerMobile}>
      <Box
        variant="topBannerImgBox"
        sx={isMobile && theme.variants.topBannerImgBoxMobile}
      >
        <Image src={"/images/home/dogley.webp"} alt="Dogley Banner" />
      </Box>
      <Text ml={[0, 0, 3]}>{bannerText}</Text>
    </Flex>
  );
}

export default TopBanner;

import { useState, useContext, useEffect, ReactElement } from "react";
import { withRouter, NextRouter } from "next/router";
import { Flex, Box, Text } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

import { Icon, Button, Modal } from "./common";
import NavItem from "./common/NavItem";
import NavLink from "./common/NavLink";
import Logo from "./common/Logo";
import Link from "./common/Link";
import { useAuthDispatch, useAuthState } from "./common/AuthContext";
import AvatarBadge from "./common/AvatarBadge";
import publicRuntimeConfig from "../lib/public-runtime-config";
import { CurrentUser, Notification } from "../interfaces";
import { LocaleContext } from "./locale";
import { useIsMobile } from "../lib/media";
import flash from "../lib/flash";
import { UserType } from "../enums";
import { logout as authLogout } from "../lib/auth";
import { useApolloClientWithResetCallback } from "../lib/apolloClient";

interface Props {
  currentUser?: CurrentUser;
  transparent?: boolean;
  immersive?: boolean;
  router: NextRouter;
  PreHeader?: ReactElement;
}

interface NavItemProps {
  currentUser?: CurrentUser;
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile: boolean;
  notifications: Notification;
}

interface NavItemPhoneNumberProps {
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile: boolean;
}

interface NavItemCountrySelectorProps {
  transparent?: boolean;
  immersive?: boolean;
  visibileOnMobile?: boolean;
  isMobile?: boolean;
}

interface NavLinkProps {
  transparent?: boolean;
  immersive?: boolean;
  name: string;
  href: string;
  icon: string;
  visibileOnMobile: boolean;
  notification?: number;
}

const settingsQuery = gql`
  query getSettings {
    settings {
      showSupportPhoneNumber
      supportPhoneNumber
    }
  }
`;

const hrefLangAlternate = publicRuntimeConfig.hrefLangAlternate;

const NavItemCountrySelector = ({
  visibileOnMobile,
  transparent,
  immersive,
  isMobile
}: NavItemCountrySelectorProps) => {
  const { t } = useTranslation("common");
  const { country } = useContext(LocaleContext);
  const [shouldShowCountrySelector, setShouldShowCountrySelector] = useState(
    false
  );
  const toggleCountrySelector = () => setShouldShowCountrySelector(v => !v);

  return (
    <>
      <NavItem>
        <Flex
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={toggleCountrySelector}
        >
          {!isMobile && (
            <Text mr={1} sx={{ fontWeight: "regular" }}>
              {t(`country-${country}`)}
            </Text>
          )}

          <Icon fontSize={4} name={`flags/${country}`} />

          {!isMobile && (
            <Box ml={1} pt={1}>
              <Icon
                name="caret-down"
                color={
                  !visibileOnMobile && transparent && !immersive
                    ? "samoyedWhite"
                    : "labradorBlack"
                }
              />
            </Box>
          )}
        </Flex>
      </NavItem>

      <Modal
        open={shouldShowCountrySelector}
        size={isMobile ? "full" : "verySmall"}
        onClose={toggleCountrySelector}
        onOutsideClick={toggleCountrySelector}
        HeaderButton={<Box></Box>}
      >
        <Box p={[0]} py={[3]}>
          <Text variant="h3" mb={2} textAlign="left">
            {t("selectCountry")}
          </Text>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "0px 0px"
            }}
          >
            {hrefLangAlternate.map((hrefLang, index) => {
              return (
                hrefLang.country &&
                country != hrefLang.country && (
                  <a
                    key={index}
                    href={hrefLang.href}
                    hrefLang={hrefLang.locale}
                    style={{
                      textDecoration: "none"
                    }}
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyConten="center"
                      py={2}
                      px={2}
                      sx={{
                        borderRadius: 4,
                        ":hover": {
                          boxShadow: "0 1px 8px 0 #ddd"
                        }
                      }}
                    >
                      <Icon fontSize={6} name={`flags/${hrefLang.country}`} />
                      <Text
                        mt={1}
                        sx={{
                          textAlign: "center",
                          fontWeight: "regular",
                          color: "labradorBlack"
                        }}
                      >
                        {t(`country-${hrefLang.locale}`)}
                      </Text>
                    </Flex>
                  </a>
                )
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const NavItemHelp = () => {
  const { t } = useTranslation("header");
  const { country } = useContext(LocaleContext);

  return (
    <NavItem>
      <Text as="a" variant="variants.nav" href={process.env.help[country]}>
        {t("help")}
      </Text>
    </NavItem>
  );
};

const NavItemSearch = () => {
  const { t } = useTranslation("header");

  return (
    <Flex justifyContent="flex-end" mb={["4px", 0]}>
      <Flex
        width="fit-content"
        alignItems="center"
        justifyContent="flex-end"
        backgroundColor="white"
        py={1}
        pl={1}
        pr={2}
        sx={{
          borderRadius: 100,
          border: "1px solid",
          borderColor: "lightGray"
        }}
      >
        <Flex
          backgroundColor="inuOrange"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 28, height: 28, borderRadius: 16, paddingBottom: "2px" }}
        >
          <Icon name="search" color="samoyedWhite" />
        </Flex>

        <Box mx={1} sx={{ color: "labradorBlack" }}>
          <NavLink name={t("search")} href="/search" />
        </Box>
      </Flex>
    </Flex>
  );
};

const NavItemPhoneNumber = (props: NavItemPhoneNumberProps) => {
  const { data: settingsData } = useQuery(settingsQuery);
  const { t } = useTranslation("header");
  const { transparent, immersive, visibileOnMobile } = props;
  // TODO: change this
  return t("header:phoneNumber") &&
    settingsData?.settings?.showSupportPhoneNumber ? (
    <NavItem display={["none", "none", "none", "block"]}>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        as="a"
        variant="variants.nav"
        href={`tel:${t("phoneNumber")}`}
      >
        <Icon
          name="phone"
          color={
            !visibileOnMobile && transparent && !immersive
              ? "samoyedWhite"
              : "labradorBlack"
          }
        />
        <Box as="span" fontSize={4} fontWeight="book" ml={1}>
          {t("phoneNumber")}
        </Box>
      </Flex>
    </NavItem>
  ) : null;
};

const NavItemDefault = (props: NavLinkProps) => {
  const {
    transparent,
    immersive,
    name,
    href,
    icon,
    visibileOnMobile,
    notification
  } = props;

  return (
    <NavItem>
      <Flex alignItems="center" justifyContent="flex-end">
        <Icon
          name={icon}
          color={
            !visibileOnMobile && transparent && !immersive
              ? "samoyedWhite"
              : "labradorBlack"
          }
        />
        <Box ml={1}>
          <NavLink name={name} href={href} />
        </Box>
        {!!notification && (
          <Box
            width="max-content"
            ml={1}
            px={1}
            sx={{
              color: "samoyedWhite",
              backgroundColor: "inuOrange",
              borderRadius: "circle"
            }}
          >
            {notification}
          </Box>
        )}
      </Flex>
    </NavItem>
  );
};

const NavItems = (props: NavItemProps) => {
  const { currentUser, notifications } = props;

  const { t } = useTranslation("header");
  const { accountMode } = useAuthState();
  const isMobile = useIsMobile();

  const openIntercom = () => {
    window.Intercom("show");
  };

  if (!currentUser) {
    return (
      <>
        <NavItemSearch />

        <NavItem>
          <NavLink name={t("howItWorks")} href="/howitworks" />
        </NavItem>

        <NavItem>
          <NavLink name={t("services")} href="ourservices" />
        </NavItem>

        <NavItem>
          <NavLink name={t("becomeASitter")} href="recruitingsitters" />
        </NavItem>

        <NavItem>
          <NavLink name={t("signup")} href="/signup" />
        </NavItem>

        <NavItem>
          <NavLink name={t("login")} href="/login" />
        </NavItem>

        {isMobile && (
          <>
            <NavItemHelp />

            <NavItem>
              <Text onClick={openIntercom}>{t("chat")}</Text>
            </NavItem>
          </>
        )}
      </>
    );
  } else {
    if (accountMode == UserType.CAREGIVER) {
      return (
        <>
          <NavItemDefault
            {...props}
            name={t("messages")}
            href="/conversations"
            icon="messages"
            notification={notifications.unreadMessagesCountCaregiver}
          />
          <NavItemDefault
            {...props}
            name={t("bookings")}
            href="/caregiver/bookings"
            icon="bell"
          />
          <NavItemDefault
            {...props}
            name={t("availability")}
            href="/caregiver/calendar"
            icon="calendar"
          />
          <NavItemHelp />
        </>
      );
    } else {
      return (
        <>
          <NavItemSearch />
          <NavItemDefault
            {...props}
            name={t("messages")}
            href="/conversations"
            icon="messages"
            notification={notifications.unreadMessagesCountUser}
          />
          <NavItemDefault
            {...props}
            name={t("yourPack")}
            href="/user/dogs"
            icon="dog"
          />

          <NavItem>
            <NavLink name={t("howItWorks")} href="/howitworks" />
          </NavItem>

          <NavItem>
            <NavLink name={t("services")} href="ourservices" />
          </NavItem>
        </>
      );
    }
  }
};

const Header = (props: Props) => {
  const { currentUser, transparent, immersive } = props;

  const { t } = useTranslation("header");
  const client = useApolloClientWithResetCallback();
  const isMobile = useIsMobile();
  const { locale } = useContext(LocaleContext);
  const authDispatch = useAuthDispatch();
  const { accountMode, loggedInAsVisilibity } = useAuthState();
  const [visibileOnMobile, setVisibleOnMobile] = useState(false);
  const [flashHeader, setFlashHeader] = useState({
    showPasswordResetHeader: false,
    forgotPasswordEmail: "",
    passwordResetSuccessful: false
  });

  const {
    showPasswordResetHeader,
    forgotPasswordEmail,
    passwordResetSuccessful
  } = flashHeader;

  const showBadge =
    currentUser &&
    isMobile &&
    (accountMode == UserType.CAREGIVER
      ? currentUser.notifications.unreadMessagesCountCaregiver > 0
      : currentUser.notifications.unreadMessagesCountUser > 0);

  const openUserMenu = () => {
    authDispatch({ type: "showUserMenu" });
  };

  const logout = () => {
    authLogout({
      client,
      locale
    });

    authDispatch({ type: "hideLoggedInAs" });
  };

  useEffect(() => {
    const value = flash.get("forgotPasswordEmail");

    if (value) {
      setFlashHeader({
        showPasswordResetHeader: true,
        forgotPasswordEmail: value,
        passwordResetSuccessful: false
      });
    }
    if (flash.get("passwordResetSuccessful")) {
      setFlashHeader({
        showPasswordResetHeader: true,
        forgotPasswordEmail: "",
        passwordResetSuccessful: true
      });
    }
  }, []);

  return (
    <Box
      width={1}
      className={
        showPasswordResetHeader ? "" : transparent ? "transparent" : ""
      }
      sx={{
        zIndex: ["auto", 5],
        position: ["relative", "sticky"],
        top: ["auto", 0],
        background: "white",
        "&.transparent": {
          zIndex: 5,
          background: "transparent",
          position: [!immersive ? "absolute" : "initial", "absolute"],
          top: 0,
          header: {
            borderBottom: !immersive ? 0 : ["default", 0]
          },
          "button svg": {
            color: "samoyedWhite"
          },
          "ul:not(.mobile)": {
            li: {
              color: "samoyedWhite"
            }
          }
        }
      }}
    >
      {!!props.PreHeader && props.PreHeader}

      {loggedInAsVisilibity && currentUser && (
        <Flex
          as="header"
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="center"
          sx={{ bg: "successGreen", color: "samoyedWhite" }}
          px={[1, 3]}
          py={1}
        >
          <Text>{t("loggedInAs", { name: currentUser.name })}</Text>
          <Text ml={1} sx={{ cursor: "pointer" }} onClick={logout}>
            {t("clickToLogout")}
          </Text>
        </Flex>
      )}

      {!showPasswordResetHeader && (
        <Flex
          as="header"
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          sx={{ borderBottom: "default" }}
          px={[1, 3]}
          py={2}
        >
          <Box sx={{ cursor: "pointer" }}>
            <Link href="/">
              <Logo
                color={transparent && !immersive ? "samoyedWhite" : "inuOrange"}
                height={["24px", "32px"]}
                width={["80px", "100px"]}
                name={"logo"}
              ></Logo>
            </Link>
          </Box>

          <Box>
            <Flex
              alignItems="center"
              as="ul"
              ml="auto"
              className={visibileOnMobile ? "mobile" : ""}
              sx={{
                "list-style": "none",
                "text-align": "right",
                display: [
                  "none !important",
                  "none !important",
                  !immersive ? "flex !important" : "none"
                ],
                "&.mobile": {
                  display: visibileOnMobile ? "block !important" : "none",
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  m: 0,
                  py: 1,
                  px: 2,
                  bg: "background",
                  zIndex: 9999,
                  li: {
                    display: "block",
                    py: 1
                  }
                }
              }}
            >
              {visibileOnMobile && (
                <li>
                  <Icon
                    as="button"
                    name="close"
                    fontSize={0}
                    onClick={() => setVisibleOnMobile(false)}
                  />
                </li>
              )}

              <NavItems
                {...props}
                visibileOnMobile={visibileOnMobile}
                notifications={currentUser && currentUser.notifications}
              />

              {currentUser && (
                <AvatarBadge
                  ml={4}
                  mr={1}
                  onClick={() => openUserMenu()}
                  src={currentUser.avatar.circle}
                  alt={`${currentUser.name} avatar`}
                  width={32}
                  height={32}
                  showBadge={showBadge}
                  data-test="avatar"
                />
              )}

              {!isMobile && (
                <NavItemCountrySelector {...props} isMobile={isMobile} />
              )}
            </Flex>
          </Box>

          <Box
            sx={{
              display: visibileOnMobile ? "none" : ["block", "block", "none"]
            }}
          >
            <Flex ml="auto" alignItems="center">
              <NavItemCountrySelector {...props} isMobile={isMobile} />

              <Text color="samoyedWhite" px={2}>
                |
              </Text>

              <Box
                as="button"
                sx={{
                  marginTop: "3px",
                  fontSize: 0,
                  "text-align": "right",
                  cursor: "pointer",
                  p: 0,
                  background: "none",
                  border: "none",
                  marginRight: 1
                }}
              >
                {currentUser ? (
                  <AvatarBadge
                    ml={0}
                    mr={1}
                    onClick={() => openUserMenu()}
                    src={currentUser.avatar.circle}
                    alt={`${currentUser.name} avatar`}
                    width={32}
                    height={32}
                    showBadge={showBadge}
                  />
                ) : (
                  <Icon
                    fontSize={2}
                    name="burger"
                    onClick={() => setVisibleOnMobile(true)}
                    color={
                      transparent && !immersive
                        ? "samoyedWhite"
                        : "labradorBlack"
                    }
                  />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}

      {showPasswordResetHeader && (
        <Flex
          as="header"
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          sx={{ bg: "pugYellow" }}
          px={[1, 3]}
          py={2}
        >
          <Box
            sx={{
              textAlign: "center",
              flex: 1
            }}
          >
            {passwordResetSuccessful && currentUser && (
              <Text>
                {t("passwordResetSuccessful", {
                  name: currentUser.name
                })}
              </Text>
            )}
            {!!forgotPasswordEmail && (
              <Text>
                {t("passwordResetEmailSent", {
                  email: forgotPasswordEmail
                })}
              </Text>
            )}
          </Box>
          <Box>
            <Button
              variant="link"
              onClick={() => {
                setFlashHeader({
                  ...flashHeader,
                  showPasswordResetHeader: false
                });
              }}
            >
              <Icon name="close" />
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default withRouter(Header);

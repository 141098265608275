import { Box, Flex } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import { Label } from "@rebass/forms/styled-components";
import { useState } from "react";
import { ErrorProp, Heading, Modal, Button, Input } from "../common";
import { useIsMobile } from "../../lib/media";

interface AddCouponModelProps {
  onClose: () => void;
  redeemCoupon: (inputValue) => void;
  error: ErrorProp;
}

interface PromoCodeInputProps {
  onChange: (event) => void;
  inputValue: string;
  error: ErrorProp;
}

function PromoCodeInput({ onChange, inputValue, error }: PromoCodeInputProps) {
  const { t } = useTranslation("common");

  return (
    <Box>
      <Heading as="h3">{t("hasPromoCode")}</Heading>
      <Flex mt={2}>
        <Box sx={{ flexGrow: 1 }}>
          <Input
            key="couponCode"
            name="couponCode"
            placeholder={t("enterCode")}
            onChange={onChange}
            value={inputValue}
            sx={{
              borderColor: error.hasError ? "errorRed" : "gray"
            }}
          ></Input>
          {error.hasError && (
            <Label
              sx={{
                color: "errorRed"
              }}
            >
              {error.message}
            </Label>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

function AddCouponModel({ onClose, redeemCoupon, error }: AddCouponModelProps) {
  const { t } = useTranslation("common");

  const [state, setState] = useState("");

  const onChange = ({ target }) => {
    setState(target.value);
  };

  const onClick = () => {
    redeemCoupon(state);
  };

  const FooterButton = () => {
    return (
      <Button size="large" variant="secondary" onClick={onClick}>
        {t("redeem")}
      </Button>
    );
  };
  const isMobileViewport = useIsMobile();
  return isMobileViewport ? (
    <Modal
      size="full"
      HeaderButton={<Box></Box>}
      onClose={onClose}
      open
      FooterButton={
        <Flex
          sx={{
            flexFlow: "column"
          }}
        >
          <FooterButton />
        </Flex>
      }
    >
      <Box py={4}>
        <PromoCodeInput onChange={onChange} inputValue={state} error={error} />
      </Box>
    </Modal>
  ) : (
    <Modal open size="small" onClose={onClose} onOutsideClick={onClose}>
      <Box
        py={4}
        sx={{
          borderBottom: "default"
        }}
      >
        <Box px={2}>
          <PromoCodeInput
            onChange={onChange}
            inputValue={state}
            error={error}
          />
        </Box>
      </Box>
      <Flex
        p={2}
        sx={{
          justifyContent: "flex-end"
        }}
      >
        <FooterButton />
      </Flex>
    </Modal>
  );
}

export default AddCouponModel;

import { Box } from "rebass/styled-components";
import {
  Textarea as BaseTextArea,
  Label
} from "@rebass/forms/styled-components";
import { ErrorProp } from "../common";
import { withTheme } from "styled-components";
import useTranslation from "next-translate/useTranslation";

interface Props {
  error: ErrorProp;
  label?: string;
  theme?: any;
  checked?: boolean;
  placeholder?: string;
  value?: string;
  rows?: number;
  cols?: number;
  sx?: any;
  onChange?: (any) => void;
}

const TextArea = ({ sx, label, error, ...rest }: Props) => {
  const { t } = useTranslation("common");

  const getErrorMessage = () => {
    const errorMessage = t(error.message, null, { fallback: "missing" });
    return errorMessage !== "missing" ? error.message : errorMessage;
  };

  return (
    <Box
      sx={{
        flex: 1
      }}
    >
      {label && (
        <Label
          py={1}
          sx={{
            fontSize: 1
          }}
        >
          {label}
        </Label>
      )}
      <BaseTextArea
        sx={{
          borderColor: error.hasError ? "errorRed" : "gray",
          borderRadius: "default",
          width: "100%",
          transition: "box-shadow 0.2s ease 0s",
          ":focus": {
            borderColor: "springGreen",
            outline: "none",
            boxShadow: "inputFocus",
            "~i": {
              opacity: 1
            }
          },
          ...sx
        }}
        {...rest}
      ></BaseTextArea>
      {error.hasError && (
        <Label
          py={1}
          sx={{
            fontSize: 1,
            color: "errorRed"
          }}
        >
          {getErrorMessage()}
        </Label>
      )}
    </Box>
  );
};

TextArea.defaultProps = {
  css: {
    resize: "none"
  },
  error: {
    hasError: false,
    message: ""
  }
};
export default withTheme(TextArea);

import { DefaultToast } from "react-toast-notifications";
import { Box } from "rebass/styled-components";

const SuccessToast = ({ children, ...props }) => (
  <Box
    data-test="success-toast"
    sx={{
      marginTop: "80px",
      ">div": {
        "[class*='Icon']": {
          width: "25px",
          backgroundColor: "springGreen",
          svg: { display: "none !important" }
        }
      }
    }}
  >
    <DefaultToast {...props}>{children}</DefaultToast>
  </Box>
);

export default SuccessToast;

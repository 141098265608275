import { Box } from "rebass/styled-components";

interface Props {
  bg: string;
  MainInfo: any;
  Label?: any;
  pt: string;
  washed?: boolean;
}
const Tile = ({ MainInfo, Label, bg, pt, washed }: Props) => {
  return (
    <Box width={"100%"} p={1}>
      <Box
        pt={pt}
        sx={{
          backgroundColor: "gray",
          position: "relative",
          borderRadius: "double",
          backgroundImage: `url("${bg}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "hidden",

          //washed out bg
          "::before": washed && {
            top: "-500px",
            right: 0,
            bottom: 0,
            left: 0,
            overflow: "hidden",
            position: "absolute",
            content: "''",
            backgroundColor: "gray",
            opacity: 0.7
          }
        }}
      >
        {Label && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              content: "''",
              zIndex: 1
            }}
          >
            <Label />
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            content: "''",
            backgroundImage: `linear-gradient(to bottom,
                  rgba(0,0,0, 0),
                  rgba(0,0,0, 1) 100%)`,
            minHeight: "60px",
            width: "100%"
          }}
        >
          <MainInfo></MainInfo>
        </Box>
      </Box>
    </Box>
  );
};

Tile.defaultProps = {
  pt: "64.5%"
};

export default Tile;

import { Flex } from "rebass/styled-components";

import useTranslation from "next-translate/useTranslation";
import ButtonSelector from "./ButtonSelector";

interface Props {
  selected: any;
  onSelect: (value: string) => void;
}

function DogWalksSelector({ selected, onSelect }: Props) {
  const { t } = useTranslation("common");

  const items = [
    {
      value: "morning",
      label: t("morningTime")
    },
    {
      value: "afternoon",
      label: t("afternoonTime")
    },
    {
      value: "night",
      label: t("nightTime")
    }
  ];

  return (
    <Flex>
      <ButtonSelector
        multiple
        items={items}
        selected={selected}
        onSelect={onSelect}
      />
    </Flex>
  );
}

export default DogWalksSelector;

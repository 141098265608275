import { Label } from "@rebass/forms/styled-components";
import { Box, Text } from "rebass/styled-components";
import { Input, OutsideClickHandler } from ".";
import PlaceSelector from "../search/PlaceSelector";
import { useState } from "react";

interface Props {
  label?: string;
  name: string;
  value: string;
  onChange?: (any: any) => any;
  onBlur?: (any: any) => any;
  onSelect: (any: any) => any;
  [x: string]: any;
}

const AutocompleteInput = (props: Props) => {
  const { label, name, value, onSelect, ...rest } = props;
  const [selectorVisible, setSelectorVisible] = useState(false);

  return (
    <Box
      width={1}
      sx={{ position: "relative", zIndex: selectorVisible ? 1 : "unset" }}
    >
      {!!selectorVisible && (
        <Box sx={{ ">*": { zIndex: "unset !important" } }}>
          <PlaceSelector input={value} onSelect={onSelect} filterByCity />
        </Box>
      )}
      <OutsideClickHandler
        onOutsideClick={() => setTimeout(() => setSelectorVisible(false), 0)}
      >
        <Box width={1} sx={{ position: "relative", zIndex: "unset" }}>
          <Input
            autoComplete={"new"}
            width={1}
            name={name}
            type="text"
            placeholder={label}
            value={value}
            onInput={() => setSelectorVisible(true)}
            {...rest}
          />
        </Box>
      </OutsideClickHandler>
    </Box>
  );
};

export default AutocompleteInput;

import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text } from "rebass/styled-components";

import { Heading } from "../common";
import Link from "../common/Link";
import { useContext } from "react";
import { LocaleContext } from "../locale";

interface Props {
  isDesktop: boolean;
}

const Areas = ({ isDesktop }: Props) => {
  const { t } = useTranslation("common");

  const { country } = useContext(LocaleContext);

  const ie = [
    { path: "dublin", label: "Dublin" },
    { path: "cork", label: "Cork" },
    { path: "galway", label: "Galway" },
    { path: "limerick", label: "Limerick" },
    { path: "sligo", label: "Sligo" },
    { path: "wicklow", label: "Wicklow" },
    { path: "wexford", label: "Wexford" },
    { path: "killarney", label: "Killarney" },
    { path: "kildare", label: "Kildare" },
    { path: "kilkenny", label: "Kilkenny" },
    ...(isDesktop ? [] : [])
  ];

  const gb = [
    { path: "london", label: "London" },
    { path: "liverpool", label: "Liverpool" },
    { path: "belfast", label: "Belfast" },
    { path: "glasgow", label: "Glasgow" },
    { path: "birmingham", label: "Birmingham" },
    { path: "edinburgh", label: "Edinburgh" },
    { path: "wirral", label: "Wirral" },
    { path: "manchester", label: "Manchester" },
    { path: "bristol", label: "Bristol" },
    { path: "lisburn", label: "Lisburn" },
    ...(isDesktop ? [] : [])
  ];

  const es = [
    { path: "madrid", label: "Madrid" },
    { path: "barcelona", label: "Barcelona" },
    { path: "sevilla", label: "Sevilla" },
    { path: "valencia", label: "Valencia" },
    { path: "zaragoza", label: "Zaragoza" },
    { path: "malaga", label: "Málaga" },
    { path: "granada", label: "Granada" },
    { path: "cordoba", label: "Córdoba" },
    { path: "bilbao", label: "Bilbao" },
    { path: "gijon", label: "Gijón" },
    ...(isDesktop ? [] : [])
  ];

  const fr = [
    { path: "paris", label: "Paris" },
    { path: "lyon", label: "Lyon" },
    { path: "toulouse", label: "Toulouse" },
    { path: "bordeaux", label: "Bordeaux" },
    { path: "marseille", label: "Marseille" },
    { path: "lille", label: "Lille" },
    { path: "nice", label: "Nice" },
    { path: "nantes", label: "Nantes" },
    { path: "montpellier", label: "Montpellier" },
    { path: "rennes", label: "Rennes" },
    ...(isDesktop ? [] : [])
  ];

  const de = [
    { path: "berlin", label: "Berlin" },
    { path: "hamburg", label: "Hamburg" },
    { path: "munchen", label: "München" },
    { path: "frankfurt-am-main", label: "Frankfurt am Main" },
    { path: "koln", label: "Köln" },
    { path: "leipzig", label: "Leipzig" },
    { path: "nurnberg", label: "Nürnberg" },
    { path: "hannover", label: "Hannover" },
    { path: "dusseldorf", label: "Düsseldorf" },
    { path: "munster", label: "Münster" },
    ...(isDesktop ? [] : [])
  ];

  const dk = [
    { path: "kobenhavn", label: "København" },
    { path: "aarhus", label: "Aarhus" },
    { path: "odense", label: "Odense" },
    { path: "aalborg", label: "Aalborg" },
    { path: "esbjerg", label: "Esbjerg" },
    { path: "randers", label: "Randers" },
    { path: "kolding", label: "Kolding" },
    { path: "horsens", label: "Horsens" },
    { path: "vejle", label: "Vejle" },
    { path: "roskilde", label: "Roskilde" },
    ...(isDesktop ? [] : [])
  ];

  const no = [
    { path: "oslo", label: "Oslo" },
    { path: "bergen", label: "Bergen" },
    { path: "trondheim", label: "Trondheim" },
    { path: "stavanger", label: "Stavanger" },
    { path: "sandvika", label: "Sandvika" },
    { path: "kristiansand", label: "Kristiansand" },
    { path: "drammen", label: "Drammen" },
    { path: "asker", label: "Asker" },
    { path: "lillestrom", label: "Lillestrøm" },
    { path: "fredrikstad", label: "Fredrikstad" },
    ...(isDesktop ? [] : [])
  ];

  const se = [
    { path: "stockholm", label: "Stockholm" },
    { path: "goteborg", label: "Göteborg" },
    { path: "malmo", label: "Malmö" },
    { path: "uppsala", label: "Uppsala" },
    { path: "solentuna", label: "Solentuna" },
    { path: "sodermalm", label: "Södermalm" },
    { path: "vasterås", label: "Västerås" },
    { path: "orebro", label: "Örebro" },
    { path: "linkoping", label: "Linköping" },
    { path: "helsingborg", label: "Helsingborg" },
    ...(isDesktop ? [] : [])
  ];

  const areas = {
    gb: gb,
    ie: ie,
    es: es,
    de: de,
    fr: fr,
    dk: dk,
    se: se,
    no: no
  };

  const currentCountryAreas = areas[(country as unknown) as keyof typeof areas];

  const columns = [
    {
      title: t("common:findDogSitters"),
      pathname: "/search/city",
      linkTitle: (city: string) =>
        t("common:serviceTypeDefaultIn") + " " + city,
      service: undefined
    },
    {
      title: t("common:serviceType1"),
      pathname: "/search/cityservice",
      linkTitle: (city: string) =>
        t("common:serviceType1In", {
          city
        }),
      service: process.env.serviceRoutes[country]["dog-boarding"]
    },
    {
      title: t("common:serviceType2"),
      pathname: "/search/cityservice",
      linkTitle: (city: string) =>
        t("common:serviceType2In", {
          city
        }),
      service: process.env.serviceRoutes[country]["dog-walking"]
    },
    {
      title: t("common:serviceType7"),
      pathname: "/search/cityservice",
      linkTitle: (city: string) =>
        t("common:serviceType7In", {
          city
        }),
      service: process.env.serviceRoutes[country]["dog-daycare"]
    }
  ];

  return (
    <Box px={[3, 3, 100]} py={[5, 5, 8]} backgroundColor="lightGray">
      <Heading as="h2" variant="h2" pb={3}>
        {t("common:popularSearches")}
      </Heading>

      <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
        {columns.map(({ title, pathname, linkTitle, service }) => (
          <Box w={[1 / 4, 1 / 2, 1]} pb={2}>
            <Heading
              as="h3"
              pb={1}
              sx={{
                fontSize: 3,
                fontWeight: "book"
              }}
            >
              {title}
            </Heading>

            {currentCountryAreas.map((area, index) => (
              <Link
                key={index}
                href={{
                  pathname,
                  query: {
                    city: area.path,
                    service
                  }
                }}
                title={linkTitle(area.label)}
              >
                <Text
                  sx={{
                    color: "springGreen",
                    fontSize: 3,
                    fontWeight: "book"
                  }}
                >
                  {linkTitle(area.label)}
                </Text>
              </Link>
            ))}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Areas;

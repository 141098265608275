import styled from "styled-components";
import { Button } from "../common";

interface Props {
  borderColor: string;
  [x: string]: any;
}

const FilterButton = ({ borderColor, children, ...rest }: Props) => {
  return (
    <Button
      variant="link"
      px={1}
      py={"2px"}
      sx={{
        fontSize: 1,
        fontWeight: "regular",
        color: "labradorBlack",
        border: "1px solid",
        borderRadius: "circle",
        borderColor: borderColor
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default FilterButton;

import { Flex } from "rebass/styled-components";
import { DogActivityLevel } from "../../../generated/graphql";

import useTranslation from "next-translate/useTranslation";
import ButtonSelector from "./ButtonSelector";

interface Props {
  selected: any;
  onSelect: (value: string) => void;
}

function DogActivityLevelSelector({ selected, onSelect }: Props) {
  const { t } = useTranslation("common");

  const items = [
    {
      value: DogActivityLevel.Low,
      label: t(`dogActivity${DogActivityLevel.Low}`)
    },
    {
      value: DogActivityLevel.Moderate,
      label: t(`dogActivity${DogActivityLevel.Moderate}`)
    },
    {
      value: DogActivityLevel.High,
      label: t(`dogActivity${DogActivityLevel.High}`)
    }
  ];

  return (
    <Flex>
      <ButtonSelector items={items} selected={selected} onSelect={onSelect} />
    </Flex>
  );
}

export default DogActivityLevelSelector;

import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Href, resolveHref } from "../../lib/routing";
import { AnchorHTMLAttributes } from "react";

const AnchorLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

export type LinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> &
  React.PropsWithChildren<NextLinkProps> & {
    href: Href;
  };

const Link = (props: LinkProps) => {
  const {
    href,
    children,
    as, // not used
    shallow,
    scroll,
    prefetch,
    passHref,
    ...anchorProps
  } = props;
  const { locale } = useRouter();
  const resolvedHref = resolveHref(href, locale);

  return (
    <NextLink
      prefetch={prefetch || false}
      locale={false}
      shallow={shallow}
      scroll={scroll}
      href={resolvedHref || href}
      passHref
    >
      <AnchorLink {...anchorProps}>{children}</AnchorLink>
    </NextLink>
  );
};

export default Link;

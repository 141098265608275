import Progress from "progressbar.js";
import { Box } from "rebass/styled-components";
import { useEffect, useRef } from "react";
import { useRouter } from "next/router";

interface Props {
  delay: number;
  fixed: boolean;
}

const routeChangeStarts = (bar: any, delay: number) => {
  //starts a full loading with delayed animation
  bar.animate(1, { duration: delay });
};

const routeChangeEnds = (bar: any) => {
  //complete loading
  bar.set(1);

  //reset loading
  setTimeout(() => {
    bar.set(0);
  }, 500);
};

const ProgressBar = ({ delay, fixed }: Props) => {
  const router = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    //start progress bar and attach it to container
    var line = new Progress.Line(`#${ref.current.id}`);

    //setting proper behaviour on route change events
    router.events.on("routeChangeStart", () => routeChangeStarts(line, delay));
    router.events.on("routeChangeError", () => routeChangeEnds(line));
    router.events.on("routeChangeComplete", () => routeChangeEnds(line));
  }, [router, delay, ref]);

  return (
    <Box
      ref={ref}
      id="progressbar"
      sx={{
        svg: {
          zIndex: 6,
          position: fixed ? "fixed" : "absolute",
          top: 0,
          left: 0,
          right: 0,
          pointerEvents: "none",
          height: "3px",
          path: {
            stroke: "inuOrange"
          }
        }
      }}
    />
  );
};

ProgressBar.defaultProps = {
  delay: 5000
};

export default ProgressBar;

import { Flex } from "rebass/styled-components";
import { Icon } from ".";

interface Props {
  score: number;
  [x: string]: any;
  onRate?: any;
}

const Rating = (props: Props) => {
  const { id, score, onRate, ...rest } = props;
  const scoreInt = Math.round(score);

  const onClick = rating => {
    onRate && onRate(rating);
  };

  return (
    <Flex variant="ratingStar" py={1} {...rest}>
      {[...Array(scoreInt)].map((e, i) => (
        <Icon
          key={i}
          name="star-on"
          color="pugYellow"
          onClick={() => onClick({ id, value: i + 1 })}
        />
      ))}
      {[...Array(5 - scoreInt)].map((e, i) => (
        <Icon
          key={i + scoreInt}
          name="star-off"
          color="pugYellow"
          onClick={() => onClick({ id, value: i + 1 + scoreInt })}
        />
      ))}
      {props.itemType && props.children}
    </Flex>
  );
};

Rating.defaultProps = {
  score: 5
};

export default Rating;

import { Box } from "rebass/styled-components";
import { Select, ErrorProp } from "..";
import useTranslation from "next-translate/useTranslation";

import { Label } from "@rebass/forms/styled-components";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const breedQuery = gql`
  query getBreeds {
    breeds {
      value: id
      label: tName
    }
  }
`;

interface Props {
  selected: any;
  onSelect: (any) => void;
  error: ErrorProp;
}

function DogBreedSelector({ selected, onSelect, error }: Props) {
  const { t } = useTranslation("common");

  const { loading, data } = useQuery(breedQuery);

  return (
    <Box>
      <Select
        id="dog_breed"
        name="dog_breed"
        placeholder={t("dog_breed")}
        isLoading={loading}
        value={
          data && data.breeds
            ? data.breeds.filter(breed => {
                return breed.value == selected;
              })[0]
            : null
        }
        onChange={onSelect}
        options={data && data.breeds}
        error={error}
      ></Select>
    </Box>
  );
}

export default DogBreedSelector;

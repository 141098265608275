import { Flex } from "rebass/styled-components";
import { Rating } from ".";
import { Label } from "@rebass/forms/styled-components";
import useTranslation from "next-translate/useTranslation";


interface Props {
  error: any;
  id: string;
  score: number;
  onScoreChange?: (value) => void;
}

function RatingSelector({ error, id, score, onScoreChange, ...rest }: Props) {
  const { t } = useTranslation("common");

  return (
    <Flex justifyContent="center" width={1} flexDirection="column">
      <Rating
        id={id}
        onRate={rating => onScoreChange(rating)}
        score={score}
        sx={{
          justifyContent: "space-around",
          svg: {
            fontSize: 6,
            cursor: "pointer"
          }
        }}
        {...rest}
      />
      {error?.message && (
        <Label
          py={1}
          sx={{
            fontSize: 1,
            color: "errorRed"
          }}
        >
          {t(error.message)}
        </Label>
      )}
    </Flex>
  );
}

RatingSelector.defaultProps = {
  score: 0,
  error: {
    hasError: false,
    message: ""
  }
};

export default RatingSelector;

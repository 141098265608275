import useTranslation from "next-translate/useTranslation";
import { ReactElement, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Label } from "@rebass/forms/styled-components";
import { FormikErrors } from "formik";

interface Props {
  label: string;
  preview: string;
  children: ReactElement;
  enablePreview: boolean;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
}

const PreviewTextArea = ({
  preview,
  label,
  error,
  children,
  enablePreview
}: Props) => {
  const { t } = useTranslation("common");
  const [visibility, setVisibility] = useState(false || !enablePreview);

  return (
    <Box>
      <Label
        flexWrap="wrap"
        sx={{
          color: error ? "errorRed" : "labradorBlack"
        }}
      >
        <Flex width={1} justifyContent="space-between">
          <Text mb={1}>{label}</Text>

          {enablePreview && (
            <Text
              onClick={() => setVisibility(!visibility)}
              sx={{
                cursor: "pointer",
                color: "springGreen",
                fontWeight: "book",
                flexDirection: "row-reverse"
              }}
            >
              {t("edit")}
            </Text>
          )}
        </Flex>
        <Box flexGrow={1}>
          {visibility ? (
            children
          ) : (
            <Text mt={0} color="darkGray">
              {preview}
            </Text>
          )}
        </Box>
      </Label>
      {error && (
        <Text
          sx={{
            color: "errorRed"
          }}
        >
          {error}
        </Text>
      )}
    </Box>
  );
};

export default PreviewTextArea;

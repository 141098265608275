import { Box } from "rebass/styled-components";
import {
  Checkbox as BaseCheckbox,
  Label
} from "@rebass/forms/styled-components";

interface Props {
  label?: string | React.ReactNode;
  color?: string;
  theme?: any;
  checked?: boolean;
  onChange?: (any) => void;
  [x: string]: any;
}

const Checkbox = (props: Props) => {
  const { label, ...rest } = props;
  return (
    <Box>
      <Label>
        <BaseCheckbox {...rest} />
        {label}
      </Label>
    </Box>
  );
};

export default Checkbox;

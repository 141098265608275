import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** An array of GraphQL::Types::ISO8601Date separated by / */
  DateArray: any;
};

export type AcceptsDogs = {
  __typename?: 'AcceptsDogs';
  giant: Scalars['Boolean'];
  large: Scalars['Boolean'];
  medium: Scalars['Boolean'];
  puppy: Scalars['Boolean'];
  small: Scalars['Boolean'];
};

export type AcceptsDogsInput = {
  giant: Scalars['Boolean'];
  large: Scalars['Boolean'];
  puppy: Scalars['Boolean'];
  small: Scalars['Boolean'];
  medium: Scalars['Boolean'];
};

/** Autogenerated input type of AddBookmark */
export type AddBookmarkInput = {
  caregiverId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddBookmark */
export type AddBookmarkPayload = {
  __typename?: 'AddBookmarkPayload';
  caregiver?: Maybe<Caregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of AddCaregiverPhotos */
export type AddCaregiverPhotosInput = {
  photosInput: Array<CaregiverPhotoInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddCaregiverPhotos */
export type AddCaregiverPhotosPayload = {
  __typename?: 'AddCaregiverPhotosPayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of AddCaregiverReview */
export type AddCaregiverReviewInput = {
  bookingId: Scalars['ID'];
  score: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  privateContent?: Maybe<Scalars['String']>;
  contentForAdmins?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddCaregiverReview */
export type AddCaregiverReviewPayload = {
  __typename?: 'AddCaregiverReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  review?: Maybe<Review>;
};

/** Autogenerated input type of AddDog */
export type AddDogInput = {
  name: Scalars['String'];
  breedId: Scalars['ID'];
  gender: Scalars['String'];
  size: Scalars['String'];
  birthday: Scalars['ISO8601Date'];
  spayedNeutered?: Maybe<Scalars['Boolean']>;
  playsDogs?: Maybe<Scalars['Boolean']>;
  playsDogsDesc?: Maybe<Scalars['String']>;
  playsCats?: Maybe<Scalars['Boolean']>;
  playsCatsDesc?: Maybe<Scalars['String']>;
  playsChildren?: Maybe<Scalars['Boolean']>;
  playsChildrenDesc?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dailyFood?: Maybe<Scalars['String']>;
  dailyFoodServings?: Maybe<DayPeriodInput>;
  dailyWalks?: Maybe<DayPeriodInput>;
  walkingInstructions?: Maybe<Scalars['String']>;
  healthInfo?: Maybe<Scalars['String']>;
  activityLevel?: Maybe<DogActivityLevel>;
  homeAlone?: Maybe<Scalars['String']>;
  indoorPrecautions?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddDog */
export type AddDogPayload = {
  __typename?: 'AddDogPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dog?: Maybe<Dog>;
  errors: Array<Error>;
};

/** Autogenerated input type of AddDogPhotos */
export type AddDogPhotosInput = {
  id: Scalars['ID'];
  photosInput: Array<DogPhotoInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddDogPhotos */
export type AddDogPhotosPayload = {
  __typename?: 'AddDogPhotosPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dog?: Maybe<Dog>;
  errors: Array<Error>;
};

/** Autogenerated input type of AddDogReviews */
export type AddDogReviewsInput = {
  reviewsInput: Array<DogReviewInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddDogReviews */
export type AddDogReviewsPayload = {
  __typename?: 'AddDogReviewsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  reviews?: Maybe<Array<Review>>;
};

/** Autogenerated input type of AddJob */
export type AddJobInput = {
  description: Scalars['String'];
  serviceTypeId: Scalars['ID'];
  dogIds: Array<Scalars['ID']>;
  bookingDates: BookingDatesInput;
  city: CityInput;
  playsDogs?: Maybe<Scalars['Boolean']>;
  playsCats?: Maybe<Scalars['Boolean']>;
  playsChildren?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddJob */
export type AddJobPayload = {
  __typename?: 'AddJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

/** Autogenerated input type of AddMessage */
export type AddMessageInput = {
  conversationId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  attachment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddMessage */
export type AddMessagePayload = {
  __typename?: 'AddMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  message?: Maybe<Message>;
};

/** Autogenerated input type of AddService */
export type AddServiceInput = {
  serviceTypeId: Scalars['ID'];
  price: Scalars['Int'];
  pickUpDropOff?: Maybe<Scalars['Boolean']>;
  pickUpDropOffDistance?: Maybe<Scalars['Int']>;
  pickUpDropOffPrice?: Maybe<Scalars['Int']>;
  holidayPrice?: Maybe<Scalars['Int']>;
  acceptsDogs?: Maybe<AcceptsDogsInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddService */
export type AddServicePayload = {
  __typename?: 'AddServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  service?: Maybe<Service>;
};

export type Application = {
  __typename?: 'Application';
  caregiver: Caregiver;
  estimatedPaymentTotal?: Maybe<Money>;
  id: Scalars['ID'];
};

/** Autogenerated input type of ApplyCouponRedemption */
export type ApplyCouponRedemptionInput = {
  bookingId: Scalars['ID'];
  couponRedemptionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ApplyCouponRedemption */
export type ApplyCouponRedemptionPayload = {
  __typename?: 'ApplyCouponRedemptionPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of AuthenticateWithApple */
export type AuthenticateWithAppleInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  idToken: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AuthenticateWithApple */
export type AuthenticateWithApplePayload = {
  __typename?: 'AuthenticateWithApplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUser>;
};

/** Autogenerated input type of AuthenticateWithFacebook */
export type AuthenticateWithFacebookInput = {
  email: Scalars['String'];
  facebookToken: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AuthenticateWithFacebook */
export type AuthenticateWithFacebookPayload = {
  __typename?: 'AuthenticateWithFacebookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUser>;
};

export type Availability = {
  __typename?: 'Availability';
  /** Dates when the caregiver is booked */
  booked: Array<Scalars['ISO8601Date']>;
  /** Dates when the caregiver is not available */
  unavailable: Array<Scalars['ISO8601Date']>;
};

/** Data requiered to update availability */
export type AvailabilityInput = {
  dates: Array<Scalars['ISO8601Date']>;
  spacesAvailable: Scalars['Int'];
};

export type BillingBankAccount = {
  __typename?: 'BillingBankAccount';
  accountNumber?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ownerAddress: Scalars['String'];
  ownerName: Scalars['String'];
  sortCode?: Maybe<Scalars['String']>;
};

export type BillingBankWire = {
  __typename?: 'BillingBankWire';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status?: Maybe<BillingBankWireStatus>;
  token: Scalars['String'];
  total: Money;
  transferId: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BillingBankWireStatus {
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED',
  /** FAILED */
  Failed = 'FAILED',
  /** CREATED */
  Created = 'CREATED'
}

export type BillingCard = {
  __typename?: 'BillingCard';
  alias: Scalars['String'];
  currency?: Maybe<Currency>;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  id: Scalars['ID'];
  provider: Scalars['String'];
};

export type BillingCardRegistration = {
  __typename?: 'BillingCardRegistration';
  accessKey: Scalars['String'];
  cardRegistrationUrl: Scalars['String'];
  id: Scalars['String'];
  preregistrationData: Scalars['String'];
};

export type BillingPayment = {
  __typename?: 'BillingPayment';
  amount?: Maybe<Money>;
  booking: Booking;
  id: Scalars['ID'];
  isCardPayment: Scalars['Boolean'];
  paymentMethod?: Maybe<PaymentMethodInfo>;
  redirectUrl?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  /** @deprecated Use `redirectUrl` instead */
  threeDSecureUrl?: Maybe<Scalars['String']>;
};

export type BillingProfile = {
  __typename?: 'BillingProfile';
  id?: Maybe<Scalars['ID']>;
  identityStatus: Scalars['String'];
};

export type Blog = {
  __typename?: 'Blog';
  posts?: Maybe<Array<BlogPost>>;
  url: Scalars['String'];
};

export type BlogPost = {
  __typename?: 'BlogPost';
  excerpt?: Maybe<Scalars['String']>;
  featuredMedia?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  title: Scalars['String'];
};

export type BookedAvailability = {
  __typename?: 'BookedAvailability';
  booking: Booking;
  date: Scalars['ISO8601Date'];
};

export type Booking = {
  __typename?: 'Booking';
  address?: Maybe<Scalars['String']>;
  amountToRefund?: Maybe<Money>;
  availableEvents?: Maybe<Array<BookingEvent>>;
  bankWire?: Maybe<BillingBankWire>;
  baseFee: Money;
  bookingDogs?: Maybe<Array<BookingDog>>;
  canBeTransferredToBankAccount?: Maybe<Scalars['Boolean']>;
  canCaregiverBeReviewed?: Maybe<Scalars['Boolean']>;
  caregiver?: Maybe<Caregiver>;
  caregiverId: Scalars['ID'];
  caregiverReview?: Maybe<Review>;
  contact?: Maybe<Contact>;
  conversationId?: Maybe<Scalars['ID']>;
  costAdjustment?: Maybe<Money>;
  country: Country;
  countryServiceType: CountryServiceType;
  couponRedemption?: Maybe<CouponRedemption>;
  createdAt: Scalars['ISO8601DateTime'];
  dogIds: Array<Scalars['ID']>;
  dogReviews?: Maybe<Array<Review>>;
  dogs: Array<Dog>;
  eligibleForFreeCancellation: Scalars['Boolean'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  fee: Money;
  freeCancellationLimit?: Maybe<Scalars['ISO8601DateTime']>;
  fromDogley?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  numberOfTrips?: Maybe<Scalars['Int']>;
  parentBookingId?: Maybe<Scalars['ID']>;
  payment?: Maybe<BillingPayment>;
  paymentConfirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  paymentTotal: Money;
  pickUpDropOff?: Maybe<Scalars['Boolean']>;
  pickUpDropOffPrice?: Maybe<Money>;
  price: Money;
  refundAmount?: Maybe<Money>;
  service?: Maybe<Service>;
  serviceDates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  serviceQuantity?: Maybe<Scalars['Int']>;
  serviceTotal: Money;
  serviceType: ServiceType;
  serviceTypeId: Scalars['ID'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  status: Scalars['String'];
  total: Money;
  totalFee: Money;
  totalFeeVat: Money;
  transferAmount?: Maybe<Money>;
  unitPrice: Money;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

/** Dates value (date range or multiple dates) for a booking */
export type BookingDatesInput = {
  dates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type BookingDog = {
  __typename?: 'BookingDog';
  booking: Booking;
  bookingId: Scalars['ID'];
  canDogBeReviewed?: Maybe<Scalars['Boolean']>;
  dog?: Maybe<Dog>;
  dogId: Scalars['ID'];
  id: Scalars['ID'];
  price?: Maybe<Money>;
};

export enum BookingEvent {
  /** User (owner / caregiver) can edit the booking request */
  EditRequest = 'EDIT_REQUEST',
  /** Owner can cancel the booking */
  OwnerCancel = 'OWNER_CANCEL',
  /** Owner can preapprove the booking request */
  OwnerPreapprove = 'OWNER_PREAPPROVE',
  /** Owner can reject the booking request */
  OwnerReject = 'OWNER_REJECT',
  /** Owner can confirm booking request */
  OwnerConfirm = 'OWNER_CONFIRM',
  /** Caregiver can confirm an owner preapproved booking */
  CaregiverConfirm = 'CAREGIVER_CONFIRM',
  /** Caregiver can accept the booking request */
  CaregiverApprove = 'CAREGIVER_APPROVE',
  /** Caregiver can reject the booking request */
  CaregiverReject = 'CAREGIVER_REJECT',
  /** Caregiver can cancel the booking */
  CaregiverCancel = 'CAREGIVER_CANCEL'
}

export type BookingHistory = {
  __typename?: 'BookingHistory';
  past: Scalars['Int'];
  requests: Scalars['Int'];
  upcoming: Scalars['Int'];
};

export type BookingPriceDogsInput = {
  id: Scalars['ID'];
  price: Scalars['Float'];
};

export type BookingPriceEstimation = {
  __typename?: 'BookingPriceEstimation';
  duration: Scalars['Int'];
  fee: Money;
  holidayServicePrice?: Maybe<PriceForDogs>;
  pickUpDropOffPrice?: Maybe<Money>;
  pricePer: Scalars['String'];
  serviceTotal: Money;
  standardServicePrice?: Maybe<PriceForDogs>;
  total: Money;
};

/** Dates value (date range or multiple dates) for a booking */
export type BookingPriceInput = {
  costAdjustment?: Maybe<Scalars['Float']>;
  dogs?: Maybe<Array<BookingPriceDogsInput>>;
  pickUpDropOffPrice?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of BookingUpdated */
export type BookingUpdatedPayload = {
  __typename?: 'BookingUpdatedPayload';
  booking?: Maybe<Booking>;
};

export type Breed = {
  __typename?: 'Breed';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tName?: Maybe<Scalars['String']>;
};

export type BrowserInfoInput = {
  language: Scalars['String'];
  colorDepth: Scalars['Int'];
  screenHeight: Scalars['Int'];
  screenWidth: Scalars['Int'];
  timeZoneOffset: Scalars['String'];
  userAgent: Scalars['String'];
  javascriptEnabled: Scalars['Boolean'];
};

export enum CancelationReason {
  /** unnecessary */
  Unnecessary = 'UNNECESSARY',
  /** accident */
  Accident = 'ACCIDENT',
  /** trip_canceled */
  TripCanceled = 'TRIP_CANCELED',
  /** dont_like_caregiver */
  DontLikeCaregiver = 'DONT_LIKE_CAREGIVER',
  /** other */
  Other = 'OTHER'
}

export enum CardType {
  CbVisaMastercard = 'CB_VISA_MASTERCARD',
  Diners = 'DINERS',
  Masterpass = 'MASTERPASS',
  Amex = 'AMEX',
  Maestro = 'MAESTRO',
  P24 = 'P24',
  Ideal = 'IDEAL',
  Bcmc = 'BCMC',
  Paylib = 'PAYLIB'
}

export type Caregiver = User & {
  __typename?: 'Caregiver';
  answerRatio?: Maybe<Scalars['String']>;
  atHomeAvailability?: Maybe<CaregiverAvailability>;
  atHomeServicePreferences?: Maybe<ServicePreferences>;
  availableHolidays?: Maybe<Scalars['String']>;
  availablePromotions?: Maybe<Array<CaregiverPromotion>>;
  avatar?: Maybe<UserAvatar>;
  billingProfile?: Maybe<BillingProfile>;
  bookingEstimation?: Maybe<Money>;
  bookingsRatio?: Maybe<Scalars['String']>;
  bookmarked?: Maybe<Scalars['String']>;
  calendarUpToDate?: Maybe<Scalars['Boolean']>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  caregiverActive?: Maybe<Scalars['Boolean']>;
  caregiverCalendarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  caregiverCanAdministerInjectedMeds?: Maybe<Scalars['Boolean']>;
  caregiverCanAdministerOralMeds?: Maybe<Scalars['Boolean']>;
  caregiverDeactivatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  caregiverDescription?: Maybe<Scalars['String']>;
  caregiverHasAnimalCertificate?: Maybe<Scalars['Boolean']>;
  caregiverHasKidsUnder18?: Maybe<Scalars['Boolean']>;
  caregiverHasPppLicense?: Maybe<Scalars['Boolean']>;
  caregiverHeadline?: Maybe<Scalars['String']>;
  caregiverNoob?: Maybe<Scalars['String']>;
  caregiverOwnVehicle?: Maybe<Scalars['Boolean']>;
  caregiverPremium?: Maybe<Scalars['String']>;
  caregiverProvideCustomerReviews?: Maybe<Scalars['Boolean']>;
  caregiverReviewsCount?: Maybe<Scalars['Int']>;
  caregiverReviewsScore?: Maybe<Scalars['Float']>;
  caregiverScore?: Maybe<Scalars['String']>;
  caregiverStatus?: Maybe<CaregiverStatus>;
  caregiverStep?: Maybe<Scalars['String']>;
  caregiverVolunteerAnimalGroups?: Maybe<Scalars['Boolean']>;
  caregiverXpAcommodatingDogs?: Maybe<Scalars['Boolean']>;
  caregiverXpAsDogWalker?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  cleanHeadline?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  featuredReview?: Maybe<Review>;
  id: Scalars['ID'];
  lastSeenAt?: Maybe<Scalars['ISO8601DateTime']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outsideAvailability?: Maybe<CaregiverAvailability>;
  outsideServicePreferences?: Maybe<ServicePreferences>;
  photos?: Maybe<Array<UserPhoto>>;
  price?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicAddress?: Maybe<Scalars['String']>;
  publicFullName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  repeatCustomersCount?: Maybe<Scalars['Int']>;
  responseTime?: Maybe<Scalars['Int']>;
  reviews?: Maybe<PaginatedReviews>;
  services?: Maybe<Array<Service>>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  webUrl?: Maybe<Scalars['String']>;
};


export type CaregiverBookingEstimationArgs = {
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
};


export type CaregiverPriceArgs = {
  serviceTypeId?: Maybe<Scalars['ID']>;
};


export type CaregiverReviewsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of CaregiverApplyForJob */
export type CaregiverApplyForJobInput = {
  jobId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverApplyForJob */
export type CaregiverApplyForJobPayload = {
  __typename?: 'CaregiverApplyForJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CaregiverApproveBooking */
export type CaregiverApproveBookingInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverApproveBooking */
export type CaregiverApproveBookingPayload = {
  __typename?: 'CaregiverApproveBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type CaregiverAvailability = {
  __typename?: 'CaregiverAvailability';
  /** Dates when the caregiver as altered the spaces available */
  available: Array<SpacesAvailable>;
  /** Dates when the caregiver is booked */
  booked: Array<BookedAvailability>;
  /** Dates when the caregiver is not available */
  unavailable: Array<Scalars['ISO8601Date']>;
};

export type CaregiverBookings = {
  __typename?: 'CaregiverBookings';
  past: PaginatedBookings;
  upcoming: PaginatedBookings;
};

/** Autogenerated input type of CaregiverCancelBooking */
export type CaregiverCancelBookingInput = {
  id: Scalars['ID'];
  cancelationReason: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverCancelBooking */
export type CaregiverCancelBookingPayload = {
  __typename?: 'CaregiverCancelBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CaregiverConfirmBooking */
export type CaregiverConfirmBookingInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverConfirmBooking */
export type CaregiverConfirmBookingPayload = {
  __typename?: 'CaregiverConfirmBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CaregiverDismissJob */
export type CaregiverDismissJobInput = {
  jobId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverDismissJob */
export type CaregiverDismissJobPayload = {
  __typename?: 'CaregiverDismissJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CaregiverPayoutBooking */
export type CaregiverPayoutBookingInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverPayoutBooking */
export type CaregiverPayoutBookingPayload = {
  __typename?: 'CaregiverPayoutBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Data required to create/update a caregiver photo */
export type CaregiverPhotoInput = {
  caregiverPhotoId?: Maybe<Scalars['ID']>;
  attachment?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type CaregiverPromotion = {
  __typename?: 'CaregiverPromotion';
  id: Scalars['ID'];
  tLabel: Scalars['String'];
};

/** Autogenerated input type of CaregiverRejectBooking */
export type CaregiverRejectBookingInput = {
  id: Scalars['ID'];
  rejectionReasonType: CaregiverRejectionReason;
  rejectionReason?: Maybe<Scalars['String']>;
  shouldUpdateCalendar?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CaregiverRejectBooking */
export type CaregiverRejectBookingPayload = {
  __typename?: 'CaregiverRejectBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export enum CaregiverRejectionReason {
  /** unavailable */
  Unavailable = 'UNAVAILABLE',
  /** inoperative */
  Inoperative = 'INOPERATIVE',
  /** no_response */
  NoResponse = 'NO_RESPONSE',
  /** dog_not_suitable */
  DogNotSuitable = 'DOG_NOT_SUITABLE',
  /** unavailable_outside */
  UnavailableOutside = 'UNAVAILABLE_OUTSIDE',
  /** other */
  Other = 'OTHER',
  /** as_admin */
  AsAdmin = 'AS_ADMIN'
}

export enum CaregiverStatus {
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
  Incomplete = 'INCOMPLETE',
  Active = 'ACTIVE',
  Rejected = 'REJECTED'
}

export type CaregiverTips = {
  __typename?: 'CaregiverTips';
  tips?: Maybe<Array<Tip>>;
};

/** Autogenerated input type of ChangeJobsBoardEnabledState */
export type ChangeJobsBoardEnabledStateInput = {
  enabled: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ChangeJobsBoardEnabledState */
export type ChangeJobsBoardEnabledStatePayload = {
  __typename?: 'ChangeJobsBoardEnabledStatePayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type City = {
  __typename?: 'City';
  activeCaregiversCount?: Maybe<Scalars['Int']>;
  avgCaregiverReviewsScore?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nearbyCities?: Maybe<Array<City>>;
  placeId?: Maybe<Scalars['String']>;
  seoLandingNoindexTag: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  sumCaregiverReviewsCount?: Maybe<Scalars['Int']>;
};

export type CityInput = {
  placeId: Scalars['String'];
  placeTypes?: Maybe<Scalars['String']>;
  placeDetails?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type Contact = {
  __typename?: 'Contact';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ContactCaregiver */
export type ContactCaregiverInput = {
  caregiverId: Scalars['ID'];
  message: Scalars['String'];
  serviceTypeId: Scalars['ID'];
  dogIds: Array<Scalars['ID']>;
  bookingDates: BookingDatesInput;
  pickUpDropOff?: Maybe<PickUpDropOffInput>;
  parentBookingId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  recaptchaToken?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ContactCaregiver */
export type ContactCaregiverPayload = {
  __typename?: 'ContactCaregiverPayload';
  alternativeCaregivers?: Maybe<Array<Caregiver>>;
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  errors: Array<Error>;
  potentialJob?: Maybe<PotentialJob>;
};

export type Conversation = {
  __typename?: 'Conversation';
  bookings: ConversationBookings;
  caregiver: User;
  id?: Maybe<Scalars['ID']>;
  /** Messages for the conversation paginated and starting by most recent */
  messages?: Maybe<PaginatedMessages>;
  /** The other user (not the current user) in the conversation */
  partner: User;
  user: User;
};


export type ConversationMessagesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type ConversationBookings = {
  __typename?: 'ConversationBookings';
  past: Array<Booking>;
  requests: Array<Booking>;
  upcoming: Array<Booking>;
};

export type ConversationSummary = {
  __typename?: 'ConversationSummary';
  bookingHistory: BookingHistory;
  id?: Maybe<Scalars['ID']>;
  lastMessage: Message;
  /** The other user (not the current user) in the conversation */
  partner: User;
  unreadMessages: Scalars['Int'];
};

/** The connection type for ConversationSummary. */
export type ConversationSummaryConnection = {
  __typename?: 'ConversationSummaryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConversationSummaryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ConversationSummary>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConversationSummaryEdge = {
  __typename?: 'ConversationSummaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ConversationSummary>;
};

export type Country = {
  __typename?: 'Country';
  countryServiceTypes?: Maybe<Array<CountryServiceType>>;
  currency: Currency;
  fullDomain: Scalars['String'];
  id: Scalars['ID'];
  iso3661Alpha2?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentMethods: Array<PaymentMethod>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  vat: Scalars['String'];
};

export type CountryServiceType = {
  __typename?: 'CountryServiceType';
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionForCaregivers?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minimumPrice?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  seoName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<ServiceType>;
};

export type Coupon = {
  __typename?: 'Coupon';
  code: Scalars['String'];
  currency: Currency;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  fixedDiscount?: Maybe<Money>;
  id: Scalars['ID'];
  minimumAmount?: Maybe<Money>;
  percentageDiscount?: Maybe<Scalars['Float']>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CouponRedemption = {
  __typename?: 'CouponRedemption';
  coupon: Coupon;
  id: Scalars['ID'];
};

/** Autogenerated input type of CreateBillingBankAccount */
export type CreateBillingBankAccountInput = {
  iban?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  ownerName: Scalars['String'];
  ownerAddress: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBillingBankAccount */
export type CreateBillingBankAccountPayload = {
  __typename?: 'CreateBillingBankAccountPayload';
  billingBankAccount?: Maybe<BillingBankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateBillingCard */
export type CreateBillingCardInput = {
  token: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateBillingCard */
export type CreateBillingCardPayload = {
  __typename?: 'CreateBillingCardPayload';
  billingCard?: Maybe<BillingCard>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateJobFromBooking */
export type CreateJobFromBookingInput = {
  bookingId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateJobFromBooking */
export type CreateJobFromBookingPayload = {
  __typename?: 'CreateJobFromBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

export enum Currency {
  /** Euro currency */
  Eur = 'EUR',
  /** British pounds currency */
  Gbp = 'GBP',
  /** Danish krone currency */
  Dkk = 'DKK',
  /** Swedish krona currency */
  Sek = 'SEK',
  /** Nowegian Krone currency */
  Nok = 'NOK'
}

export type CurrentCaregiver = CurrentUser & User & {
  __typename?: 'CurrentCaregiver';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  answerRatio?: Maybe<Scalars['String']>;
  appMagicLink: Scalars['String'];
  atHomeAvailability?: Maybe<CaregiverAvailability>;
  atHomeServicePreferences?: Maybe<ServicePreferences>;
  availableHolidays?: Maybe<Scalars['String']>;
  availablePromotions?: Maybe<Array<CaregiverPromotion>>;
  avatar?: Maybe<UserAvatar>;
  billingBankAccount?: Maybe<BillingBankAccount>;
  billingCards?: Maybe<Array<BillingCard>>;
  billingProfile?: Maybe<BillingProfile>;
  birthday?: Maybe<Scalars['ISO8601Date']>;
  bookingEstimation?: Maybe<Money>;
  bookingsRatio?: Maybe<Scalars['String']>;
  bookingsStartingInNext7Days: Array<Booking>;
  bookmarked?: Maybe<Scalars['String']>;
  bookmarks?: Maybe<Array<Caregiver>>;
  calendarUpToDate?: Maybe<Scalars['Boolean']>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  caregiverActive?: Maybe<Scalars['Boolean']>;
  caregiverCalendarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  caregiverCanAdministerInjectedMeds?: Maybe<Scalars['Boolean']>;
  caregiverCanAdministerOralMeds?: Maybe<Scalars['Boolean']>;
  caregiverDeactivatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  caregiverDescription?: Maybe<Scalars['String']>;
  caregiverHasAnimalCertificate?: Maybe<Scalars['Boolean']>;
  caregiverHasJobsBoardEnabled?: Maybe<Scalars['Boolean']>;
  caregiverHasKidsUnder18?: Maybe<Scalars['Boolean']>;
  caregiverHasPppLicense?: Maybe<Scalars['Boolean']>;
  caregiverHeadline?: Maybe<Scalars['String']>;
  caregiverMetrics?: Maybe<CurrentCaregiverMetrics>;
  caregiverNoob?: Maybe<Scalars['String']>;
  caregiverOwnVehicle?: Maybe<Scalars['Boolean']>;
  caregiverPremium?: Maybe<Scalars['String']>;
  caregiverProvideCustomerReviews?: Maybe<Scalars['Boolean']>;
  caregiverReviewsCount?: Maybe<Scalars['Int']>;
  caregiverReviewsScore?: Maybe<Scalars['Float']>;
  caregiverScore?: Maybe<Scalars['String']>;
  caregiverStatus?: Maybe<CaregiverStatus>;
  caregiverStep?: Maybe<Scalars['String']>;
  caregiverVolunteerAnimalGroups?: Maybe<Scalars['Boolean']>;
  caregiverXpAcommodatingDogs?: Maybe<Scalars['Boolean']>;
  caregiverXpAsDogWalker?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  cleanHeadline?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  couponRedemptions?: Maybe<Array<CouponRedemption>>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  email?: Maybe<Scalars['String']>;
  featuredReview?: Maybe<Review>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  intercomUserHashAndroid?: Maybe<Scalars['String']>;
  intercomUserHashIos?: Maybe<Scalars['String']>;
  lastSeenAt?: Maybe<Scalars['ISO8601DateTime']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notification>;
  outsideAvailability?: Maybe<CaregiverAvailability>;
  outsideServicePreferences?: Maybe<ServicePreferences>;
  phone?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<UserPhoto>>;
  price?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicAddress?: Maybe<Scalars['String']>;
  publicFullName?: Maybe<Scalars['String']>;
  recentUnansweredBookings: Array<Booking>;
  referralCode?: Maybe<Scalars['String']>;
  repeatCustomersCount?: Maybe<Scalars['Int']>;
  responseTime?: Maybe<Scalars['Int']>;
  reviews?: Maybe<PaginatedReviews>;
  services?: Maybe<Array<Service>>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  webUrl?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


export type CurrentCaregiverBookingEstimationArgs = {
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
};


export type CurrentCaregiverPriceArgs = {
  serviceTypeId?: Maybe<Scalars['ID']>;
};


export type CurrentCaregiverReviewsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type CurrentCaregiverMetrics = {
  __typename?: 'CurrentCaregiverMetrics';
  answersRatio?: Maybe<Scalars['String']>;
  bookingsRatio?: Maybe<Scalars['String']>;
  calendarUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  metricsMultiplier?: Maybe<Scalars['String']>;
  metricsScore?: Maybe<Scalars['String']>;
  paidBookingsCount?: Maybe<Scalars['String']>;
  repeatCustomersCount?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['String']>;
  reviewsCount?: Maybe<Scalars['String']>;
  totalCaregiverProfitCents?: Maybe<Scalars['String']>;
};

export type CurrentOwner = CurrentUser & User & {
  __typename?: 'CurrentOwner';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  appMagicLink: Scalars['String'];
  avatar?: Maybe<UserAvatar>;
  billingCards?: Maybe<Array<BillingCard>>;
  billingProfile?: Maybe<BillingProfile>;
  birthday?: Maybe<Scalars['ISO8601Date']>;
  bookmarks?: Maybe<Array<Caregiver>>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  couponRedemptions?: Maybe<Array<CouponRedemption>>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  intercomUserHashAndroid?: Maybe<Scalars['String']>;
  intercomUserHashIos?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notification>;
  phone?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicFullName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};

export type CurrentUser = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  appMagicLink: Scalars['String'];
  avatar?: Maybe<UserAvatar>;
  billingCards?: Maybe<Array<BillingCard>>;
  billingProfile?: Maybe<BillingProfile>;
  birthday?: Maybe<Scalars['ISO8601Date']>;
  bookmarks?: Maybe<Array<Caregiver>>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  couponRedemptions?: Maybe<Array<CouponRedemption>>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  intercomUserHashAndroid?: Maybe<Scalars['String']>;
  intercomUserHashIos?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Notification>;
  phone?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicFullName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};


export type DayPeriod = {
  __typename?: 'DayPeriod';
  afternoon: Scalars['Boolean'];
  morning: Scalars['Boolean'];
  night: Scalars['Boolean'];
};

export type DayPeriodInput = {
  morning: Scalars['Boolean'];
  afternoon: Scalars['Boolean'];
  night: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteBillingCard */
export type DeleteBillingCardInput = {
  billingCardId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteBillingCard */
export type DeleteBillingCardPayload = {
  __typename?: 'DeleteBillingCardPayload';
  billingCards?: Maybe<Array<BillingCard>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteBookmark */
export type DeleteBookmarkInput = {
  caregiverId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteBookmark */
export type DeleteBookmarkPayload = {
  __typename?: 'DeleteBookmarkPayload';
  caregiver?: Maybe<Caregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteCaregiverPhoto */
export type DeleteCaregiverPhotoInput = {
  caregiverPhotoId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteCaregiverPhoto */
export type DeleteCaregiverPhotoPayload = {
  __typename?: 'DeleteCaregiverPhotoPayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteDog */
export type DeleteDogInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteDog */
export type DeleteDogPayload = {
  __typename?: 'DeleteDogPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteDogPhoto */
export type DeleteDogPhotoInput = {
  dogPhotoId: Scalars['ID'];
  dogId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteDogPhoto */
export type DeleteDogPhotoPayload = {
  __typename?: 'DeleteDogPhotoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dog?: Maybe<Dog>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteJob */
export type DeleteJobInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteJob */
export type DeleteJobPayload = {
  __typename?: 'DeleteJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteMobileDevice */
export type DeleteMobileDeviceInput = {
  uuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteMobileDevice */
export type DeleteMobileDevicePayload = {
  __typename?: 'DeleteMobileDevicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteService */
export type DeleteServiceInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteService */
export type DeleteServicePayload = {
  __typename?: 'DeleteServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteUser */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export enum DirectDebitMethod {
  /** Sofort payment */
  Sofort = 'SOFORT',
  /** Giropay payment */
  Giropay = 'GIROPAY'
}

export type Dog = {
  __typename?: 'Dog';
  activityLevel?: Maybe<DogActivityLevel>;
  avatar?: Maybe<DogAvatar>;
  birthday?: Maybe<Scalars['ISO8601Date']>;
  breed?: Maybe<Breed>;
  canDogBeReviewed?: Maybe<Scalars['Boolean']>;
  chip?: Maybe<Scalars['String']>;
  dailyFood?: Maybe<Scalars['String']>;
  dailyFoodServings?: Maybe<DayPeriod>;
  dailyWalks?: Maybe<DayPeriod>;
  gender?: Maybe<Scalars['String']>;
  healthInfo?: Maybe<Scalars['String']>;
  homeAlone?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indoorPrecautions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<DogPhoto>>;
  playsCats?: Maybe<Scalars['Boolean']>;
  playsCatsDesc?: Maybe<Scalars['String']>;
  playsChildren?: Maybe<Scalars['Boolean']>;
  playsChildrenDesc?: Maybe<Scalars['String']>;
  playsDogs?: Maybe<Scalars['Boolean']>;
  playsDogsDesc?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<Review>>;
  sameBreedDogs?: Maybe<Array<Dog>>;
  size?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  spayedNeutered?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<Array<DogStats>>;
  tAge?: Maybe<Scalars['String']>;
  tAgeShort?: Maybe<Scalars['String']>;
  tGender?: Maybe<Scalars['String']>;
  tSize?: Maybe<Scalars['String']>;
  user: User;
  walkingInstructions?: Maybe<Scalars['String']>;
};


export type DogCanDogBeReviewedArgs = {
  id: Scalars['ID'];
};

export enum DogActivityLevel {
  Low = 'LOW',
  Moderate = 'MODERATE',
  High = 'HIGH'
}

export type DogAvatar = {
  __typename?: 'DogAvatar';
  big?: Maybe<Scalars['String']>;
  circle?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  slider: Scalars['String'];
  sliderThumb?: Maybe<Scalars['String']>;
};

/** Attributes for filtering by dog */
export type DogFilter = {
  size?: Maybe<Scalars['String']>;
  puppy?: Maybe<Scalars['Boolean']>;
};

export type DogPhoto = {
  __typename?: 'DogPhoto';
  id: Scalars['ID'];
  miniThumb: Scalars['String'];
  original: Scalars['String'];
  slider: Scalars['String'];
  thumb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** Data required to create/update a dog photo */
export type DogPhotoInput = {
  dogPhotoId?: Maybe<Scalars['ID']>;
  attachment?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** Data requiered to create a dog review */
export type DogReviewInput = {
  bookingId: Scalars['ID'];
  dogId: Scalars['ID'];
  score: Scalars['Int'];
  content: Scalars['String'];
};

export type DogStats = {
  __typename?: 'DogStats';
  bookingsCount: Scalars['Int'];
  serviceType: ServiceType;
};

/** Autogenerated input type of EditDog */
export type EditDogInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  breedId: Scalars['ID'];
  gender: Scalars['String'];
  size: Scalars['String'];
  birthday: Scalars['ISO8601Date'];
  spayedNeutered?: Maybe<Scalars['Boolean']>;
  playsDogs?: Maybe<Scalars['Boolean']>;
  playsDogsDesc?: Maybe<Scalars['String']>;
  playsCats?: Maybe<Scalars['Boolean']>;
  playsCatsDesc?: Maybe<Scalars['String']>;
  playsChildren?: Maybe<Scalars['Boolean']>;
  playsChildrenDesc?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  dailyFood?: Maybe<Scalars['String']>;
  dailyFoodServings?: Maybe<DayPeriodInput>;
  dailyWalks?: Maybe<DayPeriodInput>;
  walkingInstructions?: Maybe<Scalars['String']>;
  healthInfo?: Maybe<Scalars['String']>;
  activityLevel?: Maybe<DogActivityLevel>;
  homeAlone?: Maybe<Scalars['String']>;
  indoorPrecautions?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditDog */
export type EditDogPayload = {
  __typename?: 'EditDogPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dog?: Maybe<Dog>;
  errors: Array<Error>;
};

/** A user-readable error message */
export type Error = {
  __typename?: 'Error';
  /** A description of the error */
  message: Scalars['String'];
  /** The location of the value that caused the error */
  path?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of ForgotPassword */
export type ForgotPasswordInput = {
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ForgotPassword */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export enum InboxType {
  /** Inbox as a dog owner */
  Owner = 'OWNER',
  /** Inbox as a dog sitter */
  Caregiver = 'CAREGIVER'
}

/** Autogenerated return type of InboxUpdated */
export type InboxUpdatedPayload = {
  __typename?: 'InboxUpdatedPayload';
  conversation?: Maybe<ConversationSummary>;
};

/** Autogenerated input type of InitBillingCardRegistration */
export type InitBillingCardRegistrationInput = {
  currency: Currency;
  cardType?: Maybe<CardType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InitBillingCardRegistration */
export type InitBillingCardRegistrationPayload = {
  __typename?: 'InitBillingCardRegistrationPayload';
  cardRegistrationData?: Maybe<BillingCardRegistration>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};



export type Job = {
  city: City;
  countryServiceType: CountryServiceType;
  datesRequested: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dogs: Array<Dog>;
  endDate: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  playsCats: Scalars['Boolean'];
  playsChildren: Scalars['Boolean'];
  playsDogs: Scalars['Boolean'];
  serviceDates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  serviceType: ServiceType;
  startDate: Scalars['ISO8601DateTime'];
  status: Scalars['String'];
  user: User;
};

export type JobAuthor = Job & {
  __typename?: 'JobAuthor';
  applications?: Maybe<Array<Application>>;
  city: City;
  countryServiceType: CountryServiceType;
  datesRequested: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dogs: Array<Dog>;
  endDate: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  playsCats: Scalars['Boolean'];
  playsChildren: Scalars['Boolean'];
  playsDogs: Scalars['Boolean'];
  serviceDates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  serviceType: ServiceType;
  startDate: Scalars['ISO8601DateTime'];
  status: Scalars['String'];
  user: User;
};

export type JobPublic = Job & {
  __typename?: 'JobPublic';
  city: City;
  countryServiceType: CountryServiceType;
  datesRequested: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  dogs: Array<Dog>;
  endDate: Scalars['ISO8601DateTime'];
  estimatedEarnings?: Maybe<Money>;
  id: Scalars['ID'];
  playsCats: Scalars['Boolean'];
  playsChildren: Scalars['Boolean'];
  playsDogs: Scalars['Boolean'];
  serviceDates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  serviceType: ServiceType;
  startDate: Scalars['ISO8601DateTime'];
  status: Scalars['String'];
  user: User;
};

/** Autogenerated input type of Login */
export type LoginInput = {
  recaptchaToken: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUser>;
};

export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<MessageImage>;
  booking?: Maybe<Booking>;
  content?: Maybe<Scalars['String']>;
  conversationId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  fromUser?: Maybe<User>;
  id?: Maybe<Scalars['ID']>;
  toUser?: Maybe<User>;
  type: MessageType;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of MessageAdded */
export type MessageAddedPayload = {
  __typename?: 'MessageAddedPayload';
  message?: Maybe<Message>;
};

export type MessageImage = {
  __typename?: 'MessageImage';
  big?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  small: Scalars['String'];
};

export enum MessageType {
  /** Normal text message */
  Normal = 'NORMAL',
  /** Message with an image */
  Image = 'IMAGE',
  /** Automattic message related to a booking */
  Booking = 'BOOKING',
  /** Message with a custom booking */
  DeprecatedCustomBooking = 'DEPRECATED_CUSTOM_BOOKING',
  /** Automattic message when a caregiver is not available */
  DeprecatedRejection = 'DEPRECATED_REJECTION'
}

/** An object representing money, with an amount and currency. */
export type Money = {
  __typename?: 'Money';
  /** Numerical amount of the money. */
  amount: Scalars['Float'];
  currency: Currency;
  /** Displayable string (i.e. "$1.00"). */
  toS: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBookmark?: Maybe<AddBookmarkPayload>;
  addCaregiverPhotos?: Maybe<AddCaregiverPhotosPayload>;
  addCaregiverReview?: Maybe<AddCaregiverReviewPayload>;
  addDog?: Maybe<AddDogPayload>;
  addDogPhoto?: Maybe<AddDogPhotosPayload>;
  addDogReviews?: Maybe<AddDogReviewsPayload>;
  addJob?: Maybe<AddJobPayload>;
  addMessage?: Maybe<AddMessagePayload>;
  addService?: Maybe<AddServicePayload>;
  applyCouponRedemption?: Maybe<ApplyCouponRedemptionPayload>;
  authenticateWithApple?: Maybe<AuthenticateWithApplePayload>;
  authenticateWithFacebook?: Maybe<AuthenticateWithFacebookPayload>;
  caregiverApplyForJob?: Maybe<CaregiverApplyForJobPayload>;
  caregiverApproveBooking?: Maybe<CaregiverApproveBookingPayload>;
  caregiverCancelBooking?: Maybe<CaregiverCancelBookingPayload>;
  caregiverConfirmBooking?: Maybe<CaregiverConfirmBookingPayload>;
  caregiverDismissJob?: Maybe<CaregiverDismissJobPayload>;
  caregiverPayoutBooking?: Maybe<CaregiverPayoutBookingPayload>;
  caregiverRejectBooking?: Maybe<CaregiverRejectBookingPayload>;
  changeJobsBoardEnabledState?: Maybe<ChangeJobsBoardEnabledStatePayload>;
  contactCaregiver?: Maybe<ContactCaregiverPayload>;
  createBillingBankAccount?: Maybe<CreateBillingBankAccountPayload>;
  createBillingCard?: Maybe<CreateBillingCardPayload>;
  createJobFromBooking?: Maybe<CreateJobFromBookingPayload>;
  deleteBillingCard?: Maybe<DeleteBillingCardPayload>;
  deleteBookmark?: Maybe<DeleteBookmarkPayload>;
  deleteCaregiverPhoto?: Maybe<DeleteCaregiverPhotoPayload>;
  deleteDog?: Maybe<DeleteDogPayload>;
  deleteDogPhoto?: Maybe<DeleteDogPhotoPayload>;
  deleteJob?: Maybe<DeleteJobPayload>;
  deleteMobileDevice?: Maybe<DeleteMobileDevicePayload>;
  deleteService?: Maybe<DeleteServicePayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  editDog?: Maybe<EditDogPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  initBillingCardRegistration?: Maybe<InitBillingCardRegistrationPayload>;
  login?: Maybe<LoginPayload>;
  onboardOwner?: Maybe<OnboardOwnerPayload>;
  ownerAcceptJobApplication?: Maybe<OwnerAcceptJobApplicationPayload>;
  ownerCancelBooking?: Maybe<OwnerCancelBookingPayload>;
  ownerConfirmBooking?: Maybe<OwnerConfirmBookingPayload>;
  ownerDeclineJobApplication?: Maybe<OwnerDeclineJobApplicationPayload>;
  ownerPreapproveBooking?: Maybe<OwnerPreapproveBookingPayload>;
  ownerRejectBooking?: Maybe<OwnerRejectBookingPayload>;
  redeemCoupon?: Maybe<RedeemCouponPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendSmsWithAppMagicLink?: Maybe<SendSmsWithAppMagicLinkPayload>;
  signUp?: Maybe<SignUpPayload>;
  updateAvailability?: Maybe<UpdateAvailabilityPayload>;
  updateBooking?: Maybe<UpdateBookingPayload>;
  updateCaregiver?: Maybe<UpdateCaregiverPayload>;
  updateCaregiverDeactivate?: Maybe<UpdateCaregiverDeactivatePayload>;
  updateService?: Maybe<UpdateServicePayload>;
  updateServiceAvailable?: Maybe<UpdateServiceAvailablePayload>;
  updateServicePreferences?: Maybe<UpdateServicePreferencesPayload>;
  updateServiceUnavailable?: Maybe<UpdateServiceUnavailablePayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserEmail?: Maybe<UpdateUserEmailPayload>;
  updateUserPassword?: Maybe<UpdateUserPasswordPayload>;
  uploadIdentityProof?: Maybe<UploadIdentityProofPayload>;
  upsertMobileDevice?: Maybe<UpsertMobileDevicePayload>;
};


export type MutationAddBookmarkArgs = {
  input: AddBookmarkInput;
};


export type MutationAddCaregiverPhotosArgs = {
  input: AddCaregiverPhotosInput;
};


export type MutationAddCaregiverReviewArgs = {
  input: AddCaregiverReviewInput;
};


export type MutationAddDogArgs = {
  input: AddDogInput;
};


export type MutationAddDogPhotoArgs = {
  input: AddDogPhotosInput;
};


export type MutationAddDogReviewsArgs = {
  input: AddDogReviewsInput;
};


export type MutationAddJobArgs = {
  input: AddJobInput;
};


export type MutationAddMessageArgs = {
  input: AddMessageInput;
};


export type MutationAddServiceArgs = {
  input: AddServiceInput;
};


export type MutationApplyCouponRedemptionArgs = {
  input: ApplyCouponRedemptionInput;
};


export type MutationAuthenticateWithAppleArgs = {
  input: AuthenticateWithAppleInput;
};


export type MutationAuthenticateWithFacebookArgs = {
  input: AuthenticateWithFacebookInput;
};


export type MutationCaregiverApplyForJobArgs = {
  input: CaregiverApplyForJobInput;
};


export type MutationCaregiverApproveBookingArgs = {
  input: CaregiverApproveBookingInput;
};


export type MutationCaregiverCancelBookingArgs = {
  input: CaregiverCancelBookingInput;
};


export type MutationCaregiverConfirmBookingArgs = {
  input: CaregiverConfirmBookingInput;
};


export type MutationCaregiverDismissJobArgs = {
  input: CaregiverDismissJobInput;
};


export type MutationCaregiverPayoutBookingArgs = {
  input: CaregiverPayoutBookingInput;
};


export type MutationCaregiverRejectBookingArgs = {
  input: CaregiverRejectBookingInput;
};


export type MutationChangeJobsBoardEnabledStateArgs = {
  input: ChangeJobsBoardEnabledStateInput;
};


export type MutationContactCaregiverArgs = {
  input: ContactCaregiverInput;
};


export type MutationCreateBillingBankAccountArgs = {
  input: CreateBillingBankAccountInput;
};


export type MutationCreateBillingCardArgs = {
  input: CreateBillingCardInput;
};


export type MutationCreateJobFromBookingArgs = {
  input: CreateJobFromBookingInput;
};


export type MutationDeleteBillingCardArgs = {
  input: DeleteBillingCardInput;
};


export type MutationDeleteBookmarkArgs = {
  input: DeleteBookmarkInput;
};


export type MutationDeleteCaregiverPhotoArgs = {
  input: DeleteCaregiverPhotoInput;
};


export type MutationDeleteDogArgs = {
  input: DeleteDogInput;
};


export type MutationDeleteDogPhotoArgs = {
  input: DeleteDogPhotoInput;
};


export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};


export type MutationDeleteMobileDeviceArgs = {
  input: DeleteMobileDeviceInput;
};


export type MutationDeleteServiceArgs = {
  input: DeleteServiceInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationEditDogArgs = {
  input: EditDogInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationInitBillingCardRegistrationArgs = {
  input: InitBillingCardRegistrationInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationOnboardOwnerArgs = {
  input: OnboardOwnerInput;
};


export type MutationOwnerAcceptJobApplicationArgs = {
  input: OwnerAcceptJobApplicationInput;
};


export type MutationOwnerCancelBookingArgs = {
  input: OwnerCancelBookingInput;
};


export type MutationOwnerConfirmBookingArgs = {
  input: OwnerConfirmBookingInput;
};


export type MutationOwnerDeclineJobApplicationArgs = {
  input: OwnerDeclineJobApplicationInput;
};


export type MutationOwnerPreapproveBookingArgs = {
  input: OwnerPreapproveBookingInput;
};


export type MutationOwnerRejectBookingArgs = {
  input: OwnerRejectBookingInput;
};


export type MutationRedeemCouponArgs = {
  input: RedeemCouponInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendSmsWithAppMagicLinkArgs = {
  input: SendSmsWithAppMagicLinkInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationUpdateAvailabilityArgs = {
  input: UpdateAvailabilityInput;
};


export type MutationUpdateBookingArgs = {
  input: UpdateBookingInput;
};


export type MutationUpdateCaregiverArgs = {
  input: UpdateCaregiverInput;
};


export type MutationUpdateCaregiverDeactivateArgs = {
  input: UpdateCaregiverDeactivateInput;
};


export type MutationUpdateServiceArgs = {
  input: UpdateServiceInput;
};


export type MutationUpdateServiceAvailableArgs = {
  input: UpdateServiceAvailableInput;
};


export type MutationUpdateServicePreferencesArgs = {
  input: UpdateServicePreferencesInput;
};


export type MutationUpdateServiceUnavailableArgs = {
  input: UpdateServiceUnavailableInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUploadIdentityProofArgs = {
  input: UploadIdentityProofInput;
};


export type MutationUpsertMobileDeviceArgs = {
  input: UpsertMobileDeviceInput;
};

export type Notification = {
  __typename?: 'Notification';
  inbox?: Maybe<Scalars['Int']>;
  unreadMessagesCountCaregiver?: Maybe<Scalars['Int']>;
  unreadMessagesCountUser?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of OnboardOwner */
export type OnboardOwnerInput = {
  name: Scalars['String'];
  surname?: Maybe<Scalars['String']>;
  city: CityInput;
  countryId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OnboardOwner */
export type OnboardOwnerPayload = {
  __typename?: 'OnboardOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  user?: Maybe<CurrentUser>;
};

export type Owner = User & {
  __typename?: 'Owner';
  avatar?: Maybe<UserAvatar>;
  billingProfile?: Maybe<BillingProfile>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicFullName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Autogenerated input type of OwnerAcceptJobApplication */
export type OwnerAcceptJobApplicationInput = {
  jobMatchId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerAcceptJobApplication */
export type OwnerAcceptJobApplicationPayload = {
  __typename?: 'OwnerAcceptJobApplicationPayload';
  applications?: Maybe<Array<Application>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  conversation?: Maybe<Conversation>;
  errors: Array<Error>;
};

/** Autogenerated input type of OwnerCancelBooking */
export type OwnerCancelBookingInput = {
  id: Scalars['ID'];
  cancelationReasonType: CancelationReason;
  cancelationReason?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerCancelBooking */
export type OwnerCancelBookingPayload = {
  __typename?: 'OwnerCancelBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of OwnerConfirmBooking */
export type OwnerConfirmBookingInput = {
  id: Scalars['ID'];
  billingCardId?: Maybe<Scalars['ID']>;
  directDebitMethod?: Maybe<DirectDebitMethod>;
  browserInfo?: Maybe<BrowserInfoInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerConfirmBooking */
export type OwnerConfirmBookingPayload = {
  __typename?: 'OwnerConfirmBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  payment?: Maybe<BillingPayment>;
};

/** Autogenerated input type of OwnerDeclineJobApplication */
export type OwnerDeclineJobApplicationInput = {
  jobMatchId: Scalars['ID'];
  rejectionReason?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerDeclineJobApplication */
export type OwnerDeclineJobApplicationPayload = {
  __typename?: 'OwnerDeclineJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of OwnerPreapproveBooking */
export type OwnerPreapproveBookingInput = {
  id: Scalars['ID'];
  billingCardId: Scalars['ID'];
  browserInfo: BrowserInfoInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerPreapproveBooking */
export type OwnerPreapproveBookingPayload = {
  __typename?: 'OwnerPreapproveBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  payment?: Maybe<BillingPayment>;
};

/** Autogenerated input type of OwnerRejectBooking */
export type OwnerRejectBookingInput = {
  id: Scalars['ID'];
  rejectionReasonType: OwnerRejectionReason;
  rejectionReason?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OwnerRejectBooking */
export type OwnerRejectBookingPayload = {
  __typename?: 'OwnerRejectBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export enum OwnerRejectionReason {
  /** inquiring */
  Inquiring = 'INQUIRING',
  /** no_response */
  NoResponse = 'NO_RESPONSE',
  /** sitter_not_suitable */
  SitterNotSuitable = 'SITTER_NOT_SUITABLE',
  /** incorrect_parameters */
  IncorrectParameters = 'INCORRECT_PARAMETERS',
  /** found_another_option */
  FoundAnotherOption = 'FOUND_ANOTHER_OPTION',
  /** found_another_option_outside */
  FoundAnotherOptionOutside = 'FOUND_ANOTHER_OPTION_OUTSIDE',
  /** other */
  Other = 'OTHER',
  /** as_admin */
  AsAdmin = 'AS_ADMIN'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedBookings = PaginationInterface & {
  __typename?: 'PaginatedBookings';
  currentPage?: Maybe<Scalars['Int']>;
  results: Array<Booking>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginatedConversation = PaginationInterface & {
  __typename?: 'PaginatedConversation';
  currentPage?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Conversation>>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginatedMessages = PaginationInterface & {
  __typename?: 'PaginatedMessages';
  currentPage?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Message>>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginatedReviews = PaginationInterface & {
  __typename?: 'PaginatedReviews';
  currentPage?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Review>>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Something that can be paginated */
export type PaginationInterface = {
  currentPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export enum PaymentMethod {
  /** Credit/debit card payment */
  Card = 'CARD',
  /** Sofort payment */
  Sofort = 'SOFORT',
  /** Giropay payment */
  Giropay = 'GIROPAY'
}

export type PaymentMethodInfo = {
  __typename?: 'PaymentMethodInfo';
  alias?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  type: PaymentMethod;
};

export enum PaymentStatus {
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  Preauthorized = 'PREAUTHORIZED',
  PaymentThreeDSecure = 'PAYMENT_THREE_D_SECURE',
  PreauthorizationThreeDSecure = 'PREAUTHORIZATION_THREE_D_SECURE',
  Completed = 'COMPLETED'
}

/** pick up / drop off for booking */
export type PickUpDropOffInput = {
  pickUpAtHome: Scalars['Boolean'];
  numberOfTrips: Scalars['Int'];
};

export type PotentialJob = {
  __typename?: 'PotentialJob';
  bookingId?: Maybe<Scalars['ID']>;
  city: City;
  cityName?: Maybe<Scalars['String']>;
  dogIds: Array<Scalars['ID']>;
  dogs: Array<Dog>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  serviceDates?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  serviceType: ServiceType;
  serviceTypeId: Scalars['ID'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type PriceForDogs = {
  __typename?: 'PriceForDogs';
  count: Scalars['Int'];
  price: Money;
};

export type Property = {
  __typename?: 'Property';
  balcony?: Maybe<Scalars['Boolean']>;
  garden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  outdoorSize?: Maybe<Scalars['Int']>;
  terrace?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PropertyType>;
};

/** Data requiered to updat a property */
export type PropertyInput = {
  id?: Maybe<Scalars['ID']>;
  type: PropertyType;
  garden?: Maybe<Scalars['Boolean']>;
  balcony?: Maybe<Scalars['Boolean']>;
  terrace?: Maybe<Scalars['Boolean']>;
  outdoorSize?: Maybe<Scalars['Int']>;
};

export enum PropertyType {
  /** Farm house */
  Ranch = 'RANCH',
  /** House */
  House = 'HOUSE',
  /** Apartment */
  Apartment = 'APARTMENT'
}

export type Query = {
  __typename?: 'Query';
  /** Availability for a specific sitter in a given month? */
  availability: Availability;
  /** Return the latest entries for the blog */
  blog?: Maybe<Blog>;
  /** A single booking belonging to the current user */
  booking: Booking;
  /** Booking estimated price */
  bookingPrice?: Maybe<BookingPriceEstimation>;
  breeds: Array<Breed>;
  /** A single caregiver with a public profile */
  caregiver?: Maybe<Caregiver>;
  /** A list of bookings belonging to the current caregiver */
  caregiverBookings: CaregiverBookings;
  /** Return tips to improve caregiver profiles */
  caregiverTips?: Maybe<CaregiverTips>;
  /** A single City */
  city?: Maybe<City>;
  /** A single conversation belonging to the current user */
  conversation: Conversation;
  /** Conversations for the user paginated and starting by most recent */
  conversations?: Maybe<PaginatedConversation>;
  countries: Array<Country>;
  /** A single Country */
  country?: Maybe<Country>;
  /** The current user */
  currentUser?: Maybe<CurrentUser>;
  /** A single Dog */
  dog: Dog;
  /** Conversations summary for the current user paginated and starting by most recent */
  inbox: ConversationSummaryConnection;
  /** A single job belonging to the current user */
  job: Job;
  /** Job listings board */
  jobsAvailable?: Maybe<Array<Job>>;
  /** A list of bookings belonging to the current user */
  ownerBookings: PaginatedBookings;
  /** Jobs created by current user */
  ownerJobs: Array<Job>;
  /** A single payment belonging to the current user */
  payment: BillingPayment;
  /** Potential jobs to be published from a booking request */
  potentialJobs: Array<PotentialJob>;
  /** Search caregivers */
  search?: Maybe<SearchResult>;
  /** Search caregivers */
  searchCity?: Maybe<SearchResult>;
  /** Search caregivers */
  searchCityServiceType?: Maybe<SearchResult>;
  /** Search caregivers */
  searchServiceType?: Maybe<SearchResult>;
  /** Application settings */
  settings?: Maybe<Settings>;
  /** A list of top 20 reviews for any given country */
  topReviewsByCountry: Array<Review>;
  /** A single user */
  user?: Maybe<User>;
};


export type QueryAvailabilityArgs = {
  caregiverId: Scalars['ID'];
  serviceTypeId: Scalars['ID'];
};


export type QueryBlogArgs = {
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingPriceArgs = {
  serviceId?: Maybe<Scalars['ID']>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  bookingDates: BookingDatesInput;
  pickUpDropOff?: Maybe<PickUpDropOffInput>;
};


export type QueryCaregiverArgs = {
  id: Scalars['ID'];
};


export type QueryCaregiverBookingsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCityArgs = {
  id: Scalars['ID'];
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryDogArgs = {
  id: Scalars['ID'];
};


export type QueryInboxArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  type?: Maybe<InboxType>;
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryOwnerBookingsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QuerySearchArgs = {
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  near?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchCityArgs = {
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  near?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  city: Scalars['String'];
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchCityServiceTypeArgs = {
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  near?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  city: Scalars['String'];
  service: Scalars['String'];
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchServiceTypeArgs = {
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  near?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  dogs?: Maybe<Array<DogFilter>>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  service: Scalars['String'];
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
};


export type QueryTopReviewsByCountryArgs = {
  countryId: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

/** Autogenerated input type of RedeemCoupon */
export type RedeemCouponInput = {
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RedeemCoupon */
export type RedeemCouponPayload = {
  __typename?: 'RedeemCouponPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  couponRedemption?: Maybe<CouponRedemption>;
  errors: Array<Error>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  resetPasswordToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUser>;
};

export type Review = {
  __typename?: 'Review';
  author: User;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  reviewable: Reviewable;
  reviewableType: Scalars['String'];
  score: Scalars['Float'];
};

/** Objects which may be reviewed on */
export type Reviewable = Caregiver | Dog;

export type SearchResult = {
  __typename?: 'SearchResult';
  caregivers: Array<Caregiver>;
  city?: Maybe<City>;
  contentHtml?: Maybe<Scalars['String']>;
  currentPage?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  serviceType?: Maybe<ServiceType>;
  title?: Maybe<Scalars['String']>;
  topCities?: Maybe<Array<City>>;
  totalEntries?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of SendSMSWithAppMagicLink */
export type SendSmsWithAppMagicLinkInput = {
  recaptchaToken: Scalars['String'];
  telephone: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendSMSWithAppMagicLink */
export type SendSmsWithAppMagicLinkPayload = {
  __typename?: 'SendSMSWithAppMagicLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type Service = {
  __typename?: 'Service';
  acceptsDogs?: Maybe<AcceptsDogs>;
  countryServiceType?: Maybe<CountryServiceType>;
  currency?: Maybe<Scalars['String']>;
  holidayPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  pickUpDropOff?: Maybe<Scalars['Boolean']>;
  pickUpDropOffDistance?: Maybe<Scalars['Int']>;
  pickUpDropOffPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<ServiceType>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  unavailable?: Maybe<Scalars['Boolean']>;
};

export type ServicePreferences = {
  __typename?: 'ServicePreferences';
  caregiverId?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  monday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  spacesAvailable?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601Date']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** Data requiered to create/update service preferences */
export type ServicePreferencesInput = {
  monday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  friday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  spacesAvailable: Scalars['Int'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  alwaysPickUpDropOff?: Maybe<Scalars['Boolean']>;
  fullDayAvailability?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pricePer?: Maybe<Scalars['String']>;
  routeServiceParam?: Maybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  showSupportPhoneNumber: Scalars['Boolean'];
  supportPhoneNumber: Scalars['String'];
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  recaptchaToken: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUser>;
};

export type SpacesAvailable = {
  __typename?: 'SpacesAvailable';
  date: Scalars['ISO8601Date'];
  spacesAvailable: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  bookingUpdated: BookingUpdatedPayload;
  inboxUpdated: InboxUpdatedPayload;
  messageAdded: MessageAddedPayload;
};


export type SubscriptionBookingUpdatedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionMessageAddedArgs = {
  conversationId: Scalars['ID'];
};

export type Tip = {
  __typename?: 'Tip';
  link: Scalars['String'];
  title: Scalars['String'];
};

/** Autogenerated input type of UpdateAvailability */
export type UpdateAvailabilityInput = {
  atHome?: Maybe<AvailabilityInput>;
  outside?: Maybe<AvailabilityInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAvailability */
export type UpdateAvailabilityPayload = {
  __typename?: 'UpdateAvailabilityPayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateBooking */
export type UpdateBookingInput = {
  id: Scalars['ID'];
  bookingDates?: Maybe<BookingDatesInput>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  bookingPrice?: Maybe<BookingPriceInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateBooking */
export type UpdateBookingPayload = {
  __typename?: 'UpdateBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateCaregiverDeactivate */
export type UpdateCaregiverDeactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCaregiverDeactivate */
export type UpdateCaregiverDeactivatePayload = {
  __typename?: 'UpdateCaregiverDeactivatePayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateCaregiver */
export type UpdateCaregiverInput = {
  caregiverHeadline: Scalars['String'];
  caregiverDescription: Scalars['String'];
  caregiverXpAsDogWalker?: Maybe<Scalars['Boolean']>;
  caregiverXpAcommodatingDogs?: Maybe<Scalars['Boolean']>;
  caregiverProvideCustomerReviews?: Maybe<Scalars['Boolean']>;
  caregiverOwnVehicle?: Maybe<Scalars['Boolean']>;
  caregiverCanAdministerOralMeds?: Maybe<Scalars['Boolean']>;
  caregiverCanAdministerInjectedMeds?: Maybe<Scalars['Boolean']>;
  caregiverVolunteerAnimalGroups?: Maybe<Scalars['Boolean']>;
  caregiverHasAnimalCertificate?: Maybe<Scalars['Boolean']>;
  caregiverHasKidsUnder18?: Maybe<Scalars['Boolean']>;
  caregiverHasPppLicense?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCaregiver */
export type UpdateCaregiverPayload = {
  __typename?: 'UpdateCaregiverPayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateServiceAvailable */
export type UpdateServiceAvailableInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServiceAvailable */
export type UpdateServiceAvailablePayload = {
  __typename?: 'UpdateServiceAvailablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of UpdateService */
export type UpdateServiceInput = {
  id: Scalars['ID'];
  price?: Maybe<Scalars['Int']>;
  pickUpDropOff?: Maybe<Scalars['Boolean']>;
  pickUpDropOffDistance?: Maybe<Scalars['Int']>;
  pickUpDropOffPrice?: Maybe<Scalars['Int']>;
  holidayPrice?: Maybe<Scalars['Int']>;
  acceptsDogs?: Maybe<AcceptsDogsInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateService */
export type UpdateServicePayload = {
  __typename?: 'UpdateServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of UpdateServicePreferences */
export type UpdateServicePreferencesInput = {
  atHome?: Maybe<ServicePreferencesInput>;
  outside?: Maybe<ServicePreferencesInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServicePreferences */
export type UpdateServicePreferencesPayload = {
  __typename?: 'UpdateServicePreferencesPayload';
  caregiver?: Maybe<CurrentCaregiver>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateServiceUnavailable */
export type UpdateServiceUnavailableInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateServiceUnavailable */
export type UpdateServiceUnavailablePayload = {
  __typename?: 'UpdateServiceUnavailablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of UpdateUserEmail */
export type UpdateUserEmailInput = {
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserEmail */
export type UpdateUserEmailPayload = {
  __typename?: 'UpdateUserEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  birthday: Scalars['ISO8601Date'];
  address_1: Scalars['String'];
  address_2?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  city: CityInput;
  countryId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUserPassword */
export type UpdateUserPasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserPassword */
export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  errors: Array<Error>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  user?: Maybe<CurrentUser>;
};

/** Autogenerated input type of UploadIdentityProof */
export type UploadIdentityProofInput = {
  front: Scalars['String'];
  back?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UploadIdentityProof */
export type UploadIdentityProofPayload = {
  __typename?: 'UploadIdentityProofPayload';
  billingProfile?: Maybe<BillingProfile>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpsertMobileDevice */
export type UpsertMobileDeviceInput = {
  uuid: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  platform: Scalars['String'];
  version: Scalars['String'];
  appVersion: Scalars['String'];
  appId: Scalars['String'];
  idfa?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertMobileDevice */
export type UpsertMobileDevicePayload = {
  __typename?: 'UpsertMobileDevicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type User = {
  avatar?: Maybe<UserAvatar>;
  billingProfile?: Maybe<BillingProfile>;
  canCreateABillingProfile?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  dogs?: Maybe<Array<Dog>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Property>;
  publicFullName?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UserAvatar = {
  __typename?: 'UserAvatar';
  circle?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  thumb?: Maybe<Scalars['String']>;
};

export type UserPhoto = {
  __typename?: 'UserPhoto';
  id: Scalars['ID'];
  original: Scalars['String'];
  slider: Scalars['String'];
  sliderThumb: Scalars['String'];
  thumb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type OwnerRejectBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectionReasonType: OwnerRejectionReason;
  rejectionReason?: Maybe<Scalars['String']>;
}>;


export type OwnerRejectBookingMutation = (
  { __typename?: 'Mutation' }
  & { ownerRejectBooking?: Maybe<(
    { __typename?: 'OwnerRejectBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'conversationId'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type CaregiverRejectBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectionReasonType: CaregiverRejectionReason;
  rejectionReason?: Maybe<Scalars['String']>;
  shouldUpdateCalendar?: Maybe<Scalars['Boolean']>;
}>;


export type CaregiverRejectBookingMutation = (
  { __typename?: 'Mutation' }
  & { caregiverRejectBooking?: Maybe<(
    { __typename?: 'CaregiverRejectBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'conversationId'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type GetDataForBookingInvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDataForBookingInvoiceQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'paymentConfirmedAt' | 'address'>
    & { total: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), totalFee: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), totalFeeVat: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), baseFee: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), country: (
      { __typename?: 'Country' }
      & Pick<Country, 'iso3661Alpha2' | 'vat'>
    ), user?: Maybe<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'id'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'id'>
    )>, caregiver?: Maybe<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'publicAddress'>
    )> }
  ), currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'name' | 'surname' | 'address1' | 'address2'>
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'name' | 'surname' | 'address1' | 'address2'>
  )> }
);

export type GetCurrentUserForPaymentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserForPaymentQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'name' | 'surname' | 'address1' | 'address2' | 'zip' | 'birthday' | 'canCreateABillingProfile'>
    & { billingCards?: Maybe<Array<(
      { __typename?: 'BillingCard' }
      & Pick<BillingCard, 'id' | 'alias' | 'provider' | 'expirationYear' | 'expirationMonth'>
    )>>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'slug' | 'name' | 'placeId' | 'latitude' | 'longitude'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'locale' | 'currency' | 'fullDomain' | 'iso3661Alpha2' | 'paymentMethods'>
    )> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'name' | 'surname' | 'address1' | 'address2' | 'zip' | 'birthday' | 'canCreateABillingProfile'>
    & { billingCards?: Maybe<Array<(
      { __typename?: 'BillingCard' }
      & Pick<BillingCard, 'id' | 'alias' | 'provider' | 'expirationYear' | 'expirationMonth'>
    )>>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'slug' | 'name' | 'placeId' | 'latitude' | 'longitude'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'locale' | 'currency' | 'fullDomain' | 'iso3661Alpha2' | 'paymentMethods'>
    )> }
  )> }
);

export type GetBookingForPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingForPaymentQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'Booking' }
    & BookingPartsFragment
  ) }
);

export type BookingPartsFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id' | 'fromDogley' | 'canBeTransferredToBankAccount' | 'availableEvents' | 'serviceDates' | 'startDate' | 'endDate' | 'serviceQuantity' | 'createdAt' | 'updatedAt' | 'status' | 'dogIds' | 'canCaregiverBeReviewed' | 'caregiverId' | 'userId' | 'conversationId' | 'numberOfTrips' | 'pickUpDropOff'>
  & { total: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), totalFee: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), serviceTotal: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), serviceType: (
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'name' | 'pricePer'>
  ), bookingDogs?: Maybe<Array<(
    { __typename?: 'BookingDog' }
    & Pick<BookingDog, 'id' | 'dogId' | 'bookingId' | 'canDogBeReviewed'>
    & { price?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'toS' | 'amount' | 'currency'>
    )>, dog?: Maybe<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'gender'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )> }
  )>>, pickUpDropOffPrice?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'toS' | 'amount' | 'currency'>
  )>, costAdjustment?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'toS' | 'amount' | 'currency'>
  )>, couponRedemption?: Maybe<(
    { __typename?: 'CouponRedemption' }
    & Pick<CouponRedemption, 'id'>
    & { coupon: (
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'id' | 'code' | 'percentageDiscount' | 'currency' | 'startsAt' | 'expiresAt'>
      & { fixedDiscount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS' | 'amount' | 'currency'>
      )>, minimumAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS' | 'amount' | 'currency'>
      )> }
    ) }
  )>, service?: Maybe<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'price' | 'holidayPrice' | 'pickUpDropOffPrice' | 'currency'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  name: Scalars['String'];
  surname: Scalars['String'];
  birthday: Scalars['ISO8601Date'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  city: CityInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id' | 'name' | 'surname' | 'address1' | 'address2' | 'zip' | 'birthday' | 'currency'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'name' | 'placeId' | 'latitude' | 'longitude'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'id' | 'name' | 'surname' | 'address1' | 'address2' | 'zip' | 'birthday' | 'currency'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'name' | 'placeId' | 'latitude' | 'longitude'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type InitBillingCardRegistrationMutationVariables = Exact<{
  currency: Currency;
  cardType?: Maybe<CardType>;
}>;


export type InitBillingCardRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { initBillingCardRegistration?: Maybe<(
    { __typename?: 'InitBillingCardRegistrationPayload' }
    & { cardRegistrationData?: Maybe<(
      { __typename?: 'BillingCardRegistration' }
      & Pick<BillingCardRegistration, 'accessKey' | 'cardRegistrationUrl' | 'id' | 'preregistrationData'>
    )> }
  )> }
);

export type CreateBillingCardMutationMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CreateBillingCardMutationMutation = (
  { __typename?: 'Mutation' }
  & { createBillingCard?: Maybe<(
    { __typename?: 'CreateBillingCardPayload' }
    & { billingCard?: Maybe<(
      { __typename?: 'BillingCard' }
      & Pick<BillingCard, 'id' | 'alias' | 'expirationMonth' | 'expirationYear' | 'provider' | 'currency'>
    )> }
  )> }
);

export type DeleteBillingCardMutationVariables = Exact<{
  billingCardId: Scalars['ID'];
}>;


export type DeleteBillingCardMutation = (
  { __typename?: 'Mutation' }
  & { deleteBillingCard?: Maybe<(
    { __typename?: 'DeleteBillingCardPayload' }
    & { billingCards?: Maybe<Array<(
      { __typename?: 'BillingCard' }
      & Pick<BillingCard, 'id' | 'alias' | 'provider' | 'expirationYear' | 'expirationMonth'>
    )>>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )> }
  )> }
);

export type OwnerConfirmBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  billingCardId?: Maybe<Scalars['ID']>;
  browserInfo?: Maybe<BrowserInfoInput>;
  directDebitMethod?: Maybe<DirectDebitMethod>;
}>;


export type OwnerConfirmBookingMutation = (
  { __typename?: 'Mutation' }
  & { ownerConfirmBooking?: Maybe<(
    { __typename?: 'OwnerConfirmBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'conversationId'>
    )>, payment?: Maybe<(
      { __typename?: 'BillingPayment' }
      & Pick<BillingPayment, 'id' | 'status' | 'redirectUrl'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type OwnerPreapproveBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  billingCardId: Scalars['ID'];
  browserInfo: BrowserInfoInput;
}>;


export type OwnerPreapproveBookingMutation = (
  { __typename?: 'Mutation' }
  & { ownerPreapproveBooking?: Maybe<(
    { __typename?: 'OwnerPreapproveBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & BookingPartsFragment
    )>, payment?: Maybe<(
      { __typename?: 'BillingPayment' }
      & Pick<BillingPayment, 'id' | 'status' | 'redirectUrl'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type GetDataForBookingReceiptQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDataForBookingReceiptQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'startDate' | 'endDate' | 'serviceDates' | 'serviceQuantity' | 'paymentConfirmedAt' | 'freeCancellationLimit'>
    & { unitPrice: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), total: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), totalFee: (
      { __typename?: 'Money' }
      & Pick<Money, 'toS'>
    ), service?: Maybe<(
      { __typename?: 'Service' }
      & Pick<Service, 'price' | 'currency'>
    )>, countryServiceType: (
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'pricePer'>
      )> }
    ), bookingDogs?: Maybe<Array<(
      { __typename?: 'BookingDog' }
      & { dog?: Maybe<(
        { __typename?: 'Dog' }
        & Pick<Dog, 'name'>
      )> }
    )>>, user?: Maybe<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'name' | 'publicFullName'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id' | 'name' | 'publicFullName'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'id' | 'name' | 'publicFullName'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'id' | 'name' | 'publicFullName'>
    )>, caregiver?: Maybe<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'name' | 'publicAddress' | 'publicFullName'>
    )>, payment?: Maybe<(
      { __typename?: 'BillingPayment' }
      & { paymentMethod?: Maybe<(
        { __typename?: 'PaymentMethodInfo' }
        & Pick<PaymentMethodInfo, 'type' | 'alias' | 'provider'>
      )> }
    )> }
  ), currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id'>
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id'>
  )> }
);

export type GetCurrenUserWithMagicLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrenUserWithMagicLinkQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'appMagicLink'>
    & CurrentUserParts_CurrentCaregiver_Fragment
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'appMagicLink'>
    & CurrentUserParts_CurrentOwner_Fragment
  )> }
);

export type SmsMagicLinkMutationVariables = Exact<{
  input: SendSmsWithAppMagicLinkInput;
}>;


export type SmsMagicLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendSmsWithAppMagicLink?: Maybe<(
    { __typename?: 'SendSMSWithAppMagicLinkPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type GetCountryServiceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryServiceTypesQuery = (
  { __typename?: 'Query' }
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'iso3661Alpha2'>
    & { countryServiceTypes?: Maybe<Array<(
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'id' | 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
      )> }
    )>> }
  )> }
);

export type DogProfilePartsFragment = (
  { __typename?: 'Dog' }
  & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'slug' | 'birthday' | 'spayedNeutered' | 'chip' | 'playsDogs' | 'playsDogsDesc' | 'playsCats' | 'playsCatsDesc' | 'playsChildren' | 'playsChildrenDesc' | 'observations' | 'tGender' | 'tAge' | 'tAgeShort' | 'tSize' | 'activityLevel' | 'dailyFood' | 'walkingInstructions' | 'homeAlone' | 'healthInfo' | 'indoorPrecautions'>
  & { avatar?: Maybe<(
    { __typename?: 'DogAvatar' }
    & Pick<DogAvatar, 'circle' | 'big'>
  )>, breed?: Maybe<(
    { __typename?: 'Breed' }
    & Pick<Breed, 'id' | 'name' | 'tName'>
  )>, photos?: Maybe<Array<(
    { __typename?: 'DogPhoto' }
    & Pick<DogPhoto, 'id' | 'miniThumb' | 'original' | 'slider' | 'thumb' | 'title'>
  )>>, dailyFoodServings?: Maybe<(
    { __typename?: 'DayPeriod' }
    & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
  )>, dailyWalks?: Maybe<(
    { __typename?: 'DayPeriod' }
    & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
  )> }
);

export type AddDogMutationVariables = Exact<{
  input: AddDogInput;
}>;


export type AddDogMutation = (
  { __typename?: 'Mutation' }
  & { addDog?: Maybe<(
    { __typename?: 'AddDogPayload' }
    & { dog?: Maybe<(
      { __typename?: 'Dog' }
      & DogProfilePartsFragment
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type EditDogMutationVariables = Exact<{
  input: EditDogInput;
}>;


export type EditDogMutation = (
  { __typename?: 'Mutation' }
  & { editDog?: Maybe<(
    { __typename?: 'EditDogPayload' }
    & { dog?: Maybe<(
      { __typename?: 'Dog' }
      & DogProfilePartsFragment
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type DeleteDogMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDogMutation = (
  { __typename?: 'Mutation' }
  & { deleteDog?: Maybe<(
    { __typename?: 'DeleteDogPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type GetInboxQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  type?: Maybe<InboxType>;
}>;


export type GetInboxQuery = (
  { __typename?: 'Query' }
  & { inbox: (
    { __typename?: 'ConversationSummaryConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ConversationSummary' }
      & ConversationSummaryPartsFragment
    )>>> }
  ), potentialJobs: Array<(
    { __typename?: 'PotentialJob' }
    & Pick<PotentialJob, 'bookingId' | 'startDate' | 'endDate' | 'serviceDates' | 'serviceTypeId' | 'cityName'>
    & { dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'name'>
    )> }
  )> }
);

export type InboxUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InboxUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { inboxUpdated: (
    { __typename?: 'InboxUpdatedPayload' }
    & { conversation?: Maybe<(
      { __typename?: 'ConversationSummary' }
      & ConversationSummaryPartsFragment
    )> }
  ) }
);

export type ConversationSummaryPartsFragment = (
  { __typename?: 'ConversationSummary' }
  & Pick<ConversationSummary, 'id' | 'unreadMessages'>
  & { partner: (
    { __typename?: 'Caregiver' }
    & Pick<Caregiver, 'id' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'name'>
    )>> }
  ) | (
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'name'>
    )>> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'name'>
    )>> }
  ) | (
    { __typename?: 'Owner' }
    & Pick<Owner, 'id' | 'name'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'name'>
    )>> }
  ), lastMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'createdAt' | 'content' | 'type'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
      & { user?: Maybe<(
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id'>
      ) | (
        { __typename?: 'CurrentCaregiver' }
        & Pick<CurrentCaregiver, 'id'>
      ) | (
        { __typename?: 'CurrentOwner' }
        & Pick<CurrentOwner, 'id'>
      ) | (
        { __typename?: 'Owner' }
        & Pick<Owner, 'id'>
      )>, caregiver?: Maybe<(
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id'>
      )> }
    )> }
  ), bookingHistory: (
    { __typename?: 'BookingHistory' }
    & Pick<BookingHistory, 'requests' | 'upcoming' | 'past'>
  ) }
);

export type GetOwnerJobsForConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnerJobsForConversationsQuery = (
  { __typename?: 'Query' }
  & { ownerJobs: Array<(
    { __typename?: 'JobAuthor' }
    & Pick<JobAuthor, 'id' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { estimatedPaymentTotal?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS'>
      )>, caregiver: (
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle'>
        )> }
      ) }
    )>>, city: (
      { __typename?: 'City' }
      & Pick<City, 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name'>
    )> }
  ) | (
    { __typename?: 'JobPublic' }
    & Pick<JobPublic, 'id' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name'>
    )> }
  )> }
);

export type OwnerDeclineJobApplicationMutationVariables = Exact<{
  input: OwnerDeclineJobApplicationInput;
}>;


export type OwnerDeclineJobApplicationMutation = (
  { __typename?: 'Mutation' }
  & { ownerDeclineJobApplication?: Maybe<(
    { __typename?: 'OwnerDeclineJobApplicationPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )> }
  )> }
);

export type OwnerAcceptJobApplicationMutationVariables = Exact<{
  input: OwnerAcceptJobApplicationInput;
}>;


export type OwnerAcceptJobApplicationMutation = (
  { __typename?: 'Mutation' }
  & { ownerAcceptJobApplication?: Maybe<(
    { __typename?: 'OwnerAcceptJobApplicationPayload' }
    & { conversation?: Maybe<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )> }
  )> }
);

type CurrentUserParts_CurrentCaregiver_Fragment = (
  { __typename?: 'CurrentCaregiver' }
  & Pick<CurrentCaregiver, 'id' | 'name' | 'surname' | 'email' | 'type' | 'canCreateABillingProfile'>
  & { avatar?: Maybe<(
    { __typename?: 'UserAvatar' }
    & Pick<UserAvatar, 'circle'>
  )>, billingProfile?: Maybe<(
    { __typename?: 'BillingProfile' }
    & Pick<BillingProfile, 'id' | 'identityStatus'>
  )> }
);

type CurrentUserParts_CurrentOwner_Fragment = (
  { __typename?: 'CurrentOwner' }
  & Pick<CurrentOwner, 'id' | 'name' | 'surname' | 'email' | 'type' | 'canCreateABillingProfile'>
  & { avatar?: Maybe<(
    { __typename?: 'UserAvatar' }
    & Pick<UserAvatar, 'circle'>
  )>, billingProfile?: Maybe<(
    { __typename?: 'BillingProfile' }
    & Pick<BillingProfile, 'id' | 'identityStatus'>
  )> }
);

export type CurrentUserPartsFragment = CurrentUserParts_CurrentCaregiver_Fragment | CurrentUserParts_CurrentOwner_Fragment;

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'name' | 'surname' | 'email' | 'type' | 'intercomUserHash'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'name' | 'surname' | 'email' | 'type' | 'intercomUserHash'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )> }
  )> }
);

export type GetCurrentUserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserIdQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id'>
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id'>
  )> }
);

export type GetDogProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDogProfileQuery = (
  { __typename?: 'Query' }
  & { dog: (
    { __typename?: 'Dog' }
    & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'slug' | 'birthday' | 'spayedNeutered' | 'chip' | 'playsDogs' | 'playsDogsDesc' | 'playsCats' | 'playsCatsDesc' | 'playsChildren' | 'playsChildrenDesc' | 'observations' | 'tGender' | 'tAge' | 'tAgeShort' | 'tSize' | 'activityLevel' | 'dailyFood' | 'walkingInstructions' | 'homeAlone' | 'healthInfo' | 'indoorPrecautions'>
    & { avatar?: Maybe<(
      { __typename?: 'DogAvatar' }
      & Pick<DogAvatar, 'circle' | 'big'>
    )>, breed?: Maybe<(
      { __typename?: 'Breed' }
      & Pick<Breed, 'id' | 'name' | 'tName'>
    )>, photos?: Maybe<Array<(
      { __typename?: 'DogPhoto' }
      & Pick<DogPhoto, 'id' | 'miniThumb' | 'original' | 'slider' | 'thumb' | 'title'>
    )>>, dailyFoodServings?: Maybe<(
      { __typename?: 'DayPeriod' }
      & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
    )>, dailyWalks?: Maybe<(
      { __typename?: 'DayPeriod' }
      & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
    )>, user: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'name'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { nearbyCities?: Maybe<Array<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'slug' | 'placeId' | 'activeCaregiversCount'>
        )>> }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )> }
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id' | 'name'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { nearbyCities?: Maybe<Array<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'slug' | 'placeId' | 'activeCaregiversCount'>
        )>> }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )> }
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'id' | 'name'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { nearbyCities?: Maybe<Array<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'slug' | 'placeId' | 'activeCaregiversCount'>
        )>> }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )> }
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'id' | 'name'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { nearbyCities?: Maybe<Array<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'slug' | 'placeId' | 'activeCaregiversCount'>
        )>> }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )> }
    ), stats?: Maybe<Array<(
      { __typename?: 'DogStats' }
      & Pick<DogStats, 'bookingsCount'>
      & { serviceType: (
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'id' | 'name' | 'pricePer'>
      ) }
    )>>, sameBreedDogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'tAgeShort' | 'gender' | 'slug'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>>, reviews?: Maybe<Array<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'content' | 'createdAt' | 'score'>
      & { author: (
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'slug' | 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle' | 'thumb'>
        )> }
      ) | (
        { __typename?: 'CurrentCaregiver' }
        & Pick<CurrentCaregiver, 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle' | 'thumb'>
        )> }
      ) | (
        { __typename?: 'CurrentOwner' }
        & Pick<CurrentOwner, 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle' | 'thumb'>
        )> }
      ) | (
        { __typename?: 'Owner' }
        & Pick<Owner, 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle' | 'thumb'>
        )> }
      ) }
    )>> }
  ) }
);

export type GetCurrentUserForLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserForLayoutQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'name' | 'surname' | 'email' | 'type' | 'canCreateABillingProfile' | 'intercomUserHash'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, billingProfile?: Maybe<(
      { __typename?: 'BillingProfile' }
      & Pick<BillingProfile, 'id' | 'identityStatus'>
    )>, notifications?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'unreadMessagesCountUser' | 'unreadMessagesCountCaregiver'>
    )> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'name' | 'surname' | 'email' | 'type' | 'canCreateABillingProfile' | 'intercomUserHash'>
    & { avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, billingProfile?: Maybe<(
      { __typename?: 'BillingProfile' }
      & Pick<BillingProfile, 'id' | 'identityStatus'>
    )>, notifications?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'unreadMessagesCountUser' | 'unreadMessagesCountCaregiver'>
    )> }
  )> }
);

export type GetCurrentUserNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserNotificationsQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & { notifications?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'unreadMessagesCountUser' | 'unreadMessagesCountCaregiver'>
    )> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & { notifications?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'unreadMessagesCountUser' | 'unreadMessagesCountCaregiver'>
    )> }
  )> }
);

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPaymentQuery = (
  { __typename?: 'Query' }
  & { payment: (
    { __typename?: 'BillingPayment' }
    & Pick<BillingPayment, 'id' | 'status'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'conversationId'>
    ) }
  ) }
);

export type UpdateCaregiverDeactivateMutationVariables = Exact<{
  input: UpdateCaregiverDeactivateInput;
}>;


export type UpdateCaregiverDeactivateMutation = (
  { __typename?: 'Mutation' }
  & { updateCaregiverDeactivate?: Maybe<(
    { __typename?: 'UpdateCaregiverDeactivatePayload' }
    & { caregiver?: Maybe<(
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id' | 'caregiverStatus' | 'caregiverDeactivatedAt'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type GetCountryAndCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountryAndCurrentUserQuery = (
  { __typename?: 'Query' }
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'iso3661Alpha2'>
    & { countryServiceTypes?: Maybe<Array<(
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'id' | 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
      )> }
    )>> }
  )>, currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentCaregiver_Fragment
  ) | (
    { __typename?: 'CurrentOwner' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentOwner_Fragment
  )> }
);

export type SearchQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  includePrice?: Maybe<Scalars['Boolean']>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  dogs?: Maybe<Array<DogFilter>>;
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'totalPages' | 'totalEntries' | 'currentPage'>
    & { serviceType?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'pricePer' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'routeServiceParam'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug'>
      & { nearbyCities?: Maybe<Array<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug' | 'activeCaregiversCount'>
      )>> }
    )>, topCities?: Maybe<Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug' | 'activeCaregiversCount'>
    )>>, caregivers: Array<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'caregiverHeadline' | 'name' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'currency' | 'repeatCustomersCount' | 'latitude' | 'longitude' | 'publicAddress' | 'availableHolidays' | 'calendarUpToDate' | 'slug' | 'price' | 'caregiverCalendarUpdatedAt'>
      & { featuredReview?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'content'>
        & { author: (
          { __typename?: 'Caregiver' }
          & Pick<Caregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentCaregiver' }
          & Pick<CurrentCaregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentOwner' }
          & Pick<CurrentOwner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'Owner' }
          & Pick<Owner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )>, city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'name'>
      )>, bookingEstimation?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount' | 'toS'>
      )>, availablePromotions?: Maybe<Array<(
        { __typename?: 'CaregiverPromotion' }
        & Pick<CaregiverPromotion, 'id' | 'tLabel'>
      )>>, reviews?: Maybe<(
        { __typename?: 'PaginatedReviews' }
        & { results?: Maybe<Array<(
          { __typename?: 'Review' }
          & Pick<Review, 'content'>
          & { author: (
            { __typename?: 'Caregiver' }
            & { avatar?: Maybe<(
              { __typename?: 'UserAvatar' }
              & Pick<UserAvatar, 'thumb'>
            )> }
          ) | (
            { __typename?: 'CurrentCaregiver' }
            & { avatar?: Maybe<(
              { __typename?: 'UserAvatar' }
              & Pick<UserAvatar, 'thumb'>
            )> }
          ) | (
            { __typename?: 'CurrentOwner' }
            & { avatar?: Maybe<(
              { __typename?: 'UserAvatar' }
              & Pick<UserAvatar, 'thumb'>
            )> }
          ) | (
            { __typename?: 'Owner' }
            & { avatar?: Maybe<(
              { __typename?: 'UserAvatar' }
              & Pick<UserAvatar, 'thumb'>
            )> }
          ) }
        )>> }
      )> }
    )> }
  )> }
);

export type SearchCityQueryVariables = Exact<{
  city: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  includePrice?: Maybe<Scalars['Boolean']>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  dogs?: Maybe<Array<DogFilter>>;
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
}>;


export type SearchCityQuery = (
  { __typename?: 'Query' }
  & { searchCity?: Maybe<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'metaTitle' | 'metaDescription' | 'title' | 'contentHtml' | 'totalEntries' | 'totalPages' | 'currentPage'>
    & { serviceType?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'pricePer' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'routeServiceParam'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'avgCaregiverReviewsScore' | 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug' | 'seoLandingNoindexTag' | 'sumCaregiverReviewsCount'>
      & { nearbyCities?: Maybe<Array<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'activeCaregiversCount'>
      )>>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'locale'>
      )> }
    )>, caregivers: Array<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'caregiverHeadline' | 'name' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'repeatCustomersCount' | 'latitude' | 'longitude' | 'publicAddress' | 'availableHolidays' | 'calendarUpToDate' | 'slug' | 'price'>
      & { featuredReview?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'content'>
        & { author: (
          { __typename?: 'Caregiver' }
          & Pick<Caregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentCaregiver' }
          & Pick<CurrentCaregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentOwner' }
          & Pick<CurrentOwner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'Owner' }
          & Pick<Owner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )>, city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'name'>
      )>, bookingEstimation?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount' | 'toS'>
      )>, availablePromotions?: Maybe<Array<(
        { __typename?: 'CaregiverPromotion' }
        & Pick<CaregiverPromotion, 'id' | 'tLabel'>
      )>> }
    )> }
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'iso3661Alpha2'>
    & { countryServiceTypes?: Maybe<Array<(
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'id' | 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
      )> }
    )>> }
  )>, currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentCaregiver_Fragment
  ) | (
    { __typename?: 'CurrentOwner' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentOwner_Fragment
  )> }
);

export type SearchCityServiceTypeQueryVariables = Exact<{
  service: Scalars['String'];
  city: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  includePrice?: Maybe<Scalars['Boolean']>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  dogs?: Maybe<Array<DogFilter>>;
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
}>;


export type SearchCityServiceTypeQuery = (
  { __typename?: 'Query' }
  & { searchCityServiceType?: Maybe<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'metaTitle' | 'metaDescription' | 'title' | 'contentHtml' | 'totalEntries' | 'totalPages' | 'currentPage'>
    & { serviceType?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'pricePer' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'routeServiceParam'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'avgCaregiverReviewsScore' | 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug' | 'seoLandingNoindexTag' | 'sumCaregiverReviewsCount'>
      & { nearbyCities?: Maybe<Array<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'activeCaregiversCount'>
      )>>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'locale'>
      )> }
    )>, caregivers: Array<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'caregiverHeadline' | 'name' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'repeatCustomersCount' | 'latitude' | 'longitude' | 'publicAddress' | 'availableHolidays' | 'calendarUpToDate' | 'slug' | 'price'>
      & { featuredReview?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'content'>
        & { author: (
          { __typename?: 'Caregiver' }
          & Pick<Caregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentCaregiver' }
          & Pick<CurrentCaregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentOwner' }
          & Pick<CurrentOwner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'Owner' }
          & Pick<Owner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )>, city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'name'>
      )>, bookingEstimation?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount' | 'toS'>
      )>, availablePromotions?: Maybe<Array<(
        { __typename?: 'CaregiverPromotion' }
        & Pick<CaregiverPromotion, 'id' | 'tLabel'>
      )>> }
    )> }
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'iso3661Alpha2'>
    & { countryServiceTypes?: Maybe<Array<(
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'id' | 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
      )> }
    )>> }
  )>, currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentCaregiver_Fragment
  ) | (
    { __typename?: 'CurrentOwner' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentOwner_Fragment
  )> }
);

export type SearchServiceTypeQueryVariables = Exact<{
  service: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pid?: Maybe<Scalars['String']>;
  neLat?: Maybe<Scalars['String']>;
  neLng?: Maybe<Scalars['String']>;
  swLat?: Maybe<Scalars['String']>;
  swLng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  serviceTypeId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  serviceDates?: Maybe<Scalars['DateArray']>;
  includePrice?: Maybe<Scalars['Boolean']>;
  dogIds?: Maybe<Array<Scalars['ID']>>;
  dogs?: Maybe<Array<DogFilter>>;
  withDogs?: Maybe<Scalars['Boolean']>;
  withOutsideArea?: Maybe<Scalars['Boolean']>;
  withoutKids?: Maybe<Scalars['Boolean']>;
}>;


export type SearchServiceTypeQuery = (
  { __typename?: 'Query' }
  & { searchServiceType?: Maybe<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'totalEntries' | 'totalPages' | 'currentPage'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug'>
      & { nearbyCities?: Maybe<Array<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'activeCaregiversCount'>
      )>> }
    )>, serviceType?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'pricePer' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'routeServiceParam'>
    )>, topCities?: Maybe<Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'placeId' | 'latitude' | 'longitude' | 'name' | 'slug' | 'activeCaregiversCount'>
    )>>, caregivers: Array<(
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'caregiverHeadline' | 'name' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'repeatCustomersCount' | 'latitude' | 'longitude' | 'publicAddress' | 'availableHolidays' | 'calendarUpToDate' | 'slug' | 'price'>
      & { featuredReview?: Maybe<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'content'>
        & { author: (
          { __typename?: 'Caregiver' }
          & Pick<Caregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentCaregiver' }
          & Pick<CurrentCaregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentOwner' }
          & Pick<CurrentOwner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'Owner' }
          & Pick<Owner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) }
      )>, avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )>, city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'slug' | 'placeId' | 'latitude' | 'longitude' | 'name'>
      )>, bookingEstimation?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount' | 'toS'>
      )>, availablePromotions?: Maybe<Array<(
        { __typename?: 'CaregiverPromotion' }
        & Pick<CaregiverPromotion, 'id' | 'tLabel'>
      )>> }
    )> }
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'iso3661Alpha2'>
    & { countryServiceTypes?: Maybe<Array<(
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'id' | 'name'>
      & { serviceType?: Maybe<(
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
      )> }
    )>> }
  )>, currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentCaregiver_Fragment
  ) | (
    { __typename?: 'CurrentOwner' }
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
    & CurrentUserParts_CurrentOwner_Fragment
  )> }
);

export type GetCurrentUserForContactQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserForContactQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'phone' | 'type'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'phone' | 'type'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'birthday' | 'tAge' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>> }
  )> }
);

export type GetCaregiverForContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCaregiverForContactQuery = (
  { __typename?: 'Query' }
  & { caregiver?: Maybe<(
    { __typename?: 'Caregiver' }
    & Pick<Caregiver, 'id' | 'name' | 'type' | 'latitude' | 'longitude' | 'slug'>
    & { services?: Maybe<Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'currency' | 'pickUpDropOff' | 'pickUpDropOffDistance' | 'pickUpDropOffPrice'>
      & { countryServiceType?: Maybe<(
        { __typename?: 'CountryServiceType' }
        & Pick<CountryServiceType, 'id' | 'name' | 'description'>
        & { serviceType?: Maybe<(
          { __typename?: 'ServiceType' }
          & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'pricePer'>
        )> }
      )> }
    )>> }
  )> }
);

export type ContactCaregiverMutationVariables = Exact<{
  caregiverId: Scalars['ID'];
  message: Scalars['String'];
  serviceTypeId: Scalars['ID'];
  dogIds: Array<Scalars['ID']>;
  pickUpDropOff?: Maybe<PickUpDropOffInput>;
  bookingDatesInput: BookingDatesInput;
  phone?: Maybe<Scalars['String']>;
  recaptchaToken: Scalars['String'];
}>;


export type ContactCaregiverMutation = (
  { __typename?: 'Mutation' }
  & { contactCaregiver?: Maybe<(
    { __typename?: 'ContactCaregiverPayload' }
    & { potentialJob?: Maybe<(
      { __typename?: 'PotentialJob' }
      & Pick<PotentialJob, 'bookingId' | 'startDate' | 'endDate' | 'serviceDates' | 'serviceTypeId' | 'cityName'>
      & { dogs: Array<(
        { __typename?: 'Dog' }
        & Pick<Dog, 'name'>
      )> }
    )>, booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'serviceQuantity'>
      & { caregiver?: Maybe<(
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id' | 'name'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )> }
      )>, service?: Maybe<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'price'>
      )>, total: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency'>
      ), serviceType: (
        { __typename?: 'ServiceType' }
        & Pick<ServiceType, 'name'>
      ) }
    )>, conversation?: Maybe<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id'>
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type CreateJobMutationVariables = Exact<{
  input: CreateJobFromBookingInput;
}>;


export type CreateJobMutation = (
  { __typename?: 'Mutation' }
  & { createJobFromBooking?: Maybe<(
    { __typename?: 'CreateJobFromBookingPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )> }
  )> }
);

export type BookingPriceCalculationQueryVariables = Exact<{
  serviceId: Scalars['ID'];
  dogIds: Array<Scalars['ID']>;
  bookingDates: BookingDatesInput;
  pickUpDropOff?: Maybe<PickUpDropOffInput>;
}>;


export type BookingPriceCalculationQuery = (
  { __typename?: 'Query' }
  & { bookingPrice?: Maybe<(
    { __typename?: 'BookingPriceEstimation' }
    & Pick<BookingPriceEstimation, 'pricePer' | 'duration'>
    & { fee: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'toS'>
    ), total: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'toS'>
    ), holidayServicePrice?: Maybe<(
      { __typename?: 'PriceForDogs' }
      & Pick<PriceForDogs, 'count'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'toS'>
      ) }
    )>, standardServicePrice?: Maybe<(
      { __typename?: 'PriceForDogs' }
      & Pick<PriceForDogs, 'count'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency' | 'toS'>
      ) }
    )>, serviceTotal: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'toS'>
    ), pickUpDropOffPrice?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'toS'>
    )> }
  )> }
);

export type GetCaregiverQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetCaregiverQuery = (
  { __typename?: 'Query' }
  & { caregiver?: Maybe<(
    { __typename?: 'Caregiver' }
    & Pick<Caregiver, 'id' | 'name' | 'cleanHeadline' | 'caregiverStatus' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'repeatCustomersCount' | 'publicAddress' | 'caregiverDescription' | 'latitude' | 'longitude' | 'caregiverXpAcommodatingDogs' | 'caregiverXpAsDogWalker' | 'caregiverCalendarUpdatedAt' | 'caregiverHasAnimalCertificate' | 'caregiverHasKidsUnder18' | 'caregiverHasPppLicense' | 'caregiverProvideCustomerReviews' | 'caregiverOwnVehicle' | 'caregiverCanAdministerOralMeds' | 'caregiverCanAdministerInjectedMeds' | 'caregiverVolunteerAnimalGroups' | 'slug' | 'bookmarked' | 'lastSeenAt' | 'responseTime' | 'bookingsRatio' | 'answerRatio'>
    & { availablePromotions?: Maybe<Array<(
      { __typename?: 'CaregiverPromotion' }
      & Pick<CaregiverPromotion, 'id' | 'tLabel'>
    )>>, property?: Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'type' | 'garden' | 'balcony' | 'terrace' | 'outdoorSize'>
    )>, services?: Maybe<Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'serviceTypeId' | 'currency' | 'price' | 'holidayPrice' | 'pickUpDropOff' | 'pickUpDropOffPrice' | 'pickUpDropOffDistance'>
      & { acceptsDogs?: Maybe<(
        { __typename?: 'AcceptsDogs' }
        & Pick<AcceptsDogs, 'puppy' | 'small' | 'medium' | 'large' | 'giant'>
      )> }
    )>>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'profile'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle'>
      )> }
    )>>, reviews?: Maybe<(
      { __typename?: 'PaginatedReviews' }
      & Pick<PaginatedReviews, 'currentPage' | 'totalPages'>
      & { results?: Maybe<Array<(
        { __typename?: 'Review' }
        & Pick<Review, 'id' | 'score' | 'content' | 'createdAt'>
        & { author: (
          { __typename?: 'Caregiver' }
          & Pick<Caregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentCaregiver' }
          & Pick<CurrentCaregiver, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'CurrentOwner' }
          & Pick<CurrentOwner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) | (
          { __typename?: 'Owner' }
          & Pick<Owner, 'id' | 'name'>
          & { avatar?: Maybe<(
            { __typename?: 'UserAvatar' }
            & Pick<UserAvatar, 'thumb'>
          )> }
        ) }
      )>> }
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'locale'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
      & { nearbyCities?: Maybe<Array<(
        { __typename?: 'City' }
        & Pick<City, 'name' | 'slug' | 'placeId' | 'activeCaregiversCount'>
      )>> }
    )>, photos?: Maybe<Array<(
      { __typename?: 'UserPhoto' }
      & Pick<UserPhoto, 'id' | 'slider' | 'thumb' | 'title'>
    )>> }
  )> }
);

export type GetTopReviewsByCountryQueryVariables = Exact<{
  countryId: Scalars['ID'];
}>;


export type GetTopReviewsByCountryQuery = (
  { __typename?: 'Query' }
  & { topReviewsByCountry: Array<(
    { __typename?: 'Review' }
    & Pick<Review, 'score' | 'content' | 'createdAt'>
    & { author: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'name'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'id' | 'name'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'id' | 'name'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'id' | 'name'>
    ), reviewable: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'UserAvatar' }
        & Pick<UserAvatar, 'circle'>
      )> }
    ) | { __typename?: 'Dog' } }
  )> }
);

export type GetOwnerBookingsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type GetOwnerBookingsQuery = (
  { __typename?: 'Query' }
  & { ownerBookings: (
    { __typename?: 'PaginatedBookings' }
    & Pick<PaginatedBookings, 'currentPage' | 'totalPages'>
    & { results: Array<(
      { __typename?: 'Booking' }
      & OwnerBookingFieldsFragment
    )> }
  ) }
);

export type OwnerBookingFieldsFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id' | 'canBeTransferredToBankAccount' | 'availableEvents' | 'serviceDates' | 'startDate' | 'endDate' | 'serviceQuantity' | 'createdAt' | 'updatedAt' | 'status' | 'dogIds' | 'canCaregiverBeReviewed' | 'conversationId' | 'numberOfTrips'>
  & { refundAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  )>, transferAmount?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  )>, dogReviews?: Maybe<Array<(
    { __typename?: 'Review' }
    & Pick<Review, 'id' | 'content' | 'score'>
    & { reviewable: { __typename?: 'Caregiver' } | (
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name'>
    ) }
  )>>, caregiverReview?: Maybe<(
    { __typename?: 'Review' }
    & Pick<Review, 'id' | 'content' | 'score'>
    & { reviewable: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'id' | 'name'>
    ) | { __typename?: 'Dog' } }
  )>, bankWire?: Maybe<(
    { __typename?: 'BillingBankWire' }
    & Pick<BillingBankWire, 'id' | 'createdAt'>
    & { total: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency' | 'toS'>
    ) }
  )>, total: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), totalFee: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), serviceTotal: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency' | 'toS'>
  ), serviceType: (
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'alwaysPickUpDropOff' | 'fullDayAvailability' | 'name' | 'pricePer'>
  ), caregiver?: Maybe<(
    { __typename?: 'Caregiver' }
    & Pick<Caregiver, 'id' | 'name' | 'latitude' | 'longitude' | 'cleanHeadline' | 'caregiverHeadline' | 'caregiverReviewsCount' | 'caregiverReviewsScore' | 'repeatCustomersCount' | 'publicAddress' | 'slug'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    )>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'Caregiver' }
    & Pick<Caregiver, 'id' | 'name'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    )>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )> }
    )>> }
  ) | (
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id' | 'name'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    )>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )> }
    )>> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id' | 'name'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    )>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )> }
    )>> }
  ) | (
    { __typename?: 'Owner' }
    & Pick<Owner, 'id' | 'name'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    )>, avatar?: Maybe<(
      { __typename?: 'UserAvatar' }
      & Pick<UserAvatar, 'circle'>
    )>, dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )> }
    )>> }
  )>, bookingDogs?: Maybe<Array<(
    { __typename?: 'BookingDog' }
    & Pick<BookingDog, 'id' | 'dogId' | 'bookingId' | 'canDogBeReviewed'>
    & { price?: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'toS' | 'amount' | 'currency'>
    )>, dog?: Maybe<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )> }
    )> }
  )>>, dogs: Array<(
    { __typename?: 'Dog' }
    & Pick<Dog, 'id' | 'name' | 'size' | 'tAge' | 'birthday' | 'slug'>
    & { avatar?: Maybe<(
      { __typename?: 'DogAvatar' }
      & Pick<DogAvatar, 'circle' | 'big'>
    )> }
  )>, pickUpDropOffPrice?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'toS' | 'amount' | 'currency'>
  )>, costAdjustment?: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'toS' | 'amount' | 'currency'>
  )>, couponRedemption?: Maybe<(
    { __typename?: 'CouponRedemption' }
    & Pick<CouponRedemption, 'id'>
    & { coupon: (
      { __typename?: 'Coupon' }
      & Pick<Coupon, 'id' | 'code' | 'percentageDiscount' | 'currency' | 'startsAt' | 'expiresAt'>
      & { fixedDiscount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS' | 'amount' | 'currency'>
      )>, minimumAmount?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS' | 'amount' | 'currency'>
      )> }
    ) }
  )>, service?: Maybe<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'currency' | 'price' | 'holidayPrice' | 'pickUpDropOffPrice'>
  )>, countryServiceType: (
    { __typename?: 'CountryServiceType' }
    & Pick<CountryServiceType, 'id' | 'name'>
  ) }
);

export type GetDogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDogQuery = (
  { __typename?: 'Query' }
  & { dog: (
    { __typename?: 'Dog' }
    & DogProfilePartsFragment
  ) }
);

export type GetCurrentUserForEditDogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserForEditDogQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & DogProfilePartsFragment
    )>> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & DogProfilePartsFragment
    )>> }
  )> }
);

export type GetJobQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { job: (
    { __typename?: 'JobAuthor' }
    & Pick<JobAuthor, 'id' | 'status' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested' | 'playsDogs' | 'playsCats' | 'playsChildren' | 'description'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { estimatedPaymentTotal?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS'>
      )>, caregiver: (
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id' | 'lastSeenAt' | 'latitude' | 'longitude' | 'name' | 'caregiverReviewsScore' | 'caregiverReviewsCount'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle'>
        )> }
      ) }
    )>>, user: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'name'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'name'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'name'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'tAgeShort' | 'tSize' | 'tGender'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'slider'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'tName'>
      )> }
    )>, city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'latitude' | 'longitude' | 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'pricePer'>
    ), countryServiceType: (
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'name'>
    ) }
  ) | (
    { __typename?: 'JobPublic' }
    & Pick<JobPublic, 'id' | 'status' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested' | 'playsDogs' | 'playsCats' | 'playsChildren' | 'description'>
    & { user: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'name'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'name'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'name'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'tAgeShort' | 'tSize' | 'tGender'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'slider'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'tName'>
      )> }
    )>, city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'latitude' | 'longitude' | 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'pricePer'>
    ), countryServiceType: (
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'name'>
    ) }
  ) }
);

export type GetOwnerJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnerJobsQuery = (
  { __typename?: 'Query' }
  & { ownerJobs: Array<(
    { __typename?: 'JobAuthor' }
    & Pick<JobAuthor, 'id' | 'status' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested' | 'playsDogs' | 'playsCats' | 'playsChildren' | 'description'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { estimatedPaymentTotal?: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'toS'>
      )>, caregiver: (
        { __typename?: 'Caregiver' }
        & Pick<Caregiver, 'id' | 'lastSeenAt' | 'latitude' | 'longitude' | 'name' | 'caregiverReviewsScore' | 'caregiverReviewsCount'>
        & { avatar?: Maybe<(
          { __typename?: 'UserAvatar' }
          & Pick<UserAvatar, 'circle'>
        )> }
      ) }
    )>>, user: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'name'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'name'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'name'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'tAgeShort' | 'tSize' | 'tGender'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'slider'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'tName'>
      )> }
    )>, city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'latitude' | 'longitude' | 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'pricePer'>
    ), countryServiceType: (
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'name'>
    ) }
  ) | (
    { __typename?: 'JobPublic' }
    & Pick<JobPublic, 'id' | 'status' | 'startDate' | 'endDate' | 'serviceDates' | 'datesRequested' | 'playsDogs' | 'playsCats' | 'playsChildren' | 'description'>
    & { user: (
      { __typename?: 'Caregiver' }
      & Pick<Caregiver, 'name'>
    ) | (
      { __typename?: 'CurrentCaregiver' }
      & Pick<CurrentCaregiver, 'name'>
    ) | (
      { __typename?: 'CurrentOwner' }
      & Pick<CurrentOwner, 'name'>
    ) | (
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    ), dogs: Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'tAgeShort' | 'tSize' | 'tGender'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'slider'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'tName'>
      )> }
    )>, city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'latitude' | 'longitude' | 'name'>
    ), serviceType: (
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'pricePer'>
    ), countryServiceType: (
      { __typename?: 'CountryServiceType' }
      & Pick<CountryServiceType, 'name'>
    ) }
  )> }
);

export type DeleteJobMutationVariables = Exact<{
  input: DeleteJobInput;
}>;


export type DeleteJobMutation = (
  { __typename?: 'Mutation' }
  & { deleteJob?: Maybe<(
    { __typename?: 'DeleteJobPayload' }
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )> }
  )> }
);

export type GetCurrentUserForRegistrationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserForRegistrationQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'CurrentCaregiver' }
    & Pick<CurrentCaregiver, 'id'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'tGender' | 'tAge' | 'tAgeShort' | 'tSize'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )> }
    )>> }
  ) | (
    { __typename?: 'CurrentOwner' }
    & Pick<CurrentOwner, 'id'>
    & { dogs?: Maybe<Array<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'tGender' | 'tAge' | 'tAgeShort' | 'tSize'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )> }
    )>> }
  )> }
);

export type AddDogForRegistrationMutationVariables = Exact<{
  input: AddDogInput;
}>;


export type AddDogForRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { addDog?: Maybe<(
    { __typename?: 'AddDogPayload' }
    & { dog?: Maybe<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'name' | 'gender' | 'size' | 'slug' | 'birthday' | 'spayedNeutered' | 'chip' | 'playsDogs' | 'playsDogsDesc' | 'playsCats' | 'playsCatsDesc' | 'playsChildren' | 'playsChildrenDesc' | 'observations' | 'tGender' | 'tAge' | 'tAgeShort' | 'tSize' | 'activityLevel' | 'dailyFood' | 'walkingInstructions' | 'homeAlone' | 'healthInfo' | 'indoorPrecautions'>
      & { avatar?: Maybe<(
        { __typename?: 'DogAvatar' }
        & Pick<DogAvatar, 'circle' | 'big'>
      )>, breed?: Maybe<(
        { __typename?: 'Breed' }
        & Pick<Breed, 'id' | 'name' | 'tName'>
      )>, dailyFoodServings?: Maybe<(
        { __typename?: 'DayPeriod' }
        & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
      )>, dailyWalks?: Maybe<(
        { __typename?: 'DayPeriod' }
        & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
      )> }
    )>, errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'path'>
    )> }
  )> }
);

export type EditDogForRegistrationMutationVariables = Exact<{
  input: EditDogInput;
}>;


export type EditDogForRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { editDog?: Maybe<(
    { __typename?: 'EditDogPayload' }
    & { dog?: Maybe<(
      { __typename?: 'Dog' }
      & Pick<Dog, 'id' | 'activityLevel' | 'dailyFood' | 'walkingInstructions' | 'homeAlone' | 'healthInfo' | 'indoorPrecautions'>
      & { dailyFoodServings?: Maybe<(
        { __typename?: 'DayPeriod' }
        & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
      )>, dailyWalks?: Maybe<(
        { __typename?: 'DayPeriod' }
        & Pick<DayPeriod, 'morning' | 'afternoon' | 'night'>
      )> }
    )> }
  )> }
);

export const BookingPartsFragmentDoc = gql`
    fragment BookingParts on Booking {
  id
  fromDogley
  canBeTransferredToBankAccount
  availableEvents
  serviceDates
  startDate
  endDate
  serviceQuantity
  createdAt
  updatedAt
  serviceDates
  status
  dogIds
  canCaregiverBeReviewed
  caregiverId
  userId
  conversationId
  numberOfTrips
  total {
    amount
    currency
    toS
  }
  totalFee {
    amount
    currency
    toS
  }
  serviceTotal {
    amount
    currency
    toS
  }
  serviceType {
    id
    alwaysPickUpDropOff
    fullDayAvailability
    name
    pricePer
  }
  bookingDogs {
    id
    dogId
    bookingId
    price {
      toS
      amount
      currency
    }
    canDogBeReviewed
    dog {
      id
      name
      size
      tAge
      gender
      avatar {
        circle
      }
    }
  }
  pickUpDropOff
  pickUpDropOffPrice {
    toS
    amount
    currency
  }
  costAdjustment {
    toS
    amount
    currency
  }
  couponRedemption {
    id
    coupon {
      id
      code
      fixedDiscount {
        toS
        amount
        currency
      }
      percentageDiscount
      minimumAmount {
        toS
        amount
        currency
      }
      currency
      startsAt
      expiresAt
    }
  }
  service {
    id
    price
    holidayPrice
    pickUpDropOffPrice
    currency
  }
}
    `;
export const DogProfilePartsFragmentDoc = gql`
    fragment DogProfileParts on Dog {
  id
  name
  gender
  size
  slug
  birthday
  spayedNeutered
  chip
  playsDogs
  playsDogsDesc
  playsCats
  playsCatsDesc
  playsChildren
  playsChildrenDesc
  observations
  avatar {
    circle
    big
  }
  tGender
  tAge
  tAgeShort
  tSize
  breed {
    id
    name
    tName
  }
  photos {
    id
    miniThumb
    original
    slider
    thumb
    title
  }
  activityLevel
  dailyFood
  dailyFoodServings {
    morning
    afternoon
    night
  }
  dailyWalks {
    morning
    afternoon
    night
  }
  walkingInstructions
  homeAlone
  healthInfo
  indoorPrecautions
}
    `;
export const ConversationSummaryPartsFragmentDoc = gql`
    fragment ConversationSummaryParts on ConversationSummary {
  id
  partner {
    id
    name
    avatar {
      circle
    }
    dogs {
      name
    }
  }
  lastMessage {
    id
    createdAt
    content
    type
    booking {
      id
      user {
        id
      }
      caregiver {
        id
      }
    }
  }
  unreadMessages
  bookingHistory {
    requests
    upcoming
    past
  }
}
    `;
export const CurrentUserPartsFragmentDoc = gql`
    fragment currentUserParts on CurrentUser {
  id
  name
  surname
  email
  type
  avatar {
    circle
  }
  canCreateABillingProfile
  billingProfile {
    id
    identityStatus
  }
}
    `;
export const OwnerBookingFieldsFragmentDoc = gql`
    fragment OwnerBookingFields on Booking {
  id
  canBeTransferredToBankAccount
  availableEvents
  serviceDates
  startDate
  endDate
  serviceQuantity
  createdAt
  updatedAt
  serviceDates
  status
  dogIds
  canCaregiverBeReviewed
  conversationId
  numberOfTrips
  refundAmount {
    amount
    currency
    toS
  }
  transferAmount {
    amount
    currency
    toS
  }
  dogReviews {
    id
    content
    score
    reviewable {
      ... on Dog {
        id
        name
      }
    }
  }
  caregiverReview {
    id
    content
    score
    reviewable {
      ... on Caregiver {
        id
        name
      }
    }
  }
  bankWire {
    id
    createdAt
    total {
      amount
      currency
      toS
    }
  }
  total {
    amount
    currency
    toS
  }
  totalFee {
    amount
    currency
    toS
  }
  serviceTotal {
    amount
    currency
    toS
  }
  serviceType {
    id
    alwaysPickUpDropOff
    fullDayAvailability
    name
    pricePer
  }
  caregiver {
    id
    name
    latitude
    longitude
    cleanHeadline
    caregiverHeadline
    caregiverReviewsCount
    caregiverReviewsScore
    repeatCustomersCount
    publicAddress
    city {
      id
      name
      slug
    }
    avatar {
      circle
    }
    slug
  }
  user {
    id
    name
    city {
      id
      name
      slug
    }
    avatar {
      circle
    }
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      avatar {
        circle
        big
      }
      size
      tAge
      birthday
      slug
    }
  }
  bookingDogs {
    id
    dogId
    bookingId
    price {
      toS
      amount
      currency
    }
    canDogBeReviewed
    dog {
      id
      name
      avatar {
        circle
        big
      }
      size
      tAge
      birthday
      slug
    }
  }
  dogs {
    id
    name
    avatar {
      circle
      big
    }
    size
    tAge
    birthday
    slug
  }
  pickUpDropOffPrice {
    toS
    amount
    currency
  }
  costAdjustment {
    toS
    amount
    currency
  }
  couponRedemption {
    id
    coupon {
      id
      code
      fixedDiscount {
        toS
        amount
        currency
      }
      percentageDiscount
      minimumAmount {
        toS
        amount
        currency
      }
      currency
      startsAt
      expiresAt
    }
  }
  service {
    id
    currency
    price
    holidayPrice
    pickUpDropOffPrice
  }
  countryServiceType {
    id
    name
  }
}
    `;
export const OwnerRejectBookingDocument = gql`
    mutation ownerRejectBooking($id: ID!, $rejectionReasonType: OwnerRejectionReason!, $rejectionReason: String) {
  ownerRejectBooking(input: {id: $id, rejectionReasonType: $rejectionReasonType, rejectionReason: $rejectionReason}) {
    booking {
      id
      conversationId
    }
    errors {
      message
      path
    }
  }
}
    `;
export type OwnerRejectBookingMutationFn = Apollo.MutationFunction<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>;

/**
 * __useOwnerRejectBookingMutation__
 *
 * To run a mutation, you first call `useOwnerRejectBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerRejectBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerRejectBookingMutation, { data, loading, error }] = useOwnerRejectBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReasonType: // value for 'rejectionReasonType'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useOwnerRejectBookingMutation(baseOptions?: Apollo.MutationHookOptions<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>) {
        return Apollo.useMutation<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>(OwnerRejectBookingDocument, baseOptions);
      }
export type OwnerRejectBookingMutationHookResult = ReturnType<typeof useOwnerRejectBookingMutation>;
export type OwnerRejectBookingMutationResult = Apollo.MutationResult<OwnerRejectBookingMutation>;
export type OwnerRejectBookingMutationOptions = Apollo.BaseMutationOptions<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>;
export const CaregiverRejectBookingDocument = gql`
    mutation caregiverRejectBooking($id: ID!, $rejectionReasonType: CaregiverRejectionReason!, $rejectionReason: String, $shouldUpdateCalendar: Boolean) {
  caregiverRejectBooking(input: {id: $id, rejectionReasonType: $rejectionReasonType, rejectionReason: $rejectionReason, shouldUpdateCalendar: $shouldUpdateCalendar}) {
    booking {
      id
      conversationId
    }
    errors {
      message
      path
    }
  }
}
    `;
export type CaregiverRejectBookingMutationFn = Apollo.MutationFunction<CaregiverRejectBookingMutation, CaregiverRejectBookingMutationVariables>;

/**
 * __useCaregiverRejectBookingMutation__
 *
 * To run a mutation, you first call `useCaregiverRejectBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaregiverRejectBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caregiverRejectBookingMutation, { data, loading, error }] = useCaregiverRejectBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReasonType: // value for 'rejectionReasonType'
 *      rejectionReason: // value for 'rejectionReason'
 *      shouldUpdateCalendar: // value for 'shouldUpdateCalendar'
 *   },
 * });
 */
export function useCaregiverRejectBookingMutation(baseOptions?: Apollo.MutationHookOptions<CaregiverRejectBookingMutation, CaregiverRejectBookingMutationVariables>) {
        return Apollo.useMutation<CaregiverRejectBookingMutation, CaregiverRejectBookingMutationVariables>(CaregiverRejectBookingDocument, baseOptions);
      }
export type CaregiverRejectBookingMutationHookResult = ReturnType<typeof useCaregiverRejectBookingMutation>;
export type CaregiverRejectBookingMutationResult = Apollo.MutationResult<CaregiverRejectBookingMutation>;
export type CaregiverRejectBookingMutationOptions = Apollo.BaseMutationOptions<CaregiverRejectBookingMutation, CaregiverRejectBookingMutationVariables>;
export const GetDataForBookingInvoiceDocument = gql`
    query getDataForBookingInvoice($id: ID!) {
  booking(id: $id) {
    id
    status
    paymentConfirmedAt
    address
    total {
      toS
    }
    totalFee {
      toS
    }
    totalFeeVat {
      toS
    }
    baseFee {
      toS
    }
    country {
      iso3661Alpha2
      vat
    }
    user {
      id
    }
    caregiver {
      publicAddress
    }
  }
  currentUser {
    name
    surname
    address1
    address2
  }
}
    `;

/**
 * __useGetDataForBookingInvoiceQuery__
 *
 * To run a query within a React component, call `useGetDataForBookingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForBookingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForBookingInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForBookingInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetDataForBookingInvoiceQuery, GetDataForBookingInvoiceQueryVariables>) {
        return Apollo.useQuery<GetDataForBookingInvoiceQuery, GetDataForBookingInvoiceQueryVariables>(GetDataForBookingInvoiceDocument, baseOptions);
      }
export function useGetDataForBookingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataForBookingInvoiceQuery, GetDataForBookingInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetDataForBookingInvoiceQuery, GetDataForBookingInvoiceQueryVariables>(GetDataForBookingInvoiceDocument, baseOptions);
        }
export type GetDataForBookingInvoiceQueryHookResult = ReturnType<typeof useGetDataForBookingInvoiceQuery>;
export type GetDataForBookingInvoiceLazyQueryHookResult = ReturnType<typeof useGetDataForBookingInvoiceLazyQuery>;
export type GetDataForBookingInvoiceQueryResult = Apollo.QueryResult<GetDataForBookingInvoiceQuery, GetDataForBookingInvoiceQueryVariables>;
export const GetCurrentUserForPaymentDocument = gql`
    query getCurrentUserForPayment {
  currentUser {
    id
    name
    surname
    address1
    address2
    zip
    birthday
    canCreateABillingProfile
    billingCards {
      id
      alias
      provider
      expirationYear
      expirationMonth
    }
    city {
      id
      slug
      name
      placeId
      latitude
      longitude
    }
    country {
      id
      name
      locale
      currency
      fullDomain
      iso3661Alpha2
      paymentMethods
    }
  }
}
    `;

/**
 * __useGetCurrentUserForPaymentQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForPaymentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForPaymentQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForPaymentQuery, GetCurrentUserForPaymentQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserForPaymentQuery, GetCurrentUserForPaymentQueryVariables>(GetCurrentUserForPaymentDocument, baseOptions);
      }
export function useGetCurrentUserForPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForPaymentQuery, GetCurrentUserForPaymentQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserForPaymentQuery, GetCurrentUserForPaymentQueryVariables>(GetCurrentUserForPaymentDocument, baseOptions);
        }
export type GetCurrentUserForPaymentQueryHookResult = ReturnType<typeof useGetCurrentUserForPaymentQuery>;
export type GetCurrentUserForPaymentLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForPaymentLazyQuery>;
export type GetCurrentUserForPaymentQueryResult = Apollo.QueryResult<GetCurrentUserForPaymentQuery, GetCurrentUserForPaymentQueryVariables>;
export const GetBookingForPaymentDocument = gql`
    query getBookingForPayment($id: ID!) {
  booking(id: $id) {
    ...BookingParts
  }
}
    ${BookingPartsFragmentDoc}`;

/**
 * __useGetBookingForPaymentQuery__
 *
 * To run a query within a React component, call `useGetBookingForPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingForPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingForPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingForPaymentQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingForPaymentQuery, GetBookingForPaymentQueryVariables>) {
        return Apollo.useQuery<GetBookingForPaymentQuery, GetBookingForPaymentQueryVariables>(GetBookingForPaymentDocument, baseOptions);
      }
export function useGetBookingForPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingForPaymentQuery, GetBookingForPaymentQueryVariables>) {
          return Apollo.useLazyQuery<GetBookingForPaymentQuery, GetBookingForPaymentQueryVariables>(GetBookingForPaymentDocument, baseOptions);
        }
export type GetBookingForPaymentQueryHookResult = ReturnType<typeof useGetBookingForPaymentQuery>;
export type GetBookingForPaymentLazyQueryHookResult = ReturnType<typeof useGetBookingForPaymentLazyQuery>;
export type GetBookingForPaymentQueryResult = Apollo.QueryResult<GetBookingForPaymentQuery, GetBookingForPaymentQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($name: String!, $surname: String!, $birthday: ISO8601Date!, $address1: String!, $address2: String, $zip: String!, $city: CityInput!) {
  updateUser(input: {name: $name, surname: $surname, birthday: $birthday, address_1: $address1, address_2: $address2, zip: $zip, city: $city}) {
    user {
      id
      name
      surname
      address1
      address2
      zip
      city {
        id
        slug
        name
        placeId
        latitude
        longitude
        name
        country {
          id
          name
        }
      }
      birthday
      currency
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      birthday: // value for 'birthday'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      zip: // value for 'zip'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const InitBillingCardRegistrationDocument = gql`
    mutation initBillingCardRegistration($currency: Currency!, $cardType: CardType) {
  initBillingCardRegistration(input: {currency: $currency, cardType: $cardType}) {
    cardRegistrationData {
      accessKey
      cardRegistrationUrl
      id
      preregistrationData
    }
  }
}
    `;
export type InitBillingCardRegistrationMutationFn = Apollo.MutationFunction<InitBillingCardRegistrationMutation, InitBillingCardRegistrationMutationVariables>;

/**
 * __useInitBillingCardRegistrationMutation__
 *
 * To run a mutation, you first call `useInitBillingCardRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitBillingCardRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initBillingCardRegistrationMutation, { data, loading, error }] = useInitBillingCardRegistrationMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      cardType: // value for 'cardType'
 *   },
 * });
 */
export function useInitBillingCardRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<InitBillingCardRegistrationMutation, InitBillingCardRegistrationMutationVariables>) {
        return Apollo.useMutation<InitBillingCardRegistrationMutation, InitBillingCardRegistrationMutationVariables>(InitBillingCardRegistrationDocument, baseOptions);
      }
export type InitBillingCardRegistrationMutationHookResult = ReturnType<typeof useInitBillingCardRegistrationMutation>;
export type InitBillingCardRegistrationMutationResult = Apollo.MutationResult<InitBillingCardRegistrationMutation>;
export type InitBillingCardRegistrationMutationOptions = Apollo.BaseMutationOptions<InitBillingCardRegistrationMutation, InitBillingCardRegistrationMutationVariables>;
export const CreateBillingCardMutationDocument = gql`
    mutation createBillingCardMutation($token: String!) {
  createBillingCard(input: {token: $token}) {
    billingCard {
      id
      alias
      expirationMonth
      expirationYear
      provider
      currency
    }
  }
}
    `;
export type CreateBillingCardMutationMutationFn = Apollo.MutationFunction<CreateBillingCardMutationMutation, CreateBillingCardMutationMutationVariables>;

/**
 * __useCreateBillingCardMutationMutation__
 *
 * To run a mutation, you first call `useCreateBillingCardMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingCardMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingCardMutationMutation, { data, loading, error }] = useCreateBillingCardMutationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateBillingCardMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingCardMutationMutation, CreateBillingCardMutationMutationVariables>) {
        return Apollo.useMutation<CreateBillingCardMutationMutation, CreateBillingCardMutationMutationVariables>(CreateBillingCardMutationDocument, baseOptions);
      }
export type CreateBillingCardMutationMutationHookResult = ReturnType<typeof useCreateBillingCardMutationMutation>;
export type CreateBillingCardMutationMutationResult = Apollo.MutationResult<CreateBillingCardMutationMutation>;
export type CreateBillingCardMutationMutationOptions = Apollo.BaseMutationOptions<CreateBillingCardMutationMutation, CreateBillingCardMutationMutationVariables>;
export const DeleteBillingCardDocument = gql`
    mutation deleteBillingCard($billingCardId: ID!) {
  deleteBillingCard(input: {billingCardId: $billingCardId}) {
    billingCards {
      id
      alias
      provider
      expirationYear
      expirationMonth
    }
    errors {
      message
    }
  }
}
    `;
export type DeleteBillingCardMutationFn = Apollo.MutationFunction<DeleteBillingCardMutation, DeleteBillingCardMutationVariables>;

/**
 * __useDeleteBillingCardMutation__
 *
 * To run a mutation, you first call `useDeleteBillingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingCardMutation, { data, loading, error }] = useDeleteBillingCardMutation({
 *   variables: {
 *      billingCardId: // value for 'billingCardId'
 *   },
 * });
 */
export function useDeleteBillingCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingCardMutation, DeleteBillingCardMutationVariables>) {
        return Apollo.useMutation<DeleteBillingCardMutation, DeleteBillingCardMutationVariables>(DeleteBillingCardDocument, baseOptions);
      }
export type DeleteBillingCardMutationHookResult = ReturnType<typeof useDeleteBillingCardMutation>;
export type DeleteBillingCardMutationResult = Apollo.MutationResult<DeleteBillingCardMutation>;
export type DeleteBillingCardMutationOptions = Apollo.BaseMutationOptions<DeleteBillingCardMutation, DeleteBillingCardMutationVariables>;
export const OwnerConfirmBookingDocument = gql`
    mutation ownerConfirmBooking($id: ID!, $billingCardId: ID, $browserInfo: BrowserInfoInput, $directDebitMethod: DirectDebitMethod) {
  ownerConfirmBooking(input: {id: $id, browserInfo: $browserInfo, billingCardId: $billingCardId, directDebitMethod: $directDebitMethod}) {
    booking {
      id
      conversationId
    }
    payment {
      id
      status
      redirectUrl
    }
    errors {
      message
      path
    }
  }
}
    `;
export type OwnerConfirmBookingMutationFn = Apollo.MutationFunction<OwnerConfirmBookingMutation, OwnerConfirmBookingMutationVariables>;

/**
 * __useOwnerConfirmBookingMutation__
 *
 * To run a mutation, you first call `useOwnerConfirmBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerConfirmBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerConfirmBookingMutation, { data, loading, error }] = useOwnerConfirmBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      billingCardId: // value for 'billingCardId'
 *      browserInfo: // value for 'browserInfo'
 *      directDebitMethod: // value for 'directDebitMethod'
 *   },
 * });
 */
export function useOwnerConfirmBookingMutation(baseOptions?: Apollo.MutationHookOptions<OwnerConfirmBookingMutation, OwnerConfirmBookingMutationVariables>) {
        return Apollo.useMutation<OwnerConfirmBookingMutation, OwnerConfirmBookingMutationVariables>(OwnerConfirmBookingDocument, baseOptions);
      }
export type OwnerConfirmBookingMutationHookResult = ReturnType<typeof useOwnerConfirmBookingMutation>;
export type OwnerConfirmBookingMutationResult = Apollo.MutationResult<OwnerConfirmBookingMutation>;
export type OwnerConfirmBookingMutationOptions = Apollo.BaseMutationOptions<OwnerConfirmBookingMutation, OwnerConfirmBookingMutationVariables>;
export const OwnerPreapproveBookingDocument = gql`
    mutation ownerPreapproveBooking($id: ID!, $billingCardId: ID!, $browserInfo: BrowserInfoInput!) {
  ownerPreapproveBooking(input: {id: $id, billingCardId: $billingCardId, browserInfo: $browserInfo}) {
    booking {
      ...BookingParts
    }
    payment {
      id
      status
      redirectUrl
    }
    errors {
      message
      path
    }
  }
}
    ${BookingPartsFragmentDoc}`;
export type OwnerPreapproveBookingMutationFn = Apollo.MutationFunction<OwnerPreapproveBookingMutation, OwnerPreapproveBookingMutationVariables>;

/**
 * __useOwnerPreapproveBookingMutation__
 *
 * To run a mutation, you first call `useOwnerPreapproveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerPreapproveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerPreapproveBookingMutation, { data, loading, error }] = useOwnerPreapproveBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      billingCardId: // value for 'billingCardId'
 *      browserInfo: // value for 'browserInfo'
 *   },
 * });
 */
export function useOwnerPreapproveBookingMutation(baseOptions?: Apollo.MutationHookOptions<OwnerPreapproveBookingMutation, OwnerPreapproveBookingMutationVariables>) {
        return Apollo.useMutation<OwnerPreapproveBookingMutation, OwnerPreapproveBookingMutationVariables>(OwnerPreapproveBookingDocument, baseOptions);
      }
export type OwnerPreapproveBookingMutationHookResult = ReturnType<typeof useOwnerPreapproveBookingMutation>;
export type OwnerPreapproveBookingMutationResult = Apollo.MutationResult<OwnerPreapproveBookingMutation>;
export type OwnerPreapproveBookingMutationOptions = Apollo.BaseMutationOptions<OwnerPreapproveBookingMutation, OwnerPreapproveBookingMutationVariables>;
export const GetDataForBookingReceiptDocument = gql`
    query getDataForBookingReceipt($id: ID!) {
  booking(id: $id) {
    id
    status
    startDate
    endDate
    serviceDates
    serviceQuantity
    paymentConfirmedAt
    freeCancellationLimit
    unitPrice {
      toS
    }
    total {
      toS
    }
    totalFee {
      toS
    }
    service {
      price
      currency
    }
    countryServiceType {
      name
      serviceType {
        pricePer
      }
    }
    bookingDogs {
      dog {
        name
      }
    }
    user {
      id
      name
      publicFullName
    }
    caregiver {
      name
      publicAddress
      publicFullName
    }
    payment {
      paymentMethod {
        type
        alias
        provider
      }
    }
  }
  currentUser {
    id
  }
}
    `;

/**
 * __useGetDataForBookingReceiptQuery__
 *
 * To run a query within a React component, call `useGetDataForBookingReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForBookingReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForBookingReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForBookingReceiptQuery(baseOptions?: Apollo.QueryHookOptions<GetDataForBookingReceiptQuery, GetDataForBookingReceiptQueryVariables>) {
        return Apollo.useQuery<GetDataForBookingReceiptQuery, GetDataForBookingReceiptQueryVariables>(GetDataForBookingReceiptDocument, baseOptions);
      }
export function useGetDataForBookingReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataForBookingReceiptQuery, GetDataForBookingReceiptQueryVariables>) {
          return Apollo.useLazyQuery<GetDataForBookingReceiptQuery, GetDataForBookingReceiptQueryVariables>(GetDataForBookingReceiptDocument, baseOptions);
        }
export type GetDataForBookingReceiptQueryHookResult = ReturnType<typeof useGetDataForBookingReceiptQuery>;
export type GetDataForBookingReceiptLazyQueryHookResult = ReturnType<typeof useGetDataForBookingReceiptLazyQuery>;
export type GetDataForBookingReceiptQueryResult = Apollo.QueryResult<GetDataForBookingReceiptQuery, GetDataForBookingReceiptQueryVariables>;
export const GetCurrenUserWithMagicLinkDocument = gql`
    query getCurrenUserWithMagicLink {
  currentUser {
    ...currentUserParts
    appMagicLink
  }
}
    ${CurrentUserPartsFragmentDoc}`;

/**
 * __useGetCurrenUserWithMagicLinkQuery__
 *
 * To run a query within a React component, call `useGetCurrenUserWithMagicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenUserWithMagicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenUserWithMagicLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenUserWithMagicLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenUserWithMagicLinkQuery, GetCurrenUserWithMagicLinkQueryVariables>) {
        return Apollo.useQuery<GetCurrenUserWithMagicLinkQuery, GetCurrenUserWithMagicLinkQueryVariables>(GetCurrenUserWithMagicLinkDocument, baseOptions);
      }
export function useGetCurrenUserWithMagicLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenUserWithMagicLinkQuery, GetCurrenUserWithMagicLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrenUserWithMagicLinkQuery, GetCurrenUserWithMagicLinkQueryVariables>(GetCurrenUserWithMagicLinkDocument, baseOptions);
        }
export type GetCurrenUserWithMagicLinkQueryHookResult = ReturnType<typeof useGetCurrenUserWithMagicLinkQuery>;
export type GetCurrenUserWithMagicLinkLazyQueryHookResult = ReturnType<typeof useGetCurrenUserWithMagicLinkLazyQuery>;
export type GetCurrenUserWithMagicLinkQueryResult = Apollo.QueryResult<GetCurrenUserWithMagicLinkQuery, GetCurrenUserWithMagicLinkQueryVariables>;
export const SmsMagicLinkDocument = gql`
    mutation smsMagicLink($input: SendSMSWithAppMagicLinkInput!) {
  sendSmsWithAppMagicLink(input: $input) {
    errors {
      message
      path
    }
  }
}
    `;
export type SmsMagicLinkMutationFn = Apollo.MutationFunction<SmsMagicLinkMutation, SmsMagicLinkMutationVariables>;

/**
 * __useSmsMagicLinkMutation__
 *
 * To run a mutation, you first call `useSmsMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmsMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smsMagicLinkMutation, { data, loading, error }] = useSmsMagicLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSmsMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SmsMagicLinkMutation, SmsMagicLinkMutationVariables>) {
        return Apollo.useMutation<SmsMagicLinkMutation, SmsMagicLinkMutationVariables>(SmsMagicLinkDocument, baseOptions);
      }
export type SmsMagicLinkMutationHookResult = ReturnType<typeof useSmsMagicLinkMutation>;
export type SmsMagicLinkMutationResult = Apollo.MutationResult<SmsMagicLinkMutation>;
export type SmsMagicLinkMutationOptions = Apollo.BaseMutationOptions<SmsMagicLinkMutation, SmsMagicLinkMutationVariables>;
export const GetCountryServiceTypesDocument = gql`
    query getCountryServiceTypes {
  country {
    id
    iso3661Alpha2
    countryServiceTypes {
      id
      name
      serviceType {
        id
        alwaysPickUpDropOff
        fullDayAvailability
        pricePer
      }
    }
  }
}
    `;

/**
 * __useGetCountryServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetCountryServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryServiceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryServiceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryServiceTypesQuery, GetCountryServiceTypesQueryVariables>) {
        return Apollo.useQuery<GetCountryServiceTypesQuery, GetCountryServiceTypesQueryVariables>(GetCountryServiceTypesDocument, baseOptions);
      }
export function useGetCountryServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryServiceTypesQuery, GetCountryServiceTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetCountryServiceTypesQuery, GetCountryServiceTypesQueryVariables>(GetCountryServiceTypesDocument, baseOptions);
        }
export type GetCountryServiceTypesQueryHookResult = ReturnType<typeof useGetCountryServiceTypesQuery>;
export type GetCountryServiceTypesLazyQueryHookResult = ReturnType<typeof useGetCountryServiceTypesLazyQuery>;
export type GetCountryServiceTypesQueryResult = Apollo.QueryResult<GetCountryServiceTypesQuery, GetCountryServiceTypesQueryVariables>;
export const AddDogDocument = gql`
    mutation addDog($input: AddDogInput!) {
  addDog(input: $input) {
    dog {
      ...DogProfileParts
    }
    errors {
      message
      path
    }
  }
}
    ${DogProfilePartsFragmentDoc}`;
export type AddDogMutationFn = Apollo.MutationFunction<AddDogMutation, AddDogMutationVariables>;

/**
 * __useAddDogMutation__
 *
 * To run a mutation, you first call `useAddDogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDogMutation, { data, loading, error }] = useAddDogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDogMutation(baseOptions?: Apollo.MutationHookOptions<AddDogMutation, AddDogMutationVariables>) {
        return Apollo.useMutation<AddDogMutation, AddDogMutationVariables>(AddDogDocument, baseOptions);
      }
export type AddDogMutationHookResult = ReturnType<typeof useAddDogMutation>;
export type AddDogMutationResult = Apollo.MutationResult<AddDogMutation>;
export type AddDogMutationOptions = Apollo.BaseMutationOptions<AddDogMutation, AddDogMutationVariables>;
export const EditDogDocument = gql`
    mutation editDog($input: EditDogInput!) {
  editDog(input: $input) {
    dog {
      ...DogProfileParts
    }
    errors {
      message
      path
    }
  }
}
    ${DogProfilePartsFragmentDoc}`;
export type EditDogMutationFn = Apollo.MutationFunction<EditDogMutation, EditDogMutationVariables>;

/**
 * __useEditDogMutation__
 *
 * To run a mutation, you first call `useEditDogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDogMutation, { data, loading, error }] = useEditDogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDogMutation(baseOptions?: Apollo.MutationHookOptions<EditDogMutation, EditDogMutationVariables>) {
        return Apollo.useMutation<EditDogMutation, EditDogMutationVariables>(EditDogDocument, baseOptions);
      }
export type EditDogMutationHookResult = ReturnType<typeof useEditDogMutation>;
export type EditDogMutationResult = Apollo.MutationResult<EditDogMutation>;
export type EditDogMutationOptions = Apollo.BaseMutationOptions<EditDogMutation, EditDogMutationVariables>;
export const DeleteDogDocument = gql`
    mutation deleteDog($id: ID!) {
  deleteDog(input: {id: $id}) {
    errors {
      message
      path
    }
  }
}
    `;
export type DeleteDogMutationFn = Apollo.MutationFunction<DeleteDogMutation, DeleteDogMutationVariables>;

/**
 * __useDeleteDogMutation__
 *
 * To run a mutation, you first call `useDeleteDogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDogMutation, { data, loading, error }] = useDeleteDogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDogMutation, DeleteDogMutationVariables>) {
        return Apollo.useMutation<DeleteDogMutation, DeleteDogMutationVariables>(DeleteDogDocument, baseOptions);
      }
export type DeleteDogMutationHookResult = ReturnType<typeof useDeleteDogMutation>;
export type DeleteDogMutationResult = Apollo.MutationResult<DeleteDogMutation>;
export type DeleteDogMutationOptions = Apollo.BaseMutationOptions<DeleteDogMutation, DeleteDogMutationVariables>;
export const GetInboxDocument = gql`
    query getInbox($cursor: String, $first: Int, $type: InboxType) {
  inbox(first: $first, after: $cursor, type: $type) {
    pageInfo {
      endCursor
      hasNextPage
    }
    nodes {
      ...ConversationSummaryParts
    }
  }
  potentialJobs {
    bookingId
    startDate
    endDate
    serviceDates
    serviceTypeId
    cityName
    dogs {
      name
    }
  }
}
    ${ConversationSummaryPartsFragmentDoc}`;

/**
 * __useGetInboxQuery__
 *
 * To run a query within a React component, call `useGetInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetInboxQuery(baseOptions?: Apollo.QueryHookOptions<GetInboxQuery, GetInboxQueryVariables>) {
        return Apollo.useQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, baseOptions);
      }
export function useGetInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInboxQuery, GetInboxQueryVariables>) {
          return Apollo.useLazyQuery<GetInboxQuery, GetInboxQueryVariables>(GetInboxDocument, baseOptions);
        }
export type GetInboxQueryHookResult = ReturnType<typeof useGetInboxQuery>;
export type GetInboxLazyQueryHookResult = ReturnType<typeof useGetInboxLazyQuery>;
export type GetInboxQueryResult = Apollo.QueryResult<GetInboxQuery, GetInboxQueryVariables>;
export const InboxUpdatedDocument = gql`
    subscription inboxUpdated {
  inboxUpdated {
    conversation {
      ...ConversationSummaryParts
    }
  }
}
    ${ConversationSummaryPartsFragmentDoc}`;

/**
 * __useInboxUpdatedSubscription__
 *
 * To run a query within a React component, call `useInboxUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInboxUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInboxUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<InboxUpdatedSubscription, InboxUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<InboxUpdatedSubscription, InboxUpdatedSubscriptionVariables>(InboxUpdatedDocument, baseOptions);
      }
export type InboxUpdatedSubscriptionHookResult = ReturnType<typeof useInboxUpdatedSubscription>;
export type InboxUpdatedSubscriptionResult = Apollo.SubscriptionResult<InboxUpdatedSubscription>;
export const GetOwnerJobsForConversationsDocument = gql`
    query getOwnerJobsForConversations {
  ownerJobs {
    id
    startDate
    endDate
    serviceDates
    datesRequested
    city {
      name
    }
    serviceType {
      id
      name
    }
    dogs {
      id
      name
    }
    ... on JobAuthor {
      applications {
        id
        estimatedPaymentTotal {
          toS
        }
        caregiver {
          id
          name
          avatar {
            circle
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOwnerJobsForConversationsQuery__
 *
 * To run a query within a React component, call `useGetOwnerJobsForConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerJobsForConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerJobsForConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnerJobsForConversationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnerJobsForConversationsQuery, GetOwnerJobsForConversationsQueryVariables>) {
        return Apollo.useQuery<GetOwnerJobsForConversationsQuery, GetOwnerJobsForConversationsQueryVariables>(GetOwnerJobsForConversationsDocument, baseOptions);
      }
export function useGetOwnerJobsForConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerJobsForConversationsQuery, GetOwnerJobsForConversationsQueryVariables>) {
          return Apollo.useLazyQuery<GetOwnerJobsForConversationsQuery, GetOwnerJobsForConversationsQueryVariables>(GetOwnerJobsForConversationsDocument, baseOptions);
        }
export type GetOwnerJobsForConversationsQueryHookResult = ReturnType<typeof useGetOwnerJobsForConversationsQuery>;
export type GetOwnerJobsForConversationsLazyQueryHookResult = ReturnType<typeof useGetOwnerJobsForConversationsLazyQuery>;
export type GetOwnerJobsForConversationsQueryResult = Apollo.QueryResult<GetOwnerJobsForConversationsQuery, GetOwnerJobsForConversationsQueryVariables>;
export const OwnerDeclineJobApplicationDocument = gql`
    mutation ownerDeclineJobApplication($input: OwnerDeclineJobApplicationInput!) {
  ownerDeclineJobApplication(input: $input) {
    errors {
      message
    }
  }
}
    `;
export type OwnerDeclineJobApplicationMutationFn = Apollo.MutationFunction<OwnerDeclineJobApplicationMutation, OwnerDeclineJobApplicationMutationVariables>;

/**
 * __useOwnerDeclineJobApplicationMutation__
 *
 * To run a mutation, you first call `useOwnerDeclineJobApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerDeclineJobApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerDeclineJobApplicationMutation, { data, loading, error }] = useOwnerDeclineJobApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnerDeclineJobApplicationMutation(baseOptions?: Apollo.MutationHookOptions<OwnerDeclineJobApplicationMutation, OwnerDeclineJobApplicationMutationVariables>) {
        return Apollo.useMutation<OwnerDeclineJobApplicationMutation, OwnerDeclineJobApplicationMutationVariables>(OwnerDeclineJobApplicationDocument, baseOptions);
      }
export type OwnerDeclineJobApplicationMutationHookResult = ReturnType<typeof useOwnerDeclineJobApplicationMutation>;
export type OwnerDeclineJobApplicationMutationResult = Apollo.MutationResult<OwnerDeclineJobApplicationMutation>;
export type OwnerDeclineJobApplicationMutationOptions = Apollo.BaseMutationOptions<OwnerDeclineJobApplicationMutation, OwnerDeclineJobApplicationMutationVariables>;
export const OwnerAcceptJobApplicationDocument = gql`
    mutation ownerAcceptJobApplication($input: OwnerAcceptJobApplicationInput!) {
  ownerAcceptJobApplication(input: $input) {
    conversation {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type OwnerAcceptJobApplicationMutationFn = Apollo.MutationFunction<OwnerAcceptJobApplicationMutation, OwnerAcceptJobApplicationMutationVariables>;

/**
 * __useOwnerAcceptJobApplicationMutation__
 *
 * To run a mutation, you first call `useOwnerAcceptJobApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOwnerAcceptJobApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ownerAcceptJobApplicationMutation, { data, loading, error }] = useOwnerAcceptJobApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnerAcceptJobApplicationMutation(baseOptions?: Apollo.MutationHookOptions<OwnerAcceptJobApplicationMutation, OwnerAcceptJobApplicationMutationVariables>) {
        return Apollo.useMutation<OwnerAcceptJobApplicationMutation, OwnerAcceptJobApplicationMutationVariables>(OwnerAcceptJobApplicationDocument, baseOptions);
      }
export type OwnerAcceptJobApplicationMutationHookResult = ReturnType<typeof useOwnerAcceptJobApplicationMutation>;
export type OwnerAcceptJobApplicationMutationResult = Apollo.MutationResult<OwnerAcceptJobApplicationMutation>;
export type OwnerAcceptJobApplicationMutationOptions = Apollo.BaseMutationOptions<OwnerAcceptJobApplicationMutation, OwnerAcceptJobApplicationMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    name
    surname
    email
    type
    avatar {
      circle
    }
    intercomUserHash
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetCurrentUserIdDocument = gql`
    query getCurrentUserId {
  currentUser {
    id
  }
}
    `;

/**
 * __useGetCurrentUserIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserIdQuery, GetCurrentUserIdQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserIdQuery, GetCurrentUserIdQueryVariables>(GetCurrentUserIdDocument, baseOptions);
      }
export function useGetCurrentUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserIdQuery, GetCurrentUserIdQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserIdQuery, GetCurrentUserIdQueryVariables>(GetCurrentUserIdDocument, baseOptions);
        }
export type GetCurrentUserIdQueryHookResult = ReturnType<typeof useGetCurrentUserIdQuery>;
export type GetCurrentUserIdLazyQueryHookResult = ReturnType<typeof useGetCurrentUserIdLazyQuery>;
export type GetCurrentUserIdQueryResult = Apollo.QueryResult<GetCurrentUserIdQuery, GetCurrentUserIdQueryVariables>;
export const GetDogProfileDocument = gql`
    query getDogProfile($id: ID!) {
  dog(id: $id) {
    id
    name
    gender
    size
    slug
    birthday
    spayedNeutered
    chip
    playsDogs
    playsDogsDesc
    playsCats
    playsCatsDesc
    playsChildren
    playsChildrenDesc
    observations
    avatar {
      circle
      big
    }
    tGender
    tAge
    tAgeShort
    tSize
    breed {
      id
      name
      tName
    }
    photos {
      id
      miniThumb
      original
      slider
      thumb
      title
    }
    activityLevel
    dailyFood
    dailyFoodServings {
      morning
      afternoon
      night
    }
    dailyWalks {
      morning
      afternoon
      night
    }
    walkingInstructions
    homeAlone
    healthInfo
    indoorPrecautions
    user {
      id
      name
      city {
        id
        name
        slug
        nearbyCities {
          name
          slug
          placeId
          activeCaregiversCount
        }
      }
      avatar {
        circle
      }
    }
    stats {
      serviceType {
        alwaysPickUpDropOff
        fullDayAvailability
        id
        name
        pricePer
      }
      bookingsCount
    }
    sameBreedDogs {
      id
      name
      tAgeShort
      gender
      slug
      avatar {
        circle
      }
    }
    reviews {
      id
      content
      createdAt
      score
      author {
        id
        name
        avatar {
          circle
          thumb
        }
        ... on Caregiver {
          slug
        }
      }
    }
  }
}
    `;

/**
 * __useGetDogProfileQuery__
 *
 * To run a query within a React component, call `useGetDogProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDogProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDogProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDogProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetDogProfileQuery, GetDogProfileQueryVariables>) {
        return Apollo.useQuery<GetDogProfileQuery, GetDogProfileQueryVariables>(GetDogProfileDocument, baseOptions);
      }
export function useGetDogProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDogProfileQuery, GetDogProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetDogProfileQuery, GetDogProfileQueryVariables>(GetDogProfileDocument, baseOptions);
        }
export type GetDogProfileQueryHookResult = ReturnType<typeof useGetDogProfileQuery>;
export type GetDogProfileLazyQueryHookResult = ReturnType<typeof useGetDogProfileLazyQuery>;
export type GetDogProfileQueryResult = Apollo.QueryResult<GetDogProfileQuery, GetDogProfileQueryVariables>;
export const GetCurrentUserForLayoutDocument = gql`
    query getCurrentUserForLayout {
  currentUser {
    id
    name
    surname
    email
    type
    avatar {
      circle
    }
    canCreateABillingProfile
    billingProfile {
      id
      identityStatus
    }
    intercomUserHash
    notifications {
      unreadMessagesCountUser
      unreadMessagesCountCaregiver
    }
  }
}
    `;

/**
 * __useGetCurrentUserForLayoutQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForLayoutQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForLayoutQuery, GetCurrentUserForLayoutQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserForLayoutQuery, GetCurrentUserForLayoutQueryVariables>(GetCurrentUserForLayoutDocument, baseOptions);
      }
export function useGetCurrentUserForLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForLayoutQuery, GetCurrentUserForLayoutQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserForLayoutQuery, GetCurrentUserForLayoutQueryVariables>(GetCurrentUserForLayoutDocument, baseOptions);
        }
export type GetCurrentUserForLayoutQueryHookResult = ReturnType<typeof useGetCurrentUserForLayoutQuery>;
export type GetCurrentUserForLayoutLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForLayoutLazyQuery>;
export type GetCurrentUserForLayoutQueryResult = Apollo.QueryResult<GetCurrentUserForLayoutQuery, GetCurrentUserForLayoutQueryVariables>;
export const GetCurrentUserNotificationsDocument = gql`
    query getCurrentUserNotifications {
  currentUser {
    notifications {
      unreadMessagesCountUser
      unreadMessagesCountCaregiver
    }
  }
}
    `;

/**
 * __useGetCurrentUserNotificationsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>(GetCurrentUserNotificationsDocument, baseOptions);
      }
export function useGetCurrentUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>(GetCurrentUserNotificationsDocument, baseOptions);
        }
export type GetCurrentUserNotificationsQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsQuery>;
export type GetCurrentUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsLazyQuery>;
export type GetCurrentUserNotificationsQueryResult = Apollo.QueryResult<GetCurrentUserNotificationsQuery, GetCurrentUserNotificationsQueryVariables>;
export const GetPaymentDocument = gql`
    query getPayment($id: ID!) {
  payment(id: $id) {
    id
    status
    booking {
      id
      conversationId
    }
  }
}
    `;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
        return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, baseOptions);
      }
export function useGetPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, baseOptions);
        }
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const UpdateCaregiverDeactivateDocument = gql`
    mutation updateCaregiverDeactivate($input: UpdateCaregiverDeactivateInput!) {
  updateCaregiverDeactivate(input: $input) {
    caregiver {
      id
      caregiverStatus
      caregiverDeactivatedAt
    }
    errors {
      message
      path
    }
  }
}
    `;
export type UpdateCaregiverDeactivateMutationFn = Apollo.MutationFunction<UpdateCaregiverDeactivateMutation, UpdateCaregiverDeactivateMutationVariables>;

/**
 * __useUpdateCaregiverDeactivateMutation__
 *
 * To run a mutation, you first call `useUpdateCaregiverDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaregiverDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaregiverDeactivateMutation, { data, loading, error }] = useUpdateCaregiverDeactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCaregiverDeactivateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaregiverDeactivateMutation, UpdateCaregiverDeactivateMutationVariables>) {
        return Apollo.useMutation<UpdateCaregiverDeactivateMutation, UpdateCaregiverDeactivateMutationVariables>(UpdateCaregiverDeactivateDocument, baseOptions);
      }
export type UpdateCaregiverDeactivateMutationHookResult = ReturnType<typeof useUpdateCaregiverDeactivateMutation>;
export type UpdateCaregiverDeactivateMutationResult = Apollo.MutationResult<UpdateCaregiverDeactivateMutation>;
export type UpdateCaregiverDeactivateMutationOptions = Apollo.BaseMutationOptions<UpdateCaregiverDeactivateMutation, UpdateCaregiverDeactivateMutationVariables>;
export const GetCountryAndCurrentUserDocument = gql`
    query getCountryAndCurrentUser {
  country {
    id
    iso3661Alpha2
    countryServiceTypes {
      id
      name
      serviceType {
        id
        alwaysPickUpDropOff
        fullDayAvailability
        pricePer
      }
    }
  }
  currentUser {
    ...currentUserParts
    dogs {
      id
      name
      breed {
        id
        name
      }
      avatar {
        circle
      }
      size
      birthday
      tAge
      slug
    }
  }
}
    ${CurrentUserPartsFragmentDoc}`;

/**
 * __useGetCountryAndCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCountryAndCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryAndCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryAndCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryAndCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCountryAndCurrentUserQuery, GetCountryAndCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCountryAndCurrentUserQuery, GetCountryAndCurrentUserQueryVariables>(GetCountryAndCurrentUserDocument, baseOptions);
      }
export function useGetCountryAndCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryAndCurrentUserQuery, GetCountryAndCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCountryAndCurrentUserQuery, GetCountryAndCurrentUserQueryVariables>(GetCountryAndCurrentUserDocument, baseOptions);
        }
export type GetCountryAndCurrentUserQueryHookResult = ReturnType<typeof useGetCountryAndCurrentUserQuery>;
export type GetCountryAndCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCountryAndCurrentUserLazyQuery>;
export type GetCountryAndCurrentUserQueryResult = Apollo.QueryResult<GetCountryAndCurrentUserQuery, GetCountryAndCurrentUserQueryVariables>;
export const SearchDocument = gql`
    query search($page: Int, $pid: String, $neLat: String, $neLng: String, $swLat: String, $swLng: String, $lat: String, $lng: String, $serviceTypeId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date, $serviceDates: DateArray, $includePrice: Boolean = false, $dogIds: [ID!], $dogs: [DogFilter!], $withDogs: Boolean, $withOutsideArea: Boolean, $withoutKids: Boolean) {
  search(page: $page, pid: $pid, neLat: $neLat, neLng: $neLng, swLat: $swLat, swLng: $swLng, lat: $lat, lng: $lng, serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs, withDogs: $withDogs, withOutsideArea: $withOutsideArea, withoutKids: $withoutKids) {
    totalPages
    totalEntries
    currentPage
    serviceType {
      id
      name
      pricePer
      alwaysPickUpDropOff
      fullDayAvailability
      routeServiceParam
    }
    city {
      id
      placeId
      latitude
      longitude
      name
      slug
      nearbyCities {
        id
        placeId
        latitude
        longitude
        name
        slug
        activeCaregiversCount
      }
    }
    topCities {
      id
      placeId
      latitude
      longitude
      name
      slug
      activeCaregiversCount
    }
    caregivers {
      id
      caregiverHeadline
      name
      caregiverReviewsCount
      caregiverReviewsScore
      currency
      repeatCustomersCount
      latitude
      longitude
      publicAddress
      availableHolidays
      calendarUpToDate
      featuredReview {
        id
        author {
          id
          name
          avatar {
            thumb
          }
        }
        content
      }
      avatar {
        circle
      }
      city {
        id
        slug
        name
      }
      slug
      price(serviceTypeId: $serviceTypeId) @include(if: $includePrice)
      bookingEstimation(serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs) {
        currency
        amount
        toS
      }
      availablePromotions {
        id
        tLabel
      }
      caregiverCalendarUpdatedAt
      reviews(page: 1, perPage: 1) {
        results {
          author {
            avatar {
              thumb
            }
          }
          content
        }
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pid: // value for 'pid'
 *      neLat: // value for 'neLat'
 *      neLng: // value for 'neLng'
 *      swLat: // value for 'swLat'
 *      swLng: // value for 'swLng'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      serviceDates: // value for 'serviceDates'
 *      includePrice: // value for 'includePrice'
 *      dogIds: // value for 'dogIds'
 *      dogs: // value for 'dogs'
 *      withDogs: // value for 'withDogs'
 *      withOutsideArea: // value for 'withOutsideArea'
 *      withoutKids: // value for 'withoutKids'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const SearchCityDocument = gql`
    query searchCity($city: String!, $page: Int, $pid: String, $neLat: String, $neLng: String, $swLat: String, $swLng: String, $lat: String, $lng: String, $serviceTypeId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date, $serviceDates: DateArray, $includePrice: Boolean = false, $dogIds: [ID!], $dogs: [DogFilter!], $withDogs: Boolean, $withOutsideArea: Boolean, $withoutKids: Boolean) {
  searchCity(city: $city, page: $page, pid: $pid, neLat: $neLat, neLng: $neLng, swLat: $swLat, swLng: $swLng, lat: $lat, lng: $lng, serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs, withDogs: $withDogs, withOutsideArea: $withOutsideArea, withoutKids: $withoutKids) {
    metaTitle
    metaDescription
    title
    contentHtml
    totalEntries
    totalPages
    currentPage
    serviceType {
      id
      name
      pricePer
      alwaysPickUpDropOff
      fullDayAvailability
      routeServiceParam
    }
    city {
      avgCaregiverReviewsScore
      id
      placeId
      latitude
      longitude
      name
      slug
      seoLandingNoindexTag
      sumCaregiverReviewsCount
      nearbyCities {
        id
        name
        slug
        placeId
        latitude
        longitude
        activeCaregiversCount
      }
      country {
        locale
      }
    }
    caregivers {
      id
      caregiverHeadline
      name
      caregiverReviewsCount
      caregiverReviewsScore
      repeatCustomersCount
      latitude
      longitude
      publicAddress
      availableHolidays
      calendarUpToDate
      featuredReview {
        id
        author {
          id
          name
          avatar {
            thumb
          }
        }
        content
      }
      avatar {
        circle
      }
      city {
        id
        slug
        placeId
        latitude
        longitude
        name
      }
      slug
      price(serviceTypeId: $serviceTypeId) @include(if: $includePrice)
      bookingEstimation(serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs) {
        currency
        amount
        toS
      }
      availablePromotions {
        id
        tLabel
      }
    }
  }
  country {
    id
    iso3661Alpha2
    countryServiceTypes {
      id
      name
      serviceType {
        id
        alwaysPickUpDropOff
        fullDayAvailability
        pricePer
      }
    }
  }
  currentUser {
    ...currentUserParts
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      avatar {
        circle
      }
      size
      birthday
      tAge
      slug
    }
  }
}
    ${CurrentUserPartsFragmentDoc}`;

/**
 * __useSearchCityQuery__
 *
 * To run a query within a React component, call `useSearchCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *      page: // value for 'page'
 *      pid: // value for 'pid'
 *      neLat: // value for 'neLat'
 *      neLng: // value for 'neLng'
 *      swLat: // value for 'swLat'
 *      swLng: // value for 'swLng'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      serviceDates: // value for 'serviceDates'
 *      includePrice: // value for 'includePrice'
 *      dogIds: // value for 'dogIds'
 *      dogs: // value for 'dogs'
 *      withDogs: // value for 'withDogs'
 *      withOutsideArea: // value for 'withOutsideArea'
 *      withoutKids: // value for 'withoutKids'
 *   },
 * });
 */
export function useSearchCityQuery(baseOptions?: Apollo.QueryHookOptions<SearchCityQuery, SearchCityQueryVariables>) {
        return Apollo.useQuery<SearchCityQuery, SearchCityQueryVariables>(SearchCityDocument, baseOptions);
      }
export function useSearchCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCityQuery, SearchCityQueryVariables>) {
          return Apollo.useLazyQuery<SearchCityQuery, SearchCityQueryVariables>(SearchCityDocument, baseOptions);
        }
export type SearchCityQueryHookResult = ReturnType<typeof useSearchCityQuery>;
export type SearchCityLazyQueryHookResult = ReturnType<typeof useSearchCityLazyQuery>;
export type SearchCityQueryResult = Apollo.QueryResult<SearchCityQuery, SearchCityQueryVariables>;
export const SearchCityServiceTypeDocument = gql`
    query searchCityServiceType($service: String!, $city: String!, $page: Int, $pid: String, $neLat: String, $neLng: String, $swLat: String, $swLng: String, $lat: String, $lng: String, $serviceTypeId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date, $serviceDates: DateArray, $includePrice: Boolean = false, $dogIds: [ID!], $dogs: [DogFilter!], $withDogs: Boolean, $withOutsideArea: Boolean, $withoutKids: Boolean) {
  searchCityServiceType(service: $service, city: $city, page: $page, pid: $pid, neLat: $neLat, neLng: $neLng, swLat: $swLat, swLng: $swLng, lat: $lat, lng: $lng, serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs, withDogs: $withDogs, withOutsideArea: $withOutsideArea, withoutKids: $withoutKids) {
    metaTitle
    metaDescription
    title
    contentHtml
    totalEntries
    totalPages
    currentPage
    serviceType {
      id
      name
      pricePer
      alwaysPickUpDropOff
      fullDayAvailability
      routeServiceParam
    }
    city {
      avgCaregiverReviewsScore
      id
      placeId
      latitude
      longitude
      name
      slug
      seoLandingNoindexTag
      sumCaregiverReviewsCount
      nearbyCities {
        id
        name
        slug
        placeId
        latitude
        longitude
        activeCaregiversCount
      }
      country {
        locale
      }
    }
    caregivers {
      id
      caregiverHeadline
      name
      caregiverReviewsCount
      caregiverReviewsScore
      repeatCustomersCount
      latitude
      longitude
      publicAddress
      availableHolidays
      calendarUpToDate
      featuredReview {
        id
        author {
          id
          name
          avatar {
            thumb
          }
        }
        content
      }
      avatar {
        circle
      }
      city {
        id
        slug
        placeId
        latitude
        longitude
        name
      }
      slug
      price(serviceTypeId: $serviceTypeId) @include(if: $includePrice)
      bookingEstimation(serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs) {
        currency
        amount
        toS
      }
      availablePromotions {
        id
        tLabel
      }
    }
  }
  country {
    id
    iso3661Alpha2
    countryServiceTypes {
      id
      name
      serviceType {
        id
        alwaysPickUpDropOff
        fullDayAvailability
        pricePer
      }
    }
  }
  currentUser {
    ...currentUserParts
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      avatar {
        circle
      }
      size
      birthday
      tAge
      slug
    }
  }
}
    ${CurrentUserPartsFragmentDoc}`;

/**
 * __useSearchCityServiceTypeQuery__
 *
 * To run a query within a React component, call `useSearchCityServiceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCityServiceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCityServiceTypeQuery({
 *   variables: {
 *      service: // value for 'service'
 *      city: // value for 'city'
 *      page: // value for 'page'
 *      pid: // value for 'pid'
 *      neLat: // value for 'neLat'
 *      neLng: // value for 'neLng'
 *      swLat: // value for 'swLat'
 *      swLng: // value for 'swLng'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      serviceDates: // value for 'serviceDates'
 *      includePrice: // value for 'includePrice'
 *      dogIds: // value for 'dogIds'
 *      dogs: // value for 'dogs'
 *      withDogs: // value for 'withDogs'
 *      withOutsideArea: // value for 'withOutsideArea'
 *      withoutKids: // value for 'withoutKids'
 *   },
 * });
 */
export function useSearchCityServiceTypeQuery(baseOptions?: Apollo.QueryHookOptions<SearchCityServiceTypeQuery, SearchCityServiceTypeQueryVariables>) {
        return Apollo.useQuery<SearchCityServiceTypeQuery, SearchCityServiceTypeQueryVariables>(SearchCityServiceTypeDocument, baseOptions);
      }
export function useSearchCityServiceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCityServiceTypeQuery, SearchCityServiceTypeQueryVariables>) {
          return Apollo.useLazyQuery<SearchCityServiceTypeQuery, SearchCityServiceTypeQueryVariables>(SearchCityServiceTypeDocument, baseOptions);
        }
export type SearchCityServiceTypeQueryHookResult = ReturnType<typeof useSearchCityServiceTypeQuery>;
export type SearchCityServiceTypeLazyQueryHookResult = ReturnType<typeof useSearchCityServiceTypeLazyQuery>;
export type SearchCityServiceTypeQueryResult = Apollo.QueryResult<SearchCityServiceTypeQuery, SearchCityServiceTypeQueryVariables>;
export const SearchServiceTypeDocument = gql`
    query searchServiceType($service: String!, $page: Int, $pid: String, $neLat: String, $neLng: String, $swLat: String, $swLng: String, $lat: String, $lng: String, $serviceTypeId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date, $serviceDates: DateArray, $includePrice: Boolean = false, $dogIds: [ID!], $dogs: [DogFilter!], $withDogs: Boolean, $withOutsideArea: Boolean, $withoutKids: Boolean) {
  searchServiceType(service: $service, page: $page, pid: $pid, neLat: $neLat, neLng: $neLng, swLat: $swLat, swLng: $swLng, lat: $lat, lng: $lng, serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs, withDogs: $withDogs, withOutsideArea: $withOutsideArea, withoutKids: $withoutKids) {
    totalEntries
    totalPages
    currentPage
    city {
      id
      placeId
      latitude
      longitude
      name
      slug
      nearbyCities {
        id
        name
        slug
        placeId
        latitude
        longitude
        activeCaregiversCount
      }
    }
    serviceType {
      id
      name
      pricePer
      alwaysPickUpDropOff
      fullDayAvailability
      routeServiceParam
    }
    topCities {
      id
      placeId
      latitude
      longitude
      name
      slug
      activeCaregiversCount
    }
    caregivers {
      id
      caregiverHeadline
      name
      caregiverReviewsCount
      caregiverReviewsScore
      repeatCustomersCount
      latitude
      longitude
      publicAddress
      availableHolidays
      calendarUpToDate
      featuredReview {
        id
        author {
          id
          name
          avatar {
            thumb
          }
        }
        content
      }
      avatar {
        circle
      }
      city {
        id
        slug
        placeId
        latitude
        longitude
        name
      }
      slug
      price(serviceTypeId: $serviceTypeId) @include(if: $includePrice)
      bookingEstimation(serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, serviceDates: $serviceDates, dogIds: $dogIds, dogs: $dogs) {
        currency
        amount
        toS
      }
      availablePromotions {
        id
        tLabel
      }
    }
  }
  country {
    id
    iso3661Alpha2
    countryServiceTypes {
      id
      name
      serviceType {
        id
        alwaysPickUpDropOff
        fullDayAvailability
        pricePer
      }
    }
  }
  currentUser {
    ...currentUserParts
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      avatar {
        circle
      }
      size
      birthday
      tAge
      slug
    }
  }
}
    ${CurrentUserPartsFragmentDoc}`;

/**
 * __useSearchServiceTypeQuery__
 *
 * To run a query within a React component, call `useSearchServiceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchServiceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchServiceTypeQuery({
 *   variables: {
 *      service: // value for 'service'
 *      page: // value for 'page'
 *      pid: // value for 'pid'
 *      neLat: // value for 'neLat'
 *      neLng: // value for 'neLng'
 *      swLat: // value for 'swLat'
 *      swLng: // value for 'swLng'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      serviceDates: // value for 'serviceDates'
 *      includePrice: // value for 'includePrice'
 *      dogIds: // value for 'dogIds'
 *      dogs: // value for 'dogs'
 *      withDogs: // value for 'withDogs'
 *      withOutsideArea: // value for 'withOutsideArea'
 *      withoutKids: // value for 'withoutKids'
 *   },
 * });
 */
export function useSearchServiceTypeQuery(baseOptions?: Apollo.QueryHookOptions<SearchServiceTypeQuery, SearchServiceTypeQueryVariables>) {
        return Apollo.useQuery<SearchServiceTypeQuery, SearchServiceTypeQueryVariables>(SearchServiceTypeDocument, baseOptions);
      }
export function useSearchServiceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchServiceTypeQuery, SearchServiceTypeQueryVariables>) {
          return Apollo.useLazyQuery<SearchServiceTypeQuery, SearchServiceTypeQueryVariables>(SearchServiceTypeDocument, baseOptions);
        }
export type SearchServiceTypeQueryHookResult = ReturnType<typeof useSearchServiceTypeQuery>;
export type SearchServiceTypeLazyQueryHookResult = ReturnType<typeof useSearchServiceTypeLazyQuery>;
export type SearchServiceTypeQueryResult = Apollo.QueryResult<SearchServiceTypeQuery, SearchServiceTypeQueryVariables>;
export const GetCurrentUserForContactDocument = gql`
    query getCurrentUserForContact {
  currentUser {
    id
    phone
    type
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      avatar {
        circle
      }
      size
      birthday
      tAge
      slug
    }
  }
}
    `;

/**
 * __useGetCurrentUserForContactQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForContactQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForContactQuery, GetCurrentUserForContactQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserForContactQuery, GetCurrentUserForContactQueryVariables>(GetCurrentUserForContactDocument, baseOptions);
      }
export function useGetCurrentUserForContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForContactQuery, GetCurrentUserForContactQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserForContactQuery, GetCurrentUserForContactQueryVariables>(GetCurrentUserForContactDocument, baseOptions);
        }
export type GetCurrentUserForContactQueryHookResult = ReturnType<typeof useGetCurrentUserForContactQuery>;
export type GetCurrentUserForContactLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForContactLazyQuery>;
export type GetCurrentUserForContactQueryResult = Apollo.QueryResult<GetCurrentUserForContactQuery, GetCurrentUserForContactQueryVariables>;
export const GetCaregiverForContactDocument = gql`
    query getCaregiverForContact($id: ID!) {
  caregiver(id: $id) {
    id
    name
    type
    latitude
    longitude
    slug
    services {
      id
      currency
      pickUpDropOff
      pickUpDropOffDistance
      pickUpDropOffPrice
      countryServiceType {
        id
        name
        description
        serviceType {
          id
          alwaysPickUpDropOff
          fullDayAvailability
          pricePer
        }
      }
    }
  }
}
    `;

/**
 * __useGetCaregiverForContactQuery__
 *
 * To run a query within a React component, call `useGetCaregiverForContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverForContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverForContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCaregiverForContactQuery(baseOptions?: Apollo.QueryHookOptions<GetCaregiverForContactQuery, GetCaregiverForContactQueryVariables>) {
        return Apollo.useQuery<GetCaregiverForContactQuery, GetCaregiverForContactQueryVariables>(GetCaregiverForContactDocument, baseOptions);
      }
export function useGetCaregiverForContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiverForContactQuery, GetCaregiverForContactQueryVariables>) {
          return Apollo.useLazyQuery<GetCaregiverForContactQuery, GetCaregiverForContactQueryVariables>(GetCaregiverForContactDocument, baseOptions);
        }
export type GetCaregiverForContactQueryHookResult = ReturnType<typeof useGetCaregiverForContactQuery>;
export type GetCaregiverForContactLazyQueryHookResult = ReturnType<typeof useGetCaregiverForContactLazyQuery>;
export type GetCaregiverForContactQueryResult = Apollo.QueryResult<GetCaregiverForContactQuery, GetCaregiverForContactQueryVariables>;
export const ContactCaregiverDocument = gql`
    mutation contactCaregiver($caregiverId: ID!, $message: String!, $serviceTypeId: ID!, $dogIds: [ID!]!, $pickUpDropOff: PickUpDropOffInput, $bookingDatesInput: BookingDatesInput!, $phone: String, $recaptchaToken: String!) {
  contactCaregiver(input: {caregiverId: $caregiverId, message: $message, serviceTypeId: $serviceTypeId, dogIds: $dogIds, pickUpDropOff: $pickUpDropOff, bookingDates: $bookingDatesInput, phone: $phone, recaptchaToken: $recaptchaToken}) {
    potentialJob {
      bookingId
      startDate
      endDate
      serviceDates
      serviceTypeId
      cityName
      dogs {
        name
      }
    }
    booking {
      id
      serviceQuantity
      caregiver {
        id
        name
        city {
          name
        }
      }
      service {
        id
        price
      }
      total {
        currency
      }
      serviceType {
        name
      }
    }
    conversation {
      id
    }
    errors {
      message
      path
    }
  }
}
    `;
export type ContactCaregiverMutationFn = Apollo.MutationFunction<ContactCaregiverMutation, ContactCaregiverMutationVariables>;

/**
 * __useContactCaregiverMutation__
 *
 * To run a mutation, you first call `useContactCaregiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactCaregiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactCaregiverMutation, { data, loading, error }] = useContactCaregiverMutation({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *      message: // value for 'message'
 *      serviceTypeId: // value for 'serviceTypeId'
 *      dogIds: // value for 'dogIds'
 *      pickUpDropOff: // value for 'pickUpDropOff'
 *      bookingDatesInput: // value for 'bookingDatesInput'
 *      phone: // value for 'phone'
 *      recaptchaToken: // value for 'recaptchaToken'
 *   },
 * });
 */
export function useContactCaregiverMutation(baseOptions?: Apollo.MutationHookOptions<ContactCaregiverMutation, ContactCaregiverMutationVariables>) {
        return Apollo.useMutation<ContactCaregiverMutation, ContactCaregiverMutationVariables>(ContactCaregiverDocument, baseOptions);
      }
export type ContactCaregiverMutationHookResult = ReturnType<typeof useContactCaregiverMutation>;
export type ContactCaregiverMutationResult = Apollo.MutationResult<ContactCaregiverMutation>;
export type ContactCaregiverMutationOptions = Apollo.BaseMutationOptions<ContactCaregiverMutation, ContactCaregiverMutationVariables>;
export const CreateJobDocument = gql`
    mutation createJob($input: CreateJobFromBookingInput!) {
  createJobFromBooking(input: $input) {
    errors {
      message
    }
  }
}
    `;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const BookingPriceCalculationDocument = gql`
    query bookingPriceCalculation($serviceId: ID!, $dogIds: [ID!]!, $bookingDates: BookingDatesInput!, $pickUpDropOff: PickUpDropOffInput) {
  bookingPrice(serviceId: $serviceId, dogIds: $dogIds, bookingDates: $bookingDates, pickUpDropOff: $pickUpDropOff) {
    fee {
      amount
      currency
      toS
    }
    total {
      amount
      currency
      toS
    }
    holidayServicePrice {
      count
      price {
        amount
        currency
        toS
      }
    }
    standardServicePrice {
      count
      price {
        amount
        currency
        toS
      }
    }
    serviceTotal {
      amount
      currency
      toS
    }
    pickUpDropOffPrice {
      amount
      currency
      toS
    }
    pricePer
    duration
  }
}
    `;

/**
 * __useBookingPriceCalculationQuery__
 *
 * To run a query within a React component, call `useBookingPriceCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPriceCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPriceCalculationQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      dogIds: // value for 'dogIds'
 *      bookingDates: // value for 'bookingDates'
 *      pickUpDropOff: // value for 'pickUpDropOff'
 *   },
 * });
 */
export function useBookingPriceCalculationQuery(baseOptions?: Apollo.QueryHookOptions<BookingPriceCalculationQuery, BookingPriceCalculationQueryVariables>) {
        return Apollo.useQuery<BookingPriceCalculationQuery, BookingPriceCalculationQueryVariables>(BookingPriceCalculationDocument, baseOptions);
      }
export function useBookingPriceCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingPriceCalculationQuery, BookingPriceCalculationQueryVariables>) {
          return Apollo.useLazyQuery<BookingPriceCalculationQuery, BookingPriceCalculationQueryVariables>(BookingPriceCalculationDocument, baseOptions);
        }
export type BookingPriceCalculationQueryHookResult = ReturnType<typeof useBookingPriceCalculationQuery>;
export type BookingPriceCalculationLazyQueryHookResult = ReturnType<typeof useBookingPriceCalculationLazyQuery>;
export type BookingPriceCalculationQueryResult = Apollo.QueryResult<BookingPriceCalculationQuery, BookingPriceCalculationQueryVariables>;
export const GetCaregiverDocument = gql`
    query getCaregiver($id: ID!, $page: Int = 1) {
  caregiver(id: $id) {
    id
    name
    availablePromotions {
      id
      tLabel
    }
    cleanHeadline
    caregiverStatus
    caregiverReviewsCount
    caregiverReviewsScore
    repeatCustomersCount
    publicAddress
    caregiverDescription
    latitude
    longitude
    caregiverXpAcommodatingDogs
    caregiverXpAsDogWalker
    caregiverCalendarUpdatedAt
    caregiverHasAnimalCertificate
    caregiverHasKidsUnder18
    caregiverHasPppLicense
    caregiverProvideCustomerReviews
    caregiverOwnVehicle
    caregiverCanAdministerOralMeds
    caregiverCanAdministerInjectedMeds
    caregiverVolunteerAnimalGroups
    property {
      id
      type
      garden
      balcony
      terrace
      outdoorSize
    }
    slug
    bookmarked
    lastSeenAt
    responseTime
    bookingsRatio
    answerRatio
    services {
      id
      serviceTypeId
      currency
      price
      holidayPrice
      pickUpDropOff
      pickUpDropOffPrice
      pickUpDropOffDistance
      acceptsDogs {
        puppy
        small
        medium
        large
        giant
      }
    }
    avatar {
      profile
    }
    dogs {
      id
      name
      breed {
        id
        name
        tName
      }
      gender
      size
      tAge
      birthday
      avatar {
        circle
      }
      slug
    }
    reviews(page: $page, perPage: 3) {
      currentPage
      results {
        id
        author {
          id
          name
          avatar {
            thumb
          }
        }
        score
        content
        createdAt
      }
      totalPages
    }
    country {
      locale
    }
    city {
      id
      name
      slug
      nearbyCities {
        name
        slug
        placeId
        activeCaregiversCount
      }
    }
    photos {
      id
      slider
      thumb
      title
    }
  }
}
    `;

/**
 * __useGetCaregiverQuery__
 *
 * To run a query within a React component, call `useGetCaregiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCaregiverQuery(baseOptions?: Apollo.QueryHookOptions<GetCaregiverQuery, GetCaregiverQueryVariables>) {
        return Apollo.useQuery<GetCaregiverQuery, GetCaregiverQueryVariables>(GetCaregiverDocument, baseOptions);
      }
export function useGetCaregiverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiverQuery, GetCaregiverQueryVariables>) {
          return Apollo.useLazyQuery<GetCaregiverQuery, GetCaregiverQueryVariables>(GetCaregiverDocument, baseOptions);
        }
export type GetCaregiverQueryHookResult = ReturnType<typeof useGetCaregiverQuery>;
export type GetCaregiverLazyQueryHookResult = ReturnType<typeof useGetCaregiverLazyQuery>;
export type GetCaregiverQueryResult = Apollo.QueryResult<GetCaregiverQuery, GetCaregiverQueryVariables>;
export const GetTopReviewsByCountryDocument = gql`
    query getTopReviewsByCountry($countryId: ID!) {
  topReviewsByCountry(countryId: $countryId) {
    score
    content
    createdAt
    author {
      id
      name
    }
    reviewable {
      ... on Caregiver {
        id
        name
        avatar {
          circle
        }
      }
    }
  }
}
    `;

/**
 * __useGetTopReviewsByCountryQuery__
 *
 * To run a query within a React component, call `useGetTopReviewsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopReviewsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopReviewsByCountryQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetTopReviewsByCountryQuery(baseOptions?: Apollo.QueryHookOptions<GetTopReviewsByCountryQuery, GetTopReviewsByCountryQueryVariables>) {
        return Apollo.useQuery<GetTopReviewsByCountryQuery, GetTopReviewsByCountryQueryVariables>(GetTopReviewsByCountryDocument, baseOptions);
      }
export function useGetTopReviewsByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopReviewsByCountryQuery, GetTopReviewsByCountryQueryVariables>) {
          return Apollo.useLazyQuery<GetTopReviewsByCountryQuery, GetTopReviewsByCountryQueryVariables>(GetTopReviewsByCountryDocument, baseOptions);
        }
export type GetTopReviewsByCountryQueryHookResult = ReturnType<typeof useGetTopReviewsByCountryQuery>;
export type GetTopReviewsByCountryLazyQueryHookResult = ReturnType<typeof useGetTopReviewsByCountryLazyQuery>;
export type GetTopReviewsByCountryQueryResult = Apollo.QueryResult<GetTopReviewsByCountryQuery, GetTopReviewsByCountryQueryVariables>;
export const GetOwnerBookingsDocument = gql`
    query getOwnerBookings($page: Int = 1, $perPage: Int) {
  ownerBookings(page: $page, perPage: $perPage) {
    results {
      ...OwnerBookingFields
    }
    currentPage
    totalPages
  }
}
    ${OwnerBookingFieldsFragmentDoc}`;

/**
 * __useGetOwnerBookingsQuery__
 *
 * To run a query within a React component, call `useGetOwnerBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerBookingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetOwnerBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnerBookingsQuery, GetOwnerBookingsQueryVariables>) {
        return Apollo.useQuery<GetOwnerBookingsQuery, GetOwnerBookingsQueryVariables>(GetOwnerBookingsDocument, baseOptions);
      }
export function useGetOwnerBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerBookingsQuery, GetOwnerBookingsQueryVariables>) {
          return Apollo.useLazyQuery<GetOwnerBookingsQuery, GetOwnerBookingsQueryVariables>(GetOwnerBookingsDocument, baseOptions);
        }
export type GetOwnerBookingsQueryHookResult = ReturnType<typeof useGetOwnerBookingsQuery>;
export type GetOwnerBookingsLazyQueryHookResult = ReturnType<typeof useGetOwnerBookingsLazyQuery>;
export type GetOwnerBookingsQueryResult = Apollo.QueryResult<GetOwnerBookingsQuery, GetOwnerBookingsQueryVariables>;
export const GetDogDocument = gql`
    query getDog($id: ID!) {
  dog(id: $id) {
    ...DogProfileParts
  }
}
    ${DogProfilePartsFragmentDoc}`;

/**
 * __useGetDogQuery__
 *
 * To run a query within a React component, call `useGetDogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDogQuery(baseOptions?: Apollo.QueryHookOptions<GetDogQuery, GetDogQueryVariables>) {
        return Apollo.useQuery<GetDogQuery, GetDogQueryVariables>(GetDogDocument, baseOptions);
      }
export function useGetDogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDogQuery, GetDogQueryVariables>) {
          return Apollo.useLazyQuery<GetDogQuery, GetDogQueryVariables>(GetDogDocument, baseOptions);
        }
export type GetDogQueryHookResult = ReturnType<typeof useGetDogQuery>;
export type GetDogLazyQueryHookResult = ReturnType<typeof useGetDogLazyQuery>;
export type GetDogQueryResult = Apollo.QueryResult<GetDogQuery, GetDogQueryVariables>;
export const GetCurrentUserForEditDogDocument = gql`
    query getCurrentUserForEditDog {
  currentUser {
    id
    dogs {
      ...DogProfileParts
    }
  }
}
    ${DogProfilePartsFragmentDoc}`;

/**
 * __useGetCurrentUserForEditDogQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForEditDogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForEditDogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForEditDogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForEditDogQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForEditDogQuery, GetCurrentUserForEditDogQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserForEditDogQuery, GetCurrentUserForEditDogQueryVariables>(GetCurrentUserForEditDogDocument, baseOptions);
      }
export function useGetCurrentUserForEditDogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForEditDogQuery, GetCurrentUserForEditDogQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserForEditDogQuery, GetCurrentUserForEditDogQueryVariables>(GetCurrentUserForEditDogDocument, baseOptions);
        }
export type GetCurrentUserForEditDogQueryHookResult = ReturnType<typeof useGetCurrentUserForEditDogQuery>;
export type GetCurrentUserForEditDogLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForEditDogLazyQuery>;
export type GetCurrentUserForEditDogQueryResult = Apollo.QueryResult<GetCurrentUserForEditDogQuery, GetCurrentUserForEditDogQueryVariables>;
export const GetJobDocument = gql`
    query getJob($id: ID!) {
  job(id: $id) {
    id
    status
    startDate
    endDate
    serviceDates
    datesRequested
    playsDogs
    playsCats
    playsChildren
    description
    user {
      name
    }
    dogs {
      id
      name
      tAgeShort
      tSize
      tGender
      avatar {
        circle
        slider
      }
      breed {
        tName
      }
    }
    ... on JobAuthor {
      applications {
        id
        estimatedPaymentTotal {
          toS
        }
        caregiver {
          id
          lastSeenAt
          latitude
          longitude
          name
          caregiverReviewsScore
          caregiverReviewsCount
          avatar {
            circle
          }
        }
      }
    }
    city {
      id
      latitude
      longitude
      name
    }
    serviceType {
      id
      pricePer
    }
    countryServiceType {
      name
    }
  }
}
    `;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions?: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, baseOptions);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, baseOptions);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const GetOwnerJobsDocument = gql`
    query getOwnerJobs {
  ownerJobs {
    id
    status
    startDate
    endDate
    serviceDates
    datesRequested
    playsDogs
    playsCats
    playsChildren
    description
    user {
      name
    }
    dogs {
      id
      name
      tAgeShort
      tSize
      tGender
      avatar {
        circle
        slider
      }
      breed {
        tName
      }
    }
    ... on JobAuthor {
      applications {
        id
        estimatedPaymentTotal {
          toS
        }
        caregiver {
          id
          lastSeenAt
          latitude
          longitude
          name
          caregiverReviewsScore
          caregiverReviewsCount
          avatar {
            circle
          }
        }
      }
    }
    city {
      id
      latitude
      longitude
      name
    }
    serviceType {
      id
      pricePer
    }
    countryServiceType {
      name
    }
  }
}
    `;

/**
 * __useGetOwnerJobsQuery__
 *
 * To run a query within a React component, call `useGetOwnerJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnerJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnerJobsQuery, GetOwnerJobsQueryVariables>) {
        return Apollo.useQuery<GetOwnerJobsQuery, GetOwnerJobsQueryVariables>(GetOwnerJobsDocument, baseOptions);
      }
export function useGetOwnerJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnerJobsQuery, GetOwnerJobsQueryVariables>) {
          return Apollo.useLazyQuery<GetOwnerJobsQuery, GetOwnerJobsQueryVariables>(GetOwnerJobsDocument, baseOptions);
        }
export type GetOwnerJobsQueryHookResult = ReturnType<typeof useGetOwnerJobsQuery>;
export type GetOwnerJobsLazyQueryHookResult = ReturnType<typeof useGetOwnerJobsLazyQuery>;
export type GetOwnerJobsQueryResult = Apollo.QueryResult<GetOwnerJobsQuery, GetOwnerJobsQueryVariables>;
export const DeleteJobDocument = gql`
    mutation deleteJob($input: DeleteJobInput!) {
  deleteJob(input: $input) {
    errors {
      message
    }
  }
}
    `;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, baseOptions);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const GetCurrentUserForRegistrationDocument = gql`
    query getCurrentUserForRegistration {
  currentUser {
    id
    dogs {
      id
      name
      gender
      size
      avatar {
        circle
        big
      }
      tGender
      tAge
      tAgeShort
      tSize
      breed {
        id
        name
        tName
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserForRegistrationQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForRegistrationQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForRegistrationQuery, GetCurrentUserForRegistrationQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserForRegistrationQuery, GetCurrentUserForRegistrationQueryVariables>(GetCurrentUserForRegistrationDocument, baseOptions);
      }
export function useGetCurrentUserForRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForRegistrationQuery, GetCurrentUserForRegistrationQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserForRegistrationQuery, GetCurrentUserForRegistrationQueryVariables>(GetCurrentUserForRegistrationDocument, baseOptions);
        }
export type GetCurrentUserForRegistrationQueryHookResult = ReturnType<typeof useGetCurrentUserForRegistrationQuery>;
export type GetCurrentUserForRegistrationLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForRegistrationLazyQuery>;
export type GetCurrentUserForRegistrationQueryResult = Apollo.QueryResult<GetCurrentUserForRegistrationQuery, GetCurrentUserForRegistrationQueryVariables>;
export const AddDogForRegistrationDocument = gql`
    mutation addDogForRegistration($input: AddDogInput!) {
  addDog(input: $input) {
    dog {
      id
      name
      gender
      size
      slug
      birthday
      spayedNeutered
      chip
      playsDogs
      playsDogsDesc
      playsCats
      playsCatsDesc
      playsChildren
      playsChildrenDesc
      observations
      avatar {
        circle
        big
      }
      tGender
      tAge
      tAgeShort
      tSize
      breed {
        id
        name
        tName
      }
      activityLevel
      dailyFood
      dailyFoodServings {
        morning
        afternoon
        night
      }
      dailyWalks {
        morning
        afternoon
        night
      }
      walkingInstructions
      homeAlone
      healthInfo
      indoorPrecautions
    }
    errors {
      message
      path
    }
  }
}
    `;
export type AddDogForRegistrationMutationFn = Apollo.MutationFunction<AddDogForRegistrationMutation, AddDogForRegistrationMutationVariables>;

/**
 * __useAddDogForRegistrationMutation__
 *
 * To run a mutation, you first call `useAddDogForRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDogForRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDogForRegistrationMutation, { data, loading, error }] = useAddDogForRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDogForRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<AddDogForRegistrationMutation, AddDogForRegistrationMutationVariables>) {
        return Apollo.useMutation<AddDogForRegistrationMutation, AddDogForRegistrationMutationVariables>(AddDogForRegistrationDocument, baseOptions);
      }
export type AddDogForRegistrationMutationHookResult = ReturnType<typeof useAddDogForRegistrationMutation>;
export type AddDogForRegistrationMutationResult = Apollo.MutationResult<AddDogForRegistrationMutation>;
export type AddDogForRegistrationMutationOptions = Apollo.BaseMutationOptions<AddDogForRegistrationMutation, AddDogForRegistrationMutationVariables>;
export const EditDogForRegistrationDocument = gql`
    mutation editDogForRegistration($input: EditDogInput!) {
  editDog(input: $input) {
    dog {
      id
      activityLevel
      dailyFood
      dailyFoodServings {
        morning
        afternoon
        night
      }
      dailyWalks {
        morning
        afternoon
        night
      }
      walkingInstructions
      homeAlone
      healthInfo
      indoorPrecautions
    }
  }
}
    `;
export type EditDogForRegistrationMutationFn = Apollo.MutationFunction<EditDogForRegistrationMutation, EditDogForRegistrationMutationVariables>;

/**
 * __useEditDogForRegistrationMutation__
 *
 * To run a mutation, you first call `useEditDogForRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDogForRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDogForRegistrationMutation, { data, loading, error }] = useEditDogForRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDogForRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<EditDogForRegistrationMutation, EditDogForRegistrationMutationVariables>) {
        return Apollo.useMutation<EditDogForRegistrationMutation, EditDogForRegistrationMutationVariables>(EditDogForRegistrationDocument, baseOptions);
      }
export type EditDogForRegistrationMutationHookResult = ReturnType<typeof useEditDogForRegistrationMutation>;
export type EditDogForRegistrationMutationResult = Apollo.MutationResult<EditDogForRegistrationMutation>;
export type EditDogForRegistrationMutationOptions = Apollo.BaseMutationOptions<EditDogForRegistrationMutation, EditDogForRegistrationMutationVariables>;
import { withTheme } from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Label } from "@rebass/forms/styled-components";
import { default as ReactSelect, components } from "react-select";
import { Icon, ErrorProp } from ".";

interface Props {
  label?: string;
  color: string;
  theme?: any;
  icon?: string;
  error?: ErrorProp;
  isReadOnly?: boolean;
}

interface PlaceholderProps {
  [x: string]: any;
}

// We need to use the withTheme HoC until this bug gets fixed
// https://github.com/rebassjs/rebass/issues/660
const Select = (props: Props) => {
  const { label, theme, icon, error, isReadOnly = false, ...rest } = props;

  const countrolStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2
    }),
    control: (provided, state) => {
      const { selectProps } = state;
      return {
        ...provided,
        backgroundColor: theme.colors["samoyedWhite"],
        borderColor: error.hasError
          ? theme.colors["errorRed"]
          : theme.colors["gray"],
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 2,
        paddingLeft: 2,
        height: "48px",
        lineHeight: "100%",
        fontSize: theme.fontSizes[2],
        borderRadius: theme.radii.default,
        boxShadow: "none",
        width: "100%",
        transition: "box-shadow 0.2s ease 0s",
        ":hover": {
          borderColor: error.hasError
            ? theme.colors["errorRed"]
            : theme.colors["gray"]
        },
        ...selectProps.sx
      };
    },
    singleValue: (provided, state) => {
      const { selectProps } = state;
      return {
        ...provided,
        paddingBottom: 4,
        paddingTop: 4
      };
    },
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const Placeholder = (props: PlaceholderProps) => {
    const { children, className, cx, getStyles, innerProps } = props;

    return (
      <Box
        sx={getStyles("placeholder", props)}
        className={cx(
          {
            placeholder: true
          },
          className
        )}
        {...innerProps}
      >
        <Flex>
          {icon && (
            <Box mr={1}>
              <Icon name={icon} fontSize={2} />
            </Box>
          )}
          {children}
        </Flex>
      </Box>
    );
  };

  const IndicatorsContainer = props => {
    return (
      <components.IndicatorsContainer {...props}>
        <components.DropdownIndicator {...props}>
          <components.DownChevron
            css={{
              fill: theme.colors["springGreen"] + " !important"
            }}
          ></components.DownChevron>
        </components.DropdownIndicator>
      </components.IndicatorsContainer>
    );
  };

  return (
    <Box>
      {label && (
        <Label
          mb={1}
          sx={{
            color: error.hasError ? "errorRed" : "labadorBlack"
          }}
        >
          {label}
        </Label>
      )}
      <ReactSelect
        components={{
          IndicatorsContainer: IndicatorsContainer,
          Placeholder: Placeholder
        }}
        styles={countrolStyles}
        isDisabled={isReadOnly}
        {...rest}
      />
    </Box>
  );
};

Select.defaultProps = {
  color: "gray",
  error: {
    hasError: false,
    message: null
  }
};

export default withTheme(Select);

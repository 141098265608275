import { DefaultToast } from "react-toast-notifications";
import { Box } from "rebass/styled-components";

const WarningToast = ({ children, ...props }: any) => {
  return (
    <Box
      data-test="warning-toast"
      sx={{
        marginTop: [1, "80px"],
        ">div": {
          "[class*='Icon']": {
            width: "25px",
            backgroundColor: "inuOrange",
            svg: { display: "none !important" }
          }
        }
      }}
    >
      <DefaultToast {...props}>{children}</DefaultToast>
    </Box>
  );
};

export default WarningToast;

import { Box, Flex, Text, Image } from "rebass/styled-components";
import { Select, TextArea, Icon, defaultErrorProp } from "..";
import useTranslation from "next-translate/useTranslation";

import { Label } from "@rebass/forms/styled-components";
import { useState, useRef, useEffect, ReactElement } from "react";
import {
  Dog,
  GetCurrentUserForEditDogDocument,
  useAddDogMutation,
  useEditDogMutation
} from "../../../generated/graphql";
import DogBreedSelector from "./DogBreedSelector";
import DogBirthdaySelector from "./DogBirthdaySelector";
import DogGenderSelector from "./DogGenderSelector";
import DogSizeSelector from "./DogSizeSelector";
import DogInput from "./DogInput";
import Button from "../Button";
import Heading from "../Heading";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { DogFragment } from "../../../fragments/index.fragments";
import { FullLoader } from "../loader";
import Modal from "../Modal";
import { useIsMobile } from "../../../lib/media";
import { Promise } from "q";
import Input from "../Input";
import Gallery from "./Gallery";
import { ToastProvider } from "react-toast-notifications";
import { WarningToast } from "../../common/toasts";
import { useToasts } from "react-toast-notifications";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { move } from "formik";
import ReactCrop, { Crop } from "react-image-crop";
import getCroppedImg from "../../../lib/crop-image";
import { getImageUrl } from "../../../lib/image-url";
import { pushRoute } from "../../../lib/routing";
import DogActivityLevelSelector from "./DogActivityLevelSelector";
import DogDailyServingsSelector from "./DogDailyServingsSelector";
import DogDailyWalksSelector from "./DogDailyWalksSelector";
import { InputWrap, PreviewTextArea } from "../forms";

const MAX_FILE_SIZE_BYTES = 20000000;

interface Props {
  dog: Omit<Dog, "user">;
  slimView: boolean;
  triggerSave: boolean;
  displayControls: boolean;
  afterSave?: () => void;
}

interface ProfilePictureProps {
  dog: Omit<Dog, "user">;
  avatar: string;
  onChange: (event) => void;
}

interface PhotoAlbumProps {
  dog?: Omit<Dog, "user">;
  photos?: DogPhotoInputProps[];
  unsavedPhotos?: DogPhotoInputProps[];
  uploadedPhotos?: string[];
  onUpload?: (photos) => void;
  onSave?: (photos) => void;
  children?: ReactElement;
  onClose?: () => void;
}

interface DogPhotoInputProps {
  dogPhotoId?: string;
  caption?: string;
  attachment?: string;
}

interface ManagePhotoAlbumProps {
  onChange: (value, index) => void;
  onAddAnother: (photos) => void;
  onDelete: (index) => void;
  onPositionChange: (list) => void;
  photoAlbum: DogPhotoInputProps[];
}

interface InputProps {
  label: string;
  value: string;
  onChange: (event) => void;
}

interface BooleanProps {
  label: string;
  value: boolean;
  onSelect: (event) => void;
}

interface CropImageProps {
  src: string;
  onClose: () => void;
  onSave: (Image, Crop) => void;
}

interface ModalFooterProps {
  onClose: () => void;
  onSave: () => void;
}

const DELETE_DOG_REQUEST = gql`
  mutation deleteDog($id: ID!) {
    deleteDog(input: { id: $id }) {
      errors {
        message
        path
      }
    }
  }
`;

const DELETE_DOG_PHOTO_REQUEST = gql`
  mutation deleteDogPhoto($dogId: ID!, $dogPhotoId: ID!) {
    deleteDogPhoto(input: { dogId: $dogId, dogPhotoId: $dogPhotoId }) {
      dog {
        ...DogProfileParts
      }
      errors {
        message
        path
      }
    }
  }
  ${DogFragment.profile}
`;

const ADD_DOG_PHOTO_REQUEST = gql`
  mutation addDogPhoto($id: ID!, $photosInput: [DogPhotoInput!]!) {
    addDogPhoto(input: { id: $id, photosInput: $photosInput }) {
      dog {
        ...DogProfileParts
      }
      errors {
        message
        path
      }
    }
  }
  ${DogFragment.profile}
`;

interface SortableItemProps {
  photo: DogPhotoInputProps;
  sortIndex: number;
  onDelete: (index) => void;
  onChange: (value, index) => void;
}

const SortableItem = SortableElement(
  ({ photo, sortIndex, onDelete, onChange }: SortableItemProps) => {
    const { t } = useTranslation("common");

    const onDeleteConfirmation = index => {
      if (confirm(t("confirmationAlert"))) {
        onDelete(index);
      }
    };
    return (
      <Box
        width={[1 / 2, 1 / 2, 1 / 5]}
        px={"4px"}
        sx={{
          zIndex: 1000,
          "-webkit-touch-callout": "none",
          "-webkit-user-select": "none",
          "-khtml-user-select": "none",
          "-moz-user-select": "none",
          "-ms-user-select": "none",
          "user-select": "none"
        }}
      >
        <Box
          pt={"65%"}
          sx={{
            backgroundImage: `url(${photo.attachment})`,
            backgroundPositionX: "50%",
            backgroundPositionY: "50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative"
          }}
        >
          <Box
            sx={{
              position: "absolute",
              borderRadius: "circle",
              top: 1,
              right: 1,
              backgroundColor: "darkGray",
              height: "32px",
              width: "32px",
              p: 1,
              boxShadow: "0 2px 4px 0 #000000",
              mixBlendMode: "multiply"
            }}
          ></Box>
          <Box
            sx={{
              top: 2,
              right: 2,
              position: "absolute",
              cursor: "pointer"
            }}
            onClick={() => {
              onDeleteConfirmation(sortIndex);
            }}
          >
            <Icon name="trash" fontSize={2} color="samoyedWhite"></Icon>
          </Box>
        </Box>
        <Box mt={1}>
          <Input
            px={0}
            placeholder={t("writeCaption")}
            value={photo.caption}
            sx={{
              border: "none",
              bg: "transparent",
              ":focus": {
                border: "none",
                outline: "none",
                boxShadow: "none"
              }
            }}
            onChange={({ target }) => {
              onChange(target.value, sortIndex);
            }}
          ></Input>
        </Box>
      </Box>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, onDelete, onAddAnother, onChange }) => {
    return (
      <Flex
        py={1}
        mx={"-4px"}
        sx={{
          flexWrap: "wrap",
          zIndex: 9999
        }}
      >
        {items.map((value, index) => {
          return (
            <SortableItem
              key={index}
              index={index}
              sortIndex={index}
              photo={value}
              onChange={onChange}
              onDelete={onDelete}
            />
          );
        })}
        <AddAnother onAddAnother={onAddAnother} />
      </Flex>
    );
  }
);

interface AddAnotherProps {
  onAddAnother: () => void;
}

const AddAnother = ({ onAddAnother }: AddAnotherProps) => {
  const { t } = useTranslation("common");
  return (
    <Box width={[1 / 2, 1 / 2, 1 / 5]} px={"4px"}>
      <Box
        pt={"65%"}
        sx={{
          border: "1px dashed",
          borderColor: "springGreen",
          position: "relative"
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "33%",
            right: 0,
            left: 0
          }}
        >
          <PhotoAlbumUpload onUpload={onAddAnother}>
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Icon name="plus" color="springGreen" fontSize={5}></Icon>
              <Text
                mt={1}
                sx={{
                  color: "springGreen",
                  fontSize: 3
                }}
              >
                {t("addAnother")}
              </Text>
            </Flex>
          </PhotoAlbumUpload>
        </Box>
      </Box>
    </Box>
  );
};

const Oberservation = ({ label, value, onChange }: InputProps) => {
  return (
    <Box mt={3}>
      <Label mb={1}>{label}</Label>
      <TextArea
        rows={5}
        placeholder={label}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
};

const ModalFooter = ({ onClose, onSave }: ModalFooterProps) => {
  const { t } = useTranslation("common");

  return (
    <Flex
      pt={1}
      px={[1, 1, 0]}
      py={[1, 1, 0]}
      sx={{
        textAlign: "right",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Text
        as="p"
        variant="link"
        sx={{
          color: "springGreen",
          fontSize: 3,
          lineHeight: "body",
          cursor: "pointer",
          display: "inline"
        }}
        onClick={onClose}
      >
        {t("cancel")}
      </Text>
      <Button variant="secondary" onClick={onSave}>
        {t("save")}
      </Button>
    </Flex>
  );
};

export function CropImage({ src, onClose, onSave }: CropImageProps) {
  const isMobileViewport = useIsMobile();

  const [state, setState] = useState({
    crop: {
      unit: "%",
      aspect: 1,
      width: 100,
      height: 100,
      x: 0,
      y: 0
    } as Crop,
    imageWidth: "100%",
    imageHeight: "100%",
    image: null
  });
  const { image, crop, imageHeight, imageWidth } = state;

  const onImageLoaded = image => {
    const width =
      image.width > image.height ? (image.height / image.width) * 100 : 100;
    const height =
      image.height > image.width ? (image.width / image.height) * 100 : 100;
    const x = width === 100 ? 0 : (100 - width) / 2;
    const y = height === 100 ? 0 : (100 - height) / 2;

    setState({
      ...state,
      imageHeight: `${image.height}px`,
      imageWidth: `${image.width}px`,
      crop: {
        unit: "px",
        aspect: 1,
        width: (image.width / 100) * width,
        height: (image.height / 100) * height,
        x: (image.width / 100) * x,
        y: (image.height / 100) * y
      },
      image: image
    });

    return false;
  };

  const onChange = newCrop => {
    setState({
      ...state,
      crop: newCrop
    });
  };

  const save = () => {
    onSave(image, crop);
  };

  return isMobileViewport ? (
    <Modal
      open={true}
      size="full"
      FooterButton={<ModalFooter onSave={save} onClose={onClose} />}
      HeaderButton={<Box></Box>}
      onClose={onClose}
    >
      <Box
        mx={"auto"}
        my={2}
        sx={{
          textAlign: "center"
        }}
      >
        <ReactCrop
          src={src}
          crop={crop}
          circularCrop
          onImageLoaded={onImageLoaded}
          onChange={onChange}
        />
      </Box>
    </Modal>
  ) : (
    <Modal
      open={true}
      size="small"
      FooterButton={<ModalFooter onSave={save} onClose={onClose} />}
      HeaderButton={<Box></Box>}
      onOutsideClick={onClose}
      onClose={onClose}
    >
      <Box
        my={2}
        mx={"auto"}
        sx={{
          width: imageWidth,
          height: `calc(${imageHeight} + 80px)`
        }}
      >
        <ReactCrop
          src={src}
          crop={crop}
          circularCrop
          onImageLoaded={onImageLoaded}
          onChange={onChange}
        />
      </Box>
    </Modal>
  );
}

const ProfilePicture = ({ dog, avatar, onChange }: ProfilePictureProps) => {
  const { t } = useTranslation("common");
  const fileUploaderRef = useRef(null);
  const { addToast } = useToasts();
  const [state, setState] = useState({
    show: false,
    src: null
  });

  const previewImage = event => {
    const acceptedFileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    const files = Array.prototype.filter.call(event.target.files, file => {
      if (acceptedFileTypes.includes(file.type)) {
        return true;
      } else {
        addToast(
          t("invalidFileType", {
            acceptedTypes: acceptedFileTypes.join(", "),
            invalidType: file.type
          }),
          {
            appearance: "info",
            autoDismiss: true,
            style: {
              maxWidth: "90vw"
            }
          }
        );
        return false;
      }
    });
    event.target.value = "";

    if (files.length > 0) {
      getImageUrl(files[0], undefined).then(value => {
        setState({
          show: true,
          src: value
        });
      });
    }
  };

  const openFileUploader = () => {
    fileUploaderRef.current.multiple = false;
    fileUploaderRef.current.accept = "image/*";
    fileUploaderRef.current.click();
  };

  const onClose = () => {
    setState({
      src: null,
      show: false
    });
  };

  const onSave = (image, crop: Crop) => {
    onChange(getCroppedImg(image, crop));
    setState({
      src: null,
      show: false
    });
  };

  return (
    <Box my={1}>
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Label mb={2}>{t("profilePicture")}</Label>
        {(avatar || dog.avatar) && (
          <Image
            src={avatar || dog.avatar.circle}
            alt={`${dog.name} avatar`}
            variant="avatar"
            height="96px"
            width="96px"
            mb={2}
          ></Image>
        )}
        <Box width={1}>
          <Button width={1} variant="terciary" onClick={openFileUploader}>
            {!avatar && !dog.avatar ? t("selectImage") : t("changePhoto")}
          </Button>
        </Box>

        <Box
          ref={fileUploaderRef}
          as="input"
          type="file"
          sx={{
            display: "none"
          }}
          onChange={previewImage}
        ></Box>
      </Flex>
      {state.show && (
        <CropImage
          src={state.src}
          onSave={onSave}
          onClose={onClose}
        ></CropImage>
      )}
    </Box>
  );
};

const PhotoAlbum = ({
  photoAlbum,
  onChange,
  onDelete,
  onAddAnother,
  onPositionChange
}: ManagePhotoAlbumProps) => {
  const { t } = useTranslation("common");

  return (
    <Box pb={3}>
      <Heading py={1} as="h3">
        {t("photoAlbum")}
      </Heading>
      <SortableList
        axis="xy"
        pressDelay={200}
        onSortEnd={({ oldIndex, newIndex }) => {
          onPositionChange(move(photoAlbum, oldIndex, newIndex));
        }}
        items={photoAlbum}
        onChange={onChange}
        onDelete={onDelete}
        onAddAnother={onAddAnother}
      />
    </Box>
  );
};

const PhotoAlbumModal = ({
  dog,
  photos,
  onClose,
  onSave,
  uploadedPhotos
}: PhotoAlbumProps) => {
  const { t } = useTranslation("common");
  const isMobileViewport = useIsMobile();
  const [deleteDogPhoto] = useMutation(DELETE_DOG_PHOTO_REQUEST);

  const [photoAlbum, setPhotoAlbum] = useState([
    ...photos,
    ...uploadedPhotos.map(photo => {
      return {
        dogPhotoId: null,
        attachment: photo,
        caption: ""
      };
    })
  ]);

  const onChange = (value, index) => {
    let tmpPhotoAlbum = photoAlbum;
    tmpPhotoAlbum[index].caption = value;
    setPhotoAlbum([...tmpPhotoAlbum]);
  };

  const onPositionChange = list => {
    setPhotoAlbum(list);
  };

  const onDelete = index => {
    let photo = photoAlbum[index];
    let tmpPhotoAlbum = photoAlbum;
    tmpPhotoAlbum.splice(index, 1);
    setPhotoAlbum([...tmpPhotoAlbum]);
    if (photo.dogPhotoId) {
      deleteDogPhoto({
        variables: {
          dogId: dog.id,
          dogPhotoId: photo.dogPhotoId
        }
      });
    }
  };

  const onAddAnother = photos => {
    setPhotoAlbum([
      ...photoAlbum,
      ...photos.map(photo => {
        return {
          dogPhotoId: null,
          attachment: photo,
          caption: ""
        };
      })
    ]);
  };

  const close = () => {
    onClose();
  };

  const closeConfirmation = () => {
    if (uploadedPhotos.length + photoAlbum.length > photos.length) {
      if (confirm(t("unsavedPhotosAlert"))) {
        close();
      }
    } else {
      close();
    }
  };

  const ModalFooter = () => {
    return (
      <Flex
        pt={1}
        px={[1, 1, 0]}
        py={[1, 1, 0]}
        sx={{
          textAlign: "right",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Text
          as="p"
          variant="link"
          sx={{
            color: "springGreen",
            fontSize: 3,
            lineHeight: "body",
            cursor: "pointer",
            display: "inline"
          }}
          onClick={closeConfirmation}
        >
          {t("cancel")}
        </Text>
        <Button
          variant="secondary"
          onClick={() => {
            onSave(photoAlbum);
            close();
          }}
        >
          {t("update")}
        </Button>
      </Flex>
    );
  };

  return isMobileViewport ? (
    <Modal
      open={true}
      size="full"
      FooterButton={<ModalFooter />}
      HeaderButton={<Box></Box>}
      onClose={closeConfirmation}
    >
      <Box pb="80px">
        <PhotoAlbum
          onChange={onChange}
          onAddAnother={onAddAnother}
          onDelete={onDelete}
          photoAlbum={photoAlbum}
          onPositionChange={onPositionChange}
        ></PhotoAlbum>
      </Box>
    </Modal>
  ) : (
    <Modal open={true} onOutsideClick={closeConfirmation} size="large">
      <Box py={4} px={2}>
        <PhotoAlbum
          onChange={onChange}
          onAddAnother={onAddAnother}
          onDelete={onDelete}
          photoAlbum={photoAlbum}
          onPositionChange={onPositionChange}
        ></PhotoAlbum>
        <Box
          pt={2}
          sx={{
            borderTop: "default"
          }}
        >
          <ModalFooter />
        </Box>
      </Box>
    </Modal>
  );
};

const PhotoAlbumUpload = ({ onUpload, children }: PhotoAlbumProps) => {
  const { t } = useTranslation("common");
  const fileUploaderRef = useRef(null);
  const { addToast } = useToasts();

  const uploadFiles = files => {
    return Promise(async resolve => {
      let imageUrls = [];

      for (let i = 0; i < files.length; i++) {
        if (files[i].size < MAX_FILE_SIZE_BYTES) {
          imageUrls.push(await getImageUrl(files[i], undefined));
        } else {
          addToast(t("maxFileSizeExceeded"), {
            appearance: "info",
            autoDismiss: true,
            style: {
              maxWidth: "90vw"
            }
          });
        }
      }
      resolve(imageUrls);
    });
  };

  const previewImage = async event => {
    const acceptedFileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    const files = Array.prototype.filter.call(event.target.files, file => {
      if (acceptedFileTypes.includes(file.type)) {
        return true;
      } else {
        addToast(
          t("invalidFileType", {
            acceptedTypes: acceptedFileTypes.join(", "),
            invalidType: file.type
          }),
          {
            appearance: "info",
            autoDismiss: true,
            style: {
              maxWidth: "90vw"
            }
          }
        );
        return false;
      }
    });
    event.target.value = "";

    const imageUrls = (await uploadFiles(files)) as string[];
    onUpload(imageUrls);
  };

  const openFileUploader = () => {
    fileUploaderRef.current.multiple = true;
    fileUploaderRef.current.accept = ".jpeg, .jpg, .gif , .png";
    fileUploaderRef.current.click();
  };

  return (
    <Box>
      <span onClick={openFileUploader}>{children}</span>
      <Box
        ref={fileUploaderRef}
        as="input"
        type="file"
        sx={{
          display: "none"
        }}
        onChange={previewImage}
      ></Box>
    </Box>
  );
};

const PhotoAlbumPreview = ({ dog, onSave, unsavedPhotos }: PhotoAlbumProps) => {
  const { t } = useTranslation("common");

  const [state, setState] = useState({
    uploadedPhotos: [],
    showPhotoAlbumModal: false
  });

  const { uploadedPhotos, showPhotoAlbumModal } = state;
  const isMobileViewport = useIsMobile();

  const photos = [
    ...dog.photos
      .filter((photo, index) => {
        return dog.photos.indexOf(photo) == index;
      })
      .map(photo => {
        return {
          dogPhotoId: photo.id,
          caption: photo.title || "",
          attachment: photo.slider
        };
      }),
    ...unsavedPhotos
  ];

  return (
    <Box pt={1}>
      {photos.length == 0 ? (
        <Box
          sx={{
            border: "1px dashed",
            borderColor: "gray",
            position: "relative"
          }}
        >
          <Flex
            sx={{
              flexWrap: "wrap"
            }}
          >
            {[...Array(isMobileViewport ? 4 : 6)].map((value, index) => {
              return (
                <Box key={index} width={[1 / 2, 1 / 2, 1 / 3]} p={"4px"}>
                  <Box
                    pb="75%"
                    sx={{
                      bg: "#F5F5F5"
                    }}
                  ></Box>
                </Box>
              );
            })}
          </Flex>
          <Flex
            width={1}
            sx={{
              position: "absolute",
              top: "calc(50% - 24px)",
              justifyContent: "center"
            }}
          >
            <PhotoAlbumUpload
              onUpload={photos => {
                setState({
                  uploadedPhotos: photos,
                  showPhotoAlbumModal: true
                });
              }}
              onSave={onSave}
            >
              <Button size={"large"}>{t("uploadPhotos")}</Button>
            </PhotoAlbumUpload>
          </Flex>
        </Box>
      ) : (
        <Box>
          <Gallery
            photos={photos}
            onClick={() => {
              setState({
                uploadedPhotos: uploadedPhotos,
                showPhotoAlbumModal: true
              });
            }}
          />
        </Box>
      )}
      {showPhotoAlbumModal && (
        <PhotoAlbumModal
          dog={dog}
          photos={photos}
          onClose={() => {
            setState({
              uploadedPhotos: [],
              showPhotoAlbumModal: false
            });
          }}
          onSave={onSave}
          uploadedPhotos={uploadedPhotos}
        />
      )}
    </Box>
  );
};

const BooleanField = ({ label, value, onSelect }: BooleanProps) => {
  const { t } = useTranslation("common");

  const options = [
    {
      value: true,
      label: t("yes")
    },
    {
      value: false,
      label: t("no")
    }
  ];

  return (
    <InputWrap label={label}>
      <Select
        value={
          value != null
            ? options.filter(optionValue => {
                return optionValue.value == value;
              })[0]
            : null
        }
        onChange={onSelect}
        options={options}
      />
    </InputWrap>
  );
};

function CreateOrUpdateDog({
  dog,
  slimView,
  displayControls,
  triggerSave,
  afterSave
}: Props) {
  const { t } = useTranslation("common");
  const refs = {
    name: useRef(null),
    breedId: useRef(null),
    birthday: useRef(null),
    gender: useRef(null),
    size: useRef(null)
  };

  const defaultErrorState = {
    avatar: defaultErrorProp,
    name: defaultErrorProp,
    breedId: defaultErrorProp,
    birthday: defaultErrorProp,
    gender: defaultErrorProp,
    size: defaultErrorProp,
    spayedNeutered: defaultErrorProp,
    playsCats: defaultErrorProp,
    playsChildren: defaultErrorProp,
    playsDogs: defaultErrorProp,
    observations: defaultErrorProp,
    chip: defaultErrorProp,
    photos: defaultErrorProp
  };

  const [hidden, setHidden] = useState(false);

  const [state, setState] = useState({
    dog: {
      avatar: null,
      name: dog.name,
      breedId: dog.breed ? dog.breed.id : null,
      birthday: dog.birthday ? new Date(dog.birthday) : null,
      gender: dog.gender,
      size: dog.size,
      spayedNeutered: dog.spayedNeutered,
      playsCats: dog.playsCats,
      playsChildren: dog.playsChildren,
      playsDogs: dog.playsDogs,
      observations: dog.observations ? dog.observations : "",
      chip: dog.chip,
      photos: [],
      dailyFood: dog?.dailyFood,
      dailyFoodServings: {
        ...(dog?.dailyFoodServings || {
          morning: false,
          afternoon: false,
          night: false
        }),
        __typename: undefined
      },
      dailyWalks: {
        ...(dog?.dailyWalks || {
          morning: false,
          afternoon: false,
          night: false
        }),
        __typename: undefined
      },
      walkingInstructions: dog?.walkingInstructions,
      activityLevel: dog?.activityLevel,
      homeAlone: dog?.homeAlone,
      indoorPrecautions: dog?.indoorPrecautions,
      healthInfo: dog?.healthInfo
    },
    errors: defaultErrorState
  });

  const [addDogPhotos, { loading: addDogPhotosLoading }] = useMutation(
    ADD_DOG_PHOTO_REQUEST
  );

  const [addDog, { loading: addDogLoading }] = useAddDogMutation({
    onCompleted: async data => {
      if (data.addDog.errors.length == 0) {
        if (state.dog.photos.length > 0) {
          await addDogPhotos({
            variables: {
              id: data.addDog.dog.id,
              photosInput: state.dog.photos
            }
          });
        }
        if (!slimView) {
          pushRoute({
            pathname: "/user/editdog",
            query: {
              id: data.addDog.dog.slug
            }
          });
        } else {
          setHidden(true);
        }
      }
    },
    refetchQueries: [
      {
        query: GetCurrentUserForEditDogDocument
      }
    ],
    awaitRefetchQueries: true
  });

  const [editDog, { loading: editDogLoading }] = useEditDogMutation({
    onCompleted: async data => {
      if (data.editDog.errors.length == 0) {
        !!afterSave && afterSave();
      }
    }
  });

  const [deleteDog, { loading: deleteDogLoading }] = useMutation(
    DELETE_DOG_REQUEST,
    {
      update(cache) {
        const query = gql`
          query getDog($id: ID!) {
            dog(id: $id) {
              id
            }
          }
        `;

        cache.writeQuery({
          query: query,
          variables: {
            id: dog.slug
          },
          data: {
            dog: null
          }
        });
      },
      refetchQueries: [
        {
          query: GetCurrentUserForEditDogDocument
        }
      ],
      awaitRefetchQueries: true,
      onCompleted: data => {
        if (data.deleteDog.errors.length == 0 && !afterSave) {
          pushRoute({ pathname: "/user/dogs" });
        } else {
          afterSave();
        }
      }
    }
  );

  const loading =
    addDogLoading || editDogLoading || deleteDogLoading || addDogPhotosLoading;

  const validate = () => {
    let tmpErrors = defaultErrorState;
    let errorCount = 0;

    if (state.dog.name.length <= 0) {
      tmpErrors.name = {
        hasError: true,
        message: "required"
      };
      errorCount++;
    }

    if (!state.dog.breedId) {
      tmpErrors.breedId = {
        hasError: true,
        message: "required"
      };
      errorCount++;
    }

    if (!state.dog.gender) {
      tmpErrors.gender = {
        hasError: true,
        message: "required"
      };
      errorCount++;
    }

    if (!state.dog.size) {
      tmpErrors.size = {
        hasError: true,
        message: "required"
      };
      errorCount++;
    }

    if (!state.dog.birthday) {
      tmpErrors.birthday = {
        hasError: true,
        message: "required"
      };
      errorCount++;
    }

    if (errorCount > 0) {
      const error = Object.keys(tmpErrors).filter(value => {
        return tmpErrors[value].hasError == true;
      })[0];

      if (error && refs[error]) {
        var rect = refs[error].current.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;

        // Only completely visible elements return true:
        if (!(elemTop >= 0 && elemBottom <= window.innerHeight)) {
          refs[error].current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth"
          });
        }
      }
    }
    return {
      isValid: errorCount == 0,
      tmpErrors: tmpErrors
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = () => {
    const { isValid, tmpErrors } = validate();

    setState({ ...state, errors: tmpErrors });
    if (isValid) {
      const input = {
        ...state.dog,
        photos: undefined
      };

      if (dog.id) {
        editDog({
          variables: {
            input: {
              id: dog.id,
              ...input
            }
          }
        });
      } else {
        addDog({
          variables: {
            input: input
          }
        });
      }
    }
  };

  const onSave = photos => {
    if (dog.id) {
      setState({
        ...state,
        dog: {
          ...state.dog,
          photos: []
        }
      });
      addDogPhotos({
        variables: {
          id: dog.id,
          photosInput: photos
        }
      });
    } else {
      setState({
        ...state,
        dog: {
          ...state.dog,
          photos: photos
        }
      });
    }
  };

  const deleteDogClick = () => {
    deleteDog({
      variables: {
        id: dog.id
      }
    });
  };

  type SingleTargets = "activityLevel" | "size";
  type MultipleTargets = "dailyFoodServings" | "dailyWalks";

  const onSelectingSingle = (selected: string, target: SingleTargets) => {
    setState({
      ...state,
      dog: {
        ...state.dog,
        [target]: selected
      }
    });
  };

  const onSelectingMultiple = (selected: string, target: MultipleTargets) => {
    const updated = {
      ...state.dog[target]
    };

    updated[selected] = !updated[selected];

    setState({
      ...state,
      dog: {
        ...state.dog,
        [target]: {
          ...updated
        }
      }
    });
  };

  useEffect(() => {
    if (triggerSave && !hidden) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave]);

  return hidden ? null : (
    <ToastProvider components={{ Toast: WarningToast }}>
      <Box>
        {loading && <FullLoader />}
        <Flex
          width={1}
          sx={{
            flexDirection: ["column", "column", "row"],
            justifyContent: "space-between"
          }}
        >
          <Box width={slimView ? 1 : [1, 1, 6 / 10]}>
            <ProfilePicture
              dog={dog}
              avatar={state.dog.avatar}
              onChange={avatar => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    avatar: avatar
                  }
                });
              }}
            />
            {displayControls && (
              <Box mx={["-16px", "-16px", "0"]}>
                <PhotoAlbumPreview
                  dog={dog}
                  onSave={onSave}
                  unsavedPhotos={state.dog.photos}
                />
              </Box>
            )}
            <Box ref={refs.name} mt={3}>
              <DogInput
                name="name"
                value={state.dog.name}
                onChange={event => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      name: event.target.value
                    }
                  });
                }}
                error={state.errors.name}
              />
            </Box>
            <InputWrap
              ref={refs.breedId}
              label={t("breed")}
              error={
                state.errors.breedId?.hasError &&
                t(state.errors.breedId?.message)
              }
            >
              <DogBreedSelector
                selected={state.dog.breedId}
                onSelect={({ value }) => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      breedId: value
                    }
                  });
                }}
                error={state.errors.breedId}
              />
            </InputWrap>
            <InputWrap
              notLabeled
              ref={refs.birthday}
              label={t("dateOfBirth")}
              error={
                state.errors.birthday?.hasError &&
                t(state.errors.birthday?.message)
              }
            >
              <DogBirthdaySelector
                selected={state.dog.birthday}
                onSelect={({ value }) => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      birthday: value
                    }
                  });
                }}
                error={state.errors.birthday}
              />
            </InputWrap>
            <InputWrap
              ref={refs.gender}
              label={t("gender")}
              error={
                state.errors.gender?.hasError && t(state.errors.gender?.message)
              }
            >
              <DogGenderSelector
                selected={state.dog.gender}
                onSelect={({ value }) => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      gender: value
                    }
                  });
                }}
                error={state.errors.gender}
              />
            </InputWrap>
            <InputWrap
              notLabeled
              ref={refs.size}
              label={t("size")}
              error={
                state.errors.size?.hasError && t(state.errors.size?.message)
              }
            >
              <DogSizeSelector
                selected={state.dog.size}
                onSelect={selected => onSelectingSingle(selected, "size")}
              />
            </InputWrap>
            <BooleanField
              label={t("isSpayedNeutered")}
              value={state.dog.spayedNeutered}
              onSelect={({ value }) => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    spayedNeutered: value
                  }
                });
              }}
            />
            <BooleanField
              label={t("playsCats")}
              value={state.dog.playsCats}
              onSelect={({ value }) => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    playsCats: value
                  }
                });
              }}
            />
            <BooleanField
              label={t("playsChildren")}
              value={state.dog.playsChildren}
              onSelect={({ value }) => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    playsChildren: value
                  }
                });
              }}
            />
            <BooleanField
              label={t("playsDogs")}
              value={state.dog.playsDogs}
              onSelect={({ value }) => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    playsDogs: value
                  }
                });
              }}
            />
            <Oberservation
              label={t("oberservations")}
              value={state.dog.observations}
              onChange={event => {
                setState({
                  ...state,
                  dog: {
                    ...state.dog,
                    observations: event.target.value
                  }
                });
              }}
            />
            <InputWrap>
              <DogInput
                name="chip"
                value={state.dog.chip}
                onChange={event => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      chip: event.target.value
                    }
                  });
                }}
                error={state.errors.chip}
              />
            </InputWrap>
            {/** TODO add is dog PPP */}
            <Box mt={3}>
              <Heading as="h3" variant="highlight">
                {t("dogSitterProvisions")}
              </Heading>
              <Text as="p" color="darkGray">
                {t("dogSitterProvisionsDesc")}
              </Text>
            </Box>
            <InputWrap>
              <DogInput
                name="dailyFood"
                value={state.dog.dailyFood}
                onChange={event => {
                  setState({
                    ...state,
                    dog: {
                      ...state.dog,
                      dailyFood: event.target.value
                    }
                  });
                }}
              />
            </InputWrap>
            <InputWrap notLabeled label={t("selectDogDailyFoodServings")}>
              <DogDailyServingsSelector
                selected={state.dog.dailyFoodServings}
                onSelect={selected =>
                  onSelectingMultiple(selected, "dailyFoodServings")
                }
              />
            </InputWrap>
            <InputWrap>
              <PreviewTextArea
                label={t("healthDetails")}
                enablePreview={!state.dog.healthInfo}
                preview={t("healthDetailsDesc")}
              >
                <TextArea
                  rows={5}
                  placeholder={t("healthDetailsDesc")}
                  value={state.dog.healthInfo}
                  onChange={event => {
                    setState({
                      ...state,
                      dog: {
                        ...state.dog,
                        healthInfo: event.target.value
                      }
                    });
                  }}
                />
              </PreviewTextArea>
            </InputWrap>
            <InputWrap notLabeled label={t("selectDogDailyWalks")}>
              <DogDailyWalksSelector
                selected={state.dog.dailyWalks}
                onSelect={selected =>
                  onSelectingMultiple(selected, "dailyWalks")
                }
              />
            </InputWrap>
            <InputWrap>
              <PreviewTextArea
                label={t("walkingInstructions")}
                enablePreview={!state.dog.walkingInstructions}
                preview={t("walkingInstructionsDesc")}
              >
                <TextArea
                  rows={5}
                  placeholder={t("walkingInstructionsDesc")}
                  value={state.dog.walkingInstructions}
                  onChange={event => {
                    setState({
                      ...state,
                      dog: {
                        ...state.dog,
                        walkingInstructions: event.target.value
                      }
                    });
                  }}
                />
              </PreviewTextArea>
            </InputWrap>
            <InputWrap notLabeled label={t("selectDogActivityLevel")}>
              <DogActivityLevelSelector
                selected={state.dog.activityLevel}
                onSelect={selected =>
                  onSelectingSingle(selected, "activityLevel")
                }
              />
            </InputWrap>
            <InputWrap>
              <PreviewTextArea
                label={t("homeAloneInstructions")}
                enablePreview={!state.dog.homeAlone}
                preview={t("homeAloneInstructionsDesc")}
              >
                <TextArea
                  mt={2}
                  rows={5}
                  placeholder={t("homeAloneInstructionsDesc")}
                  value={state.dog.homeAlone}
                  onChange={event => {
                    setState({
                      ...state,
                      dog: {
                        ...state.dog,
                        homeAlone: event.target.value
                      }
                    });
                  }}
                />
              </PreviewTextArea>
            </InputWrap>
            <InputWrap>
              <PreviewTextArea
                label={t("indoorPrecautions")}
                enablePreview={!state.dog.indoorPrecautions}
                preview={t("indoorPrecautionsDesc")}
              >
                <TextArea
                  mt={2}
                  rows={5}
                  placeholder={t("indoorPrecautionsDesc")}
                  value={state.dog.indoorPrecautions}
                  onChange={event => {
                    setState({
                      ...state,
                      dog: {
                        ...state.dog,
                        indoorPrecautions: event.target.value
                      }
                    });
                  }}
                />
              </PreviewTextArea>
            </InputWrap>
          </Box>

          {!slimView && (
            <Box width={[1, 1, 3 / 10]}>
              <Box
                p={2}
                mt={2}
                sx={{
                  border: "default"
                }}
              >
                <Box>
                  <Icon name="info" color="pugYellow" fontSize={6}></Icon>
                </Box>
                <Box mt={2}>
                  <Heading as="h4" variant="highlight">
                    {t("whatInfoIsSharedQ")}
                  </Heading>
                  <Heading as="h4" mt={1}>
                    {t("whatInfoIsSharedA")}
                  </Heading>
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
        {displayControls && (
          <Box
            width={slimView ? 1 : [1, 1, 6 / 10]}
            mt={3}
            py={3}
            sx={{
              borderTop: "default"
            }}
          >
            <Flex
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row-reverse"
              }}
            >
              <Button variant="secondary" onClick={onSubmit}>
                {t("saveDog")}
              </Button>
              {dog.id && (
                <Text
                  variant="link"
                  sx={{
                    color: "springGreen",
                    fontSize: 3,
                    fontWeight: "book",
                    lineHeight: "body",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (confirm(t("confirmationAlert"))) {
                      deleteDogClick();
                    }
                  }}
                >
                  {t("deleteDog")}
                </Text>
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </ToastProvider>
  );
}

CreateOrUpdateDog.defaultProps = {
  slimView: false,
  triggerSave: false,
  displayControls: true
};

export default CreateOrUpdateDog;

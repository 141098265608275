import { transparentize } from "polished";
import useTranslation from "next-translate/useTranslation";

import { useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { Flex, Box, Image, Text } from "rebass/styled-components";

import Link from "./common/Link";
import theme from "../lib/theme";
import { CurrentUser } from "../interfaces";
import { LocaleContext } from "./locale";
import { logout as authLogout } from "../lib/auth";
import { Card, Icon, OutsideClickHandler } from "./common";
import { useAuthDispatch, useAuthState } from "./common/AuthContext";
import { useIsMobile } from "../lib/media";
import { UserType } from "../enums";
import { gql } from "apollo-boost";
import { useApolloClientWithResetCallback } from "../lib/apolloClient";

interface Props {
  currentUser: CurrentUser;
}

interface NavLinkProps {
  name: string;
  icon: string;
  href: string;
  notification?: number;
}

const settingsQuery = gql`
  query getSettings {
    settings {
      showSupportPhoneNumber
      supportPhoneNumber
    }
  }
`;

const NavLink = ({ name, icon, href, notification }: NavLinkProps) => {
  return (
    <Link href={href}>
      <Flex flexWrap="wrap" alignItems="center" pt={3}>
        <Box mx={2} fontSize={5} display="inherit">
          <Icon name={icon} fontSize={5} />
        </Box>
        <Text>{name}</Text>
        {!!notification && (
          <Box
            width="max-content"
            ml={1}
            px={1}
            sx={{
              color: "samoyedWhite",
              backgroundColor: "inuOrange",
              borderRadius: "circle"
            }}
          >
            {notification}
          </Box>
        )}
      </Flex>
    </Link>
  );
};

const UserMenu = ({ currentUser }: Props) => {
  const { t } = useTranslation("common");
  const client = useApolloClientWithResetCallback();
  const dispatch = useAuthDispatch();
  const { data: settingsData } = useQuery(settingsQuery);
  const { locale, country } = useContext(LocaleContext);
  const { userMenuVisibility: visibility, accountMode } = useAuthState();
  const isMobile = useIsMobile();

  const { billingProfile, canCreateABillingProfile } = currentUser || {
    billingProfile: null,
    canCreateABillingProfile: null
  };

  const close = e => {
    dispatch({ type: "hideUserMenu" });
  };

  const switchAccount = (override?: any) => {
    dispatch({
      type: "setAccountMode",
      payload: {
        accountMode:
          override ||
          (accountMode == UserType.CAREGIVER
            ? UserType.USER
            : UserType.CAREGIVER)
      }
    });
  };

  const logout = () => {
    authLogout({
      client: client,
      locale: locale
    });

    dispatch({ type: "hideLoggedInAs" });
  };

  //when accountType updated
  useEffect(() => {
    //first render after login
    if (!accountMode) switchAccount(currentUser.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountMode]);

  useEffect(() => {
    //making sure the body doesn't scroll when private area is opened
    document.body.style.overflow = visibility ? "hidden" : "initial";
  }, [visibility]);

  const color = accountMode == UserType.CAREGIVER ? "aquamarine" : "inuOrange";

  const openIntercom = () => {
    window.Intercom("show");
  };

  return (
    <Box
      data-test="private-area"
      sx={{
        position: "fixed",
        overflowY: ["scroll", "hidden"],
        transform: "translateZ(0)", //workaround for position fixed not scrolling on safari mobile
        top: 0,
        right: 0,
        width: visibility ? "100vw" : 0,
        height: visibility ? "100vh" : 0,
        opacity: visibility ? 1 : 0,
        zIndex: 10,
        backgroundColor: transparentize(0.05, theme.colors.samoyedWhite),
        transition: `opacity .25s ease-in-out ${
          !visibility ? ", width .0s .3s linear, height .0s .3s linear" : "" //assuring fade out
        }`,
        "a,a:visited": {
          textDecoration: "none",
          color: "initial",
          "&:hover": { color: color }
        }
      }}
    >
      <Flex
        flexWrap="wrap"
        flexDirection="row-reverse"
        sx={{ display: "none" }}
      >
        <Box width={1} pr={4}>
          <Flex flexDirection="row-reverse" alignItems="center" height={"65px"}>
            <Box sx={{ cursor: "pointer" }} mr={"4px"}>
              <Icon as="button" name="close" fontSize={0} />
            </Box>
          </Flex>
        </Box>
        {visibility && (
          <OutsideClickHandler onOutsideClick={close}>
            <Card
              sx={{
                minHeight: "calc(100vh - 65px)",
                borderRadius: "0 !important",
                borderWidth: ["0 !important", "1px"]
              }}
            >
              <Box minWidth={["initial", "400px"]} width={["100vw", "auto"]}>
                <Box
                  sx={{
                    backgroundColor: color,
                    padding: 2
                  }}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box color="samoyedWhite">
                      <Text as="p" sx={{ ...theme.text.h2 }}>
                        {t("greeting", {
                          name: currentUser.name || currentUser.email
                        })}
                      </Text>
                      <Text fontSize={1} as="span">
                        {t(
                          accountMode == UserType.CAREGIVER
                            ? "dogSitter"
                            : "dogOwner"
                        )}
                      </Text>
                    </Box>
                    <Box>
                      <Image
                        src={currentUser.avatar.circle}
                        alt={`${currentUser.name} avatar`}
                        width={40}
                        height={40}
                        variant="avatar"
                        mx={1}
                      />
                    </Box>
                  </Flex>
                </Box>
                {currentUser.type == UserType.CAREGIVER && (
                  <Box>
                    <Flex
                      onClick={() => {
                        switchAccount();
                      }}
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="space-between"
                      py={3}
                      pl={2}
                      sx={{
                        borderBottom: "default",
                        cursor: "pointer"
                      }}
                    >
                      <Text>
                        {accountMode == UserType.CAREGIVER
                          ? t("switchToOwner")
                          : t("switchToSitter")}
                      </Text>
                      <Box mr={4} fontSize={5} display="inherit">
                        <Icon name="switch" />
                      </Box>
                    </Flex>
                  </Box>
                )}
                <Box
                  minHeight={"100%"}
                  pb={["80px", 2]}
                  sx={{
                    "> * ": { cursor: "pointer", "&:hover": { color: color } }
                  }}
                >
                  {accountMode == UserType.CAREGIVER && (
                    <NavLink
                      href="/caregiver/home"
                      icon="user"
                      name={t("profile")}
                    />
                  )}
                  {accountMode != UserType.CAREGIVER && (
                    <NavLink
                      href="/user/edit"
                      icon="user"
                      name={t("profile")}
                    />
                  )}
                  {isMobile && (
                    <NavLink
                      href="/conversations"
                      icon="messages"
                      name={t("messages")}
                      notification={
                        accountMode == UserType.CAREGIVER
                          ? currentUser.notifications
                              ?.unreadMessagesCountCaregiver
                          : currentUser.notifications?.unreadMessagesCountUser
                      }
                    />
                  )}
                  {accountMode == UserType.USER && (
                    <NavLink
                      href="/user/bookings"
                      icon="bell"
                      name={t("bookings")}
                    />
                  )}

                  {accountMode == UserType.CAREGIVER && isMobile && (
                    <NavLink
                      href="/caregiver/calendar"
                      icon="calendar"
                      name={t("availability")}
                    />
                  )}
                  {accountMode == UserType.CAREGIVER && isMobile && (
                    <NavLink
                      href="/caregiver/bookings"
                      icon="bell"
                      name={t("bookings")}
                    />
                  )}

                  <NavLink href="/user/dogs" icon="dog" name={t("yourPack")} />

                  {accountMode != UserType.CAREGIVER && (
                    <NavLink
                      href="/favourites"
                      icon="heart-shape"
                      name={t("favouriteSitters")}
                    />
                  )}
                  {accountMode != UserType.CAREGIVER && (
                    <NavLink
                      href="/user/paymentmethods"
                      icon="payment"
                      name={t("paymentMethod")}
                    />
                  )}
                  {accountMode == UserType.CAREGIVER && (
                    <NavLink
                      href="/payoutpreferences"
                      icon="payment"
                      name={t("payoutMethod")}
                    />
                  )}
                  <NavLink
                    href="/user/settings"
                    icon="filter"
                    name={t("settings")}
                  />
                  {accountMode == UserType.CAREGIVER &&
                    canCreateABillingProfile &&
                    billingProfile &&
                    billingProfile.identityStatus == "not_uploaded" && (
                      <NavLink
                        href="/identity"
                        icon="license"
                        name={t("identity")}
                      />
                    )}
                  {accountMode != UserType.CAREGIVER && (
                    <NavLink
                      name={t("findDogSitters")}
                      href="/search"
                      icon="search"
                    />
                  )}

                  {accountMode == UserType.USER &&
                    t("common:phoneNumber") &&
                    settingsData &&
                    settingsData.settings.showSupportPhoneNumber && (
                      <Flex
                        flexWrap="wrap"
                        alignItems="center"
                        pt={3}
                        as="a"
                        href={`tel:${t("phoneNumber")}`}
                      >
                        <Box mx={2} fontSize={5} display="inherit">
                          <Icon name="phone" color={"labradorBlack"} />
                        </Box>
                        <Text>{t("phoneNumber")}</Text>
                      </Flex>
                    )}

                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    pt={3}
                    as="a"
                    href={process.env.help[country]}
                  >
                    <Box mx={2} fontSize={5} display="inherit">
                      <Icon name="gudog" color={"labradorBlack"} />
                    </Box>
                    <Text>{t("help")}</Text>
                  </Flex>

                  {isMobile && (
                    <Flex
                      flexWrap="wrap"
                      alignItems="center"
                      pt={3}
                      onClick={openIntercom}
                    >
                      <Box mx={2} fontSize={5} display="inherit">
                        <Icon name="intercom" color={"labradorBlack"} />
                      </Box>
                      <Text>{t("chat")}</Text>
                    </Flex>
                  )}

                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    pt={3}
                    onClick={() => logout()}
                    data-test="logout"
                  >
                    <Box mx={2} fontSize={5} display="inherit">
                      <Icon name="exit" />
                    </Box>
                    <Text>{t("logout")}</Text>
                  </Flex>
                </Box>
              </Box>
            </Card>
          </OutsideClickHandler>
        )}
      </Flex>
    </Box>
  );
};

export default UserMenu;

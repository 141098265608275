import { useEffect } from "react";
import { useFormikContext } from "formik";

interface Props {
  submitOnNextRender: boolean;
}

const AutoSubmit = ({ submitOnNextRender }: Props) => {
  // Grab values and submitForm from context
  const { submitForm } = useFormikContext();

  useEffect(() => {
    // Submit the form imperatively as an effect as soon as submitOnNextRender is true
    if (submitOnNextRender) {
      submitForm();
    }
  }, [submitForm, submitOnNextRender]);
  return null;
};

export default AutoSubmit;

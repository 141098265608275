import { Box } from "rebass/styled-components";
import { ErrorProp, Input } from "..";
import useTranslation from "next-translate/useTranslation";

import { Label } from "@rebass/forms/styled-components";

interface Props {
  name: string;
  value: string;
  onChange: (any) => void;
  error?: ErrorProp;
  type?: string;
}

function DogInput({ name, value, type, onChange, error }: Props) {
  const { t } = useTranslation("common");

  return (
    <Box>
      <Label
        mb={1}
        sx={{
          color: error?.hasError ? "errorRed" : "labradorBlack"
        }}
      >
        {t(name)}
      </Label>
      <Input
        name={name}
        placeholder={t(name)}
        key={name}
        type={type}
        value={value}
        onChange={onChange.bind(this)}
        sx={{
          borderColor: error?.hasError ? "errorRed" : "gray"
        }}
      />
      {error?.hasError && (
        <Label
          sx={{
            color: "errorRed"
          }}
        >
          {t(error.message)}
        </Label>
      )}
    </Box>
  );
}

DogInput.defaultProps = {
  type: "text"
};

export default DogInput;

import React, { useContext } from "react";
import load from "little-loader";
import { LocaleContext } from "../locale";

interface GooglePlacesContextType {
  isInProvider: boolean;
  googlePlaces: typeof google.maps.places | undefined;
  autocompleteService: google.maps.places.AutocompleteService | undefined;
}

export const GooglePlacesContext = React.createContext<GooglePlacesContextType>(
  {
    isInProvider: false,
    googlePlaces: undefined,
    autocompleteService: undefined
  }
);

interface ProviderProps {
  apiKey: string;
  children: React.ReactNode;
}

export const GooglePlacesProvider = ({ apiKey, children }: ProviderProps) => {
  const existingContext = useContext(GooglePlacesContext);
  const { locale } = useContext(LocaleContext);

  const [context, setContext] = React.useState<GooglePlacesContextType>({
    googlePlaces: existingContext.googlePlaces,
    autocompleteService: existingContext.autocompleteService,
    isInProvider: true
  });

  React.useEffect(() => {
    if (!existingContext.isInProvider) {
      load(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${locale}`,
        () => {
          setContext({
            googlePlaces: google.maps.places,
            isInProvider: true,
            autocompleteService: new google.maps.places.AutocompleteService()
          });
        }
      );
    }
  }, [apiKey, locale, existingContext.isInProvider]);

  return (
    <GooglePlacesContext.Provider value={context}>
      {children}
    </GooglePlacesContext.Provider>
  );
};

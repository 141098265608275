import { Box, Flex } from "rebass/styled-components";
import { Select, ErrorProp } from "..";
import useTranslation from "next-translate/useTranslation";

import { useState } from "react";

interface Props {
  selected?: Date;
  onSelect: (any) => void;
  error: ErrorProp;
}

function DogBirthdaySelector({ selected, onSelect, error }: Props) {
  const { t } = useTranslation("common");

  const months = [
    {
      value: 0,
      label: t("month_1")
    },
    {
      value: 1,
      label: t("month_2")
    },
    {
      value: 2,
      label: t("month_3")
    },
    {
      value: 3,
      label: t("month_4")
    },
    {
      value: 4,
      label: t("month_5")
    },
    {
      value: 5,
      label: t("month_6")
    },
    {
      value: 6,
      label: t("month_7")
    },
    {
      value: 7,
      label: t("month_8")
    },
    {
      value: 8,
      label: t("month_9")
    },
    {
      value: 9,
      label: t("month_10")
    },
    {
      value: 10,
      label: t("month_11")
    },
    {
      value: 11,
      label: t("month_12")
    }
  ];

  let years = [];
  let yearEnd = new Date().getUTCFullYear();
  for (let i = 0; i < 30; i++) {
    let year = yearEnd - i;
    years.push({
      value: year,
      label: year
    });
  }

  const [state, dispatch] = useState({
    month: selected
      ? months.filter(value => {
          return value.value == selected.getMonth();
        })[0]
      : null,
    year: selected
      ? years.filter(value => {
          return value.value == selected.getUTCFullYear();
        })[0]
      : null
  });

  const { month, year } = state;

  const onChange = payload => {
    const { month, year } = payload;
    if (month && year) {
      const birthday = new Date();
      birthday.setDate(1);
      birthday.setMonth(month.value);
      birthday.setUTCFullYear(year.value);
      onSelect({ value: birthday });
    }
    dispatch(payload);
  };

  return (
    <Flex>
      <Box className="dogBirthday" width={1}>
        <Select
          id="dog_birthday_month"
          name="dog_birthday_month"
          value={month}
          placeholder={t("month")}
          onChange={month => {
            onChange({
              ...state,
              month: month
            });
          }}
          options={months}
          error={error}
          sx={{
            borderRadius: "4px 0 0 4px",
            borderRight: "none"
          }}
        ></Select>
      </Box>
      <Box className="dogBirthday" width={1}>
        <Select
          id="dog_birthday_year"
          name="dog_birthday_year"
          value={year}
          placeholder={t("year")}
          onChange={year => {
            onChange({
              ...state,
              year: year
            });
          }}
          options={years}
          error={error}
          sx={{
            borderRadius: "0 4px 4px 0"
          }}
        ></Select>
      </Box>
    </Flex>
  );
}

export default DogBirthdaySelector;

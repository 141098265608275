import { Flex, Box } from "rebass/styled-components";
import { useContext, useEffect, useState, useRef } from "react";
import { GooglePlacesContext } from "../google-places/GooglePlacesProvider";
import { Icon } from "../common";
import { Place } from "../../interfaces";
import useTranslation from "next-translate/useTranslation";

import SearchNearbySelector from "./SearchNearbySelector";
import { LocaleContext } from "../locale";
import useDebounce from "../../lib/use-debounce";

interface Props {
  input?: string;
  onSelect: (place: Place) => void;
  country?: string;
  showSearchNearby?: boolean;
  filterByCity?: boolean;
}

function PlaceSelector({
  input,
  onSelect,
  country,
  showSearchNearby,
  filterByCity
}: Props) {
  const { t } = useTranslation("common");
  const localeContext = useContext(LocaleContext);
  const { autocompleteService, googlePlaces } = useContext(GooglePlacesContext);
  const [results, setResults] = useState([]);
  const [placesService, setPlacesService] = useState<
    google.maps.places.PlacesService
  >();

  let attributionRef = useRef<HTMLDivElement>(null);
  const handleResultSelect = result => {
    placesService.getDetails(
      { placeId: result.place_id, fields: ["geometry.location"] },
      place => {
        onSelect({
          pid: result.place_id,
          near: result.title,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
      }
    );
  };

  useEffect(() => {
    if (googlePlaces && attributionRef.current) {
      setPlacesService(new googlePlaces.PlacesService(attributionRef.current!));
    }
  }, [googlePlaces, attributionRef]);

  const debouncedInput = useDebounce(input, 500);

  useEffect(() => {
    if (autocompleteService && debouncedInput?.length > 2) {
      autocompleteService.getPlacePredictions(
        {
          input: debouncedInput,
          types: filterByCity ? ["(cities)"] : ["geocode"],
          // TODO: Change country here
          componentRestrictions: { country: country || localeContext.country }
        },
        (
          predictions: google.maps.places.AutocompletePrediction[],
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setResults(
              predictions.map(prediction => {
                return {
                  // key: prediction.id,
                  place_id: prediction.place_id,
                  title: prediction.structured_formatting.main_text,
                  description: prediction.structured_formatting.secondary_text,
                  source: prediction
                };
              })
            );
          }
        }
      );
    }
  }, [autocompleteService, debouncedInput, country, localeContext]);

  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: "absolute",
        top: "100%",
        width: "100%",
        px: 2,
        py: 3,
        mt: -1,
        zIndex: 1,
        bg: "background",
        borderLeft: "default",
        borderRight: "default",
        borderBottom: "default",
        borderBottomLeftRadius: "default",
        borderBottomRightRadius: "default"
      }}
    >
      {showSearchNearby && <SearchNearbySelector onSelect={onSelect} />}
      {!!results &&
        results.map(result => {
          return (
            <Flex
              key={result.place_id}
              alignItems="center"
              my={1}
              sx={{
                color: "labradorBlack",
                cursor: "pointer",
                ".ellipsis": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                },
                svg: {
                  flexShrink: 0
                }
              }}
              onClick={() => handleResultSelect(result)}
            >
              <Icon name="marker" />
              <Box ml={1} className="ellipsis">
                {result.title}
              </Box>

              <Box className="ellipsis" fontSize={0} color="darkGray" ml={1}>
                {result.description}
              </Box>
            </Flex>
          );
        })}
      <div ref={attributionRef} />
    </Flex>
  );
}

export default PlaceSelector;

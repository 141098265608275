import { Flex, Box, Text, Image } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import { Heading, Icon } from "./common";
import { useContext } from "react";
import publicRuntimeConfig from "../lib/public-runtime-config";
import { LocaleContext } from "./locale";
import Logo from "./common/Logo";
import Link from "./common/Link";
import Trans from "next-translate/Trans";
import Areas from "../components/home/Areas";

interface Props {
  isMobile?: boolean;
}

const Footer = ({ isMobile }: Props) => {
  const { locale, country } = useContext(LocaleContext);
  const { t } = useTranslation("footer");

  const language = (locale ?? "en").substring(0, 2);
  const hrefLangAlternate = publicRuntimeConfig.hrefLangAlternate as any;

  return (
    <Box as="footer" width="100%">
      <Areas isDesktop={!isMobile} />

      <Flex
        flexWrap="wrap"
        sx={{
          backgroundColor: "prusianBlue",
          color: "samoyedWhite",
          position: "absolute",
          top: "100%"
        }}
        px={[3, 3, 100]}
        py={[5, 5, 8]}
        width={1}
      >
        <Box width={[1, 1 / 5, 1 / 5]} pb={[3, 3, 0]}>
          <Heading as="p" variant="highlight" mb={1} sx={{ fontSize: "16px" }}>
            {t("gudog")}
          </Heading>

          <Link href="/ourservices">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("ourServices")}
            </Text>
          </Link>

          <Link href="/howitworks">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("howItWorks")}
            </Text>
          </Link>

          <Link href="/about">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("aboutGudog")}
            </Text>
          </Link>

          <Link href="/testimonials">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("testimonials")}
            </Text>
          </Link>

          <Link href="/veterinarycover">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("veterinaryCover")}
            </Text>
          </Link>

          <Link href="/adviceforowners">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("tipsForDogOwners")}
            </Text>
          </Link>

          <Link href="/adviceforsitters">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("tipsForDogSitters")}
            </Text>
          </Link>

          <Link href="/recruitingsitters">
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer"
              }}
            >
              {t("becomeDogSitter")}
            </Text>
          </Link>

          <a
            href={process.env.blog[country]}
            style={{
              textDecoration: "none"
            }}
          >
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer",
                color: "samoyedWhite"
              }}
            >
              {t("blog")}
            </Text>
          </a>

          <a
            href={process.env.help[country]}
            style={{
              textDecoration: "none"
            }}
          >
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer",
                color: "samoyedWhite"
              }}
            >
              {t("help")}
            </Text>
          </a>

          <a
            href={process.env.contact[country]}
            style={{
              textDecoration: "none"
            }}
          >
            <Text
              as="p"
              py={1}
              sx={{
                cursor: "pointer",
                color: "samoyedWhite"
              }}
            >
              {t("contact")}
            </Text>
          </a>
        </Box>
        <Box width={[1, 1 / 5, 1 / 5]} pb={[3, 3, 0]}>
          <Heading as="p" variant="highlight" mb={1} sx={{ fontSize: "16px" }}>
            {t("services")}
          </Heading>
          <Link
            href={{
              pathname: "/search/service",
              query: {
                service: process.env.serviceRoutes[country][
                  "dog-daycare"
                ].toString()
              }
            }}
          >
            <Text py={1}>{t("dogDayCare")}</Text>
          </Link>
          <Link
            href={{
              pathname: "/search/service",
              query: {
                service: process.env.serviceRoutes[country][
                  "dog-walking"
                ].toString()
              }
            }}
          >
            <Text py={1}>{t("dogWalking")}</Text>
          </Link>
          <Link
            href={{
              pathname: "/search/service",
              query: {
                service: process.env.serviceRoutes[country][
                  "dog-boarding"
                ].toString()
              }
            }}
          >
            <Text py={1}>{t("boarding")}</Text>
          </Link>

          {(country === "dk" || country === "no" || country === "se") && (
            <Link
              href={{
                pathname: "/search/service",
                query: {
                  service: process.env.serviceRoutes[country][
                    "house-sitting"
                  ].toString()
                }
              }}
            >
              <Text py={1}>{t("house-sitting")}</Text>
            </Link>
          )}
        </Box>
        <Box width={[1, 1 / 5, 1 / 5]} pb={[3, 3, 0]}>
          <Heading as="p" variant="highlight" mb={1} sx={{ fontSize: "16px" }}>
            {t("countries")}
          </Heading>
          {hrefLangAlternate.map((hrefLang, index) => {
            return (
              hrefLang.country &&
              country != hrefLang.country && (
                <a
                  key={index}
                  href={hrefLang.href}
                  hrefLang={hrefLang.locale}
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Text
                    as="p"
                    py={1}
                    sx={{
                      color: "samoyedWhite"
                    }}
                  >
                    {t(hrefLang.locale)}
                  </Text>
                </a>
              )
            );
          })}
        </Box>
        <Box width={[1, 1 / 5, 1 / 5]} pb={[3, 3, 0]}>
          <Link href="/terms" rel="nofollow">
            <Text mb={1}>{t("tAndC")}</Text>
          </Link>
          <Link href="/privacy" rel="nofollow">
            <Text py={1}>{t("privacyPolicy")}</Text>
          </Link>
          <Flex width={1} py={1}>
            <Box>
              <a
                href={t("instagramUrl")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon name="instagram" color="samoyedWhite" fontSize={5}></Icon>
              </a>
            </Box>
            <Box px={2}>
              <a
                href={t("facebookUrl")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon name="facebook" color="samoyedWhite" fontSize={5}></Icon>
              </a>
            </Box>
            <Box>
              <a
                href={t("twitterUrl")}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon name="twitter" color="samoyedWhite" fontSize={5}></Icon>
              </a>
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1 / 5, 1 / 5]} pb={[2, 2, 0]}>
          <Text
            as="p"
            variant="highlight"
            mb={1}
            sx={{ fontSize: "16px", fontWeight: "bold" }}
          >
            {t("downloadOurApp")}
          </Text>
          <Box width={1} pt={1}>
            <a
              href="https://play.google.com/store/apps/details?id=com.gudog.app"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                alt="Gudog - Google Play"
                src={`/images/home/${language}/google_play.svg`}
                width={"auto"}
                height={"49.5px"}
              />
            </a>
          </Box>

          <Box width={1}>
            <a
              href={`https://apps.apple.com/${country}/app/gudog/id964470204`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                alt="Gudog - App Store"
                src={`/images/home/${language}/app_store.svg`}
                width={"auto"}
                height={"49.5px"}
              />
            </a>
          </Box>
        </Box>
        <Box
          width={1}
          height={1}
          mt={2}
          mb={2}
          sx={{
            backgroundColor: "#DDDDDD"
          }}
        ></Box>
        <Box width={1 / 2}>
          <Logo
            color={"samoyedWhite"}
            height={["16", "16px", "16px"]}
            width={["54px", "54px", "54px"]}
            name={"logo"}
          ></Logo>
        </Box>
        <Box
          width={1 / 2}
          sx={{
            float: "right"
          }}
        >
          <Text
            sx={{
              color: "darkGray",
              float: "right",
              fontSize: 1,
              letterSpacing: "body"
            }}
          >
            {t("copyright", { year: new Date().getFullYear() })}
          </Text>
        </Box>
        <Box mt={1}>
          <Text
            sx={{ fontSize: 0, color: "darkGray", a: { color: "darkGray" } }}
          >
            <Trans
              i18nKey="footer:recaptcha"
              components={[
                <a
                  key={1}
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                />,
                <a
                  key={2}
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer noopener"
                />
              ]}
            />
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;

import styled from "styled-components";
import { Box, Text } from "rebass/styled-components";
import { useState } from "react";
import Carousel, { ModalGateway, Modal } from "react-images";
import { Icon } from "../../common";
import Trans from "next-translate/Trans";

const modalStyles = {
  positioner: base => ({
    ...base,
    zIndex: 5
  }),
  blanket: base => ({
    ...base,
    zIndex: 5,
    backgroundColor: "rgba(255,255,255, 0.9)"
  })
};

const carouselStyles = {
  header: base => ({
    ...base,
    background: "none!important"
  }),
  headerClose: base => ({
    ...base,
    color: "#000",
    ":hover": { color: "#000" }
  }),
  headerFullscreen: base => ({
    ...base,
    color: "#000",
    ":hover": { color: "#000" }
  }),
  view: base => ({
    ...base,
    backgroundColor: "none"
  }),
  pager: base => ({
    ...base,
    backgroundColor: "none"
  }),
  footer: base => ({
    ...base,
    background: "none!important",
    color: "#666"
  })
};

const NavigationButton = styled.span`
  position: absolute;
  top: 50%;
  display: block;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii.circle}px;
  width: 48px;
  height: 48px;
  font-size: 1em;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.colors.springGreen};
  border: 1px solid ${({ theme }) => theme.colors.springGreen};
  margin: -25px 0 0 0;
  :first-of-type {
    left: ${({ theme }) => theme.space[3]}px;
  }
  :last-of-type {
    right: ${({ theme }) => theme.space[3]}px;
  }
`;

const NavigationPrev = ({ innerProps, isModal }: any) => (
  <>
    <NavigationButton {...innerProps}>
      <Icon name="prev" color="springGreen" />
    </NavigationButton>
  </>
);

const NavigationNext = ({ innerProps, isModal }: any) => (
  <>
    <NavigationButton {...innerProps}>
      <Icon name="next" color="springGreen" />
    </NavigationButton>
  </>
);

interface Photo {
  slider?: string;
  thumb?: string;
  attachment?: string;
}

interface Props {
  limit: number;
  photos: Photo[];
  onClick: () => void;
}

const Gallery = (props: Props) => {
  const { photos, limit, onClick } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const photosForCarousel = photos.map(p => ({
    source: {
      regular: p.slider || p.attachment,
      fullscreen: p.slider || p.attachment
    }
  }));

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        height: [`calc(61.33vw + ${(100 / 3) * 0.64}vw)`, "auto"]
      }}
    >
      {photos.map((photo, index) => {
        return index < limit ? (
          <Box
            key={index}
            sx={{
              float: "left",
              position: "relative",
              paddingTop: [`${(100 / 3) * 0.64}%`, `${100 / 6.7}%`],
              cursor: "pointer",
              backgroundImage: `url(${
                index === 0
                  ? photo.slider || photo.attachment
                  : photo.thumb || photo.attachment
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: [`${100 / 3}%`, "25%"],
              ":first-of-type": {
                paddingTop: ["61.33vw", `${100 / 3.35}%`],
                width: ["100%", "50%"]
              }
            }}
            onClick={() => {
              setSelectedIndex(index);
              setLightboxIsOpen(!lightboxIsOpen);
            }}
          ></Box>
        ) : null;
      })}
      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal
            allowFullscreen={false}
            styles={modalStyles}
            onClose={() => {
              setSelectedIndex(0);
              setLightboxIsOpen(!lightboxIsOpen);
            }}
          >
            <Carousel
              styles={carouselStyles}
              currentIndex={selectedIndex}
              views={photosForCarousel}
              components={{
                NavigationPrev: NavigationPrev,
                NavigationNext: NavigationNext
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            paddingTop: [`${(100 / 3) * 0.64}%`, `${100 / 6.7}%`],
            backgroundColor: "rgba(0,0,0,0.8)",
            cursor: "pointer",
            width: [
              0, //`${100/3}%`,
              "25%"
            ]
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: [1, 2],
              left: 0,
              right: 0
            }}
          >
            <Text fontSize={2} color="samoyedWhite" textAlign="center">
              <Trans values={{ total: photos.length }} i18nKey="common:photos"/>
            </Text>
            <Text
              sx={{
                color: "springGreen",
                fontSize: 3,
                fontWeight: "book",
                textAlign: "center"
              }}
            >
              <Trans i18nKey="common:editPhotos"/>
            </Text>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            paddingTop: [`${(100 / 3) * 0.64}%`, `${100 / 6.7}%`],
            backgroundColor: "rgba(0,0,0,0.8)",
            cursor: "pointer",
            width: [`${100 / 3}%`, 0]
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: [1, 2],
              left: 0,
              right: 0
            }}
          >
            <Text fontSize={2} color="samoyedWhite" textAlign="center">
              <Trans values={{ total: photos.length }} i18nKey="common:photos"/>
            </Text>
            <Text
              sx={{
                color: "springGreen",
                fontSize: 3,
                fontWeight: "book",
                textAlign: "center"
              }}
            >
              <Trans i18nKey="common:editPhotos"/>
            </Text>
          </Box>
        </Box>
      </>
    </Box>
  );
};

Gallery.defaultProps = {
  limit: 5
};

export default Gallery;

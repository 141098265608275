import { useEffect, useRef } from "react";
import { Flex, Box } from "rebass/styled-components";

import { createPortal } from "react-dom";
import { Loader } from ".";

const root = typeof window === "undefined" ? null : document.body;

export const LoadingWrapper = props => {
  const { children } = props;

  const elem = document.createElement("div");
  const rootElemRef = useRef(elem);

  useEffect(function setupElement() {}, []);

  if (!root) {
    return null;
  }

  return createPortal(children, root);
};

const FullLoader = props => {
  return (
    root && (
      <LoadingWrapper>
        <Loader
          {...props}
          sx={{
            position: "fixed",
            zIndex: "2147483647"
          }}
        ></Loader>
      </LoadingWrapper>
    )
  );
};

export default FullLoader;

import { Box } from "rebass/styled-components";
import { Button, Icon, Heading } from "..";

interface Item {
  value: string;
  iconName?: string;
  label: string;
  fontSize?: number;
}

interface MultipleSelection {
  [x: string]: boolean;
}

interface Props {
  items: Item[];
  selected: string | MultipleSelection;
  multiple: boolean;

  onSelect: (value: string) => void;
}

const ButtonSelector = ({ items, selected, multiple, onSelect }: Props) => {
  return (
    <>
      {items.map(item => {
        const isSelected =
          multiple && typeof selected !== "string"
            ? selected[item.value]
            : selected && item.value == selected;
        return (
          <Button
            key={item.value}
            variant="terciary"
            mx="4px"
            sx={{
              border: "1px solid",
              borderColor: isSelected ? "springGreen" : "lightGray",
              backgroundColor: isSelected ? "springGreen" : "lightGray",
              position: "relative",
              flex: "1 1 auto",
              minHeight: 80,
              ":hover": {
                borderColor: "springGreen"
              },
              ":first-of-type": {
                ml: 0
              },
              ":last-of-type": {
                mr: 0
              }
            }}
            onClick={(e: Event) => {
              e.preventDefault();
              onSelect(item.value);
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: item.iconName ? 16 : 30,
                left: 0,
                right: 0
              }}
            >
              {!!item?.iconName && (
                <Icon
                  name={item.iconName}
                  fontSize={item.fontSize}
                  width={"100%"}
                  color={isSelected ? "samoyedWhite" : "darkGray"}
                />
              )}
              <Heading
                as="h5"
                width={"max-content"}
                m="auto"
                sx={{
                  fontWeight: !item?.iconName ? "book" : "body",
                  letterSpacing: "body",
                  color: isSelected ? "samoyedWhite" : "darkGray"
                }}
              >
                {item.label}
              </Heading>
            </Box>
          </Button>
        );
      })}
    </>
  );
};

ButtonSelector.defaultProps = {
  multiple: false
};

export default ButtonSelector;

import { fontFace, lighten } from "polished";

const colors = {
  samoyedWhite: "#ffffff",
  pugYellow: "#F9B342",
  inuOrange: "#ED553B",
  mongrelBrown: "#5B2B2F",
  labradorBlack: "#010101",
  springGreen: "#3CB4A0",
  aquamarine: "#1F8C8D",
  prusianBlue: "#033243",
  lightGray: "#EBEBEB",
  gray: "#DDDDDD",
  darkGray: "#9A9A9A",
  errorRed: "#B31412",
  veryDarkGray: "#181818",
  successGreen: "#6DD400"
};

//Alias
colors.primary = colors.inuOrange;
colors.black = colors.labradorBlack;
colors.background = colors.samoyedWhite;

// Default breakpoints borrwed from Bootstrap
// https://getbootstrap.com/docs/4.3/layout/overview/
export const breakpoints = {
  small: 576, // mobile devices
  medium: 768, // ipad, ipad pro, ipad mini, etc
  large: 992, // smaller laptops
  xlarge: 1200 // laptops and desktops
};

const theme = {
  breakpoints: Object.values(breakpoints).map(val => `${val}px`),
  colors: { ...colors },
  fonts: {
    body: "Geomanist",
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    // Font-face mapping for weights
    book: 300,
    regular: 400,
    medium: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 96],
  sizes: {
    avatar: 48,
    thumb: 56,
    carousel: 64,
    avatarSmallMobile: 24,
    avararSmallDesktop: 32
  },
  radii: {
    default: 4,
    double: 8,
    triple: 16,
    circle: 99999,
    paginationPrev: "50% 0 0 50%",
    paginationNext: "0 50% 50% 0"
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)",
    inputFocus: "0 0 25px 0 rgba(38,55,70,0.1)"
  },
  borders: {
    default: "1px solid #DDDDDD",
    double: "2px solid #DDDDDD"
  },
  letterSpacing: {
    body: "0.5px"
  },
  // rebass variants
  text: {
    h1: {
      fontWeight: "medium",
      fontSize: "40px",
      lineHeight: 48 / 40
    },
    h2: {
      fontWeight: "medium",
      fontSize: "30px",
      lineHeight: 42 / 32
    },
    h3: {
      fontWeight: "book",
      fontSize: "24px",
      lineHeight: 24 / 24
    },
    h4_highlight: {
      fontWeight: "medium",
      fontSize: "16px",
      lineHeight: 24 / 16
    },
    h4: {
      fontWeight: "regular",
      fontSize: "16px",
      lineHeight: 24 / 16
    },
    h5: {
      fontWeight: "regular",
      fontSize: "14px",
      lineHeight: 16 / 14
    },
    note: {
      fontWeight: "regular",
      fontSize: "8px",
      lineHeight: 8 / 8
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading"
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    }
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle"
    },
    avatarCarousel: {
      width: "carousel",
      height: "carousel",
      borderRadius: "circle"
    },
    avatarSitterProfile: {
      width: ["80px", "152px"],
      height: ["80px", "152px"],
      borderRadius: "circle"
    },
    avatarDogProfile: {
      width: "152px",
      height: "152px",
      borderRadius: "circle"
    },
    avatarLarge: {
      width: "64px",
      height: "64px"
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card"
    },
    link: {
      color: "primary",
      textDecoration: "none"
    },
    secondaryLink: {
      color: "springGreen",
      fontWeight: "book",
      textDecoration: "none"
    },
    nav: {
      fontSize: 2,
      fontWeight: "bold",
      display: "inline-block",
      color: "inherit",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "primary"
      }
    },
    error: {
      color: "errorRed",
      borderColor: "errorRed",
      ":hover,:focus": { color: "errorRed", borderColor: "errorRed" },
      "*:not(button)": {
        color: "errorRed",
        borderColor: "errorRed",
        ":hover,:focus": { color: "errorRed", borderColor: "errorRed" }
      }
    },
    layout: {
      large: {
        px: [2, 2, 4, "8.33333vw"]
      },
      medium: {
        px: [2, 2, 8, "25vw"]
      },
      small: {
        px: [2, 2, "25vw", "33.33333vw"]
      },
      sitterProfile: {
        px: [2, 2, 4, "16.66666vw"]
      },
      dashboard: {
        py: [2, 2, 4],
        px: [2, 2, 4, "8.33333vw"],
        mx: "auto"
      },
      dashboardFull: {
        py: [2, 2, 4],
        px: [2, 2, 3, 3],
        mx: "auto"
      },
      caregiverCalendar: {
        pb: [2, 2, 3, 3],
        mx: "auto"
      }
    },
    box: {
      mx: "8.33333vw",
      background: "white",
      border: ["none", "1px solid"],
      borderColor: ["inherit", "gray"],
      borderRadius: "4px",
      boxShadow: ["0", "inputFocus", "inputFocus"]
    },
    borderedBox: {
      background: "samoyedWhite",
      border: "1px solid",
      borderColor: "gray",
      borderRadius: "double"
    },
    searchBox: {
      mx: "8.33333vw",
      background: "white",
      border: ["none", "1px solid"],
      borderColor: ["inherit", "gray"],
      borderRadius: "4px",
      boxShadow: ["0", "inputFocus", "inputFocus"],
      maxWidth: [0, 0, "50%", "40%", "34%"],
      p: 4,
      py: 3,
      mt: 6,
      // mb: 4,
      flexDirection: "column"
    },
    searchBoxWithBanner: {
      mb: 0,
      borderRadius: "4px 4px 0 0"
    },
    bannerFlex: {
      flexDirection: "column",
      flexGrow: 1
    },
    searchBanner: {
      mx: "8.33333vw",
      borderRadius: "0 0 4px 4px",
      boxShadow: ["0", "inputFocus", "inputFocus"],
      maxWidth: [0, 0, "50%", "40%", "34%"],
      backgroundColor: "pugYellow",
      px: 4,
      py: 3,
      color: "white",
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "body"
    },
    topBanner: {
      flexDirection: "row",
      py: 3,
      px: 8,
      backgroundColor: "pugYellow",
      color: "samoyedWhite",
      fontSize: "20px",
      lineHeight: "25px",
      fontWeight: "body"
    },
    topBannerMobile: {
      flexDirection: "column",
      py: 2,
      px: 2,
      mr: 0,
      fontSize: "14px",
      lineHeight: "18px",
      width: "100%"
    },
    topBannerImgBox: {
      minWidth: "142px",
      margin: "auto",
      flexGrow: 1
    },
    topBannerImgBoxMobile: {
      minWidth: 0,
      width: "110px",
      mb: "8px"
    },
    ratingStar: {
      svg: {
        fontSize: 2,
        mr: "2px !important",
        ml: "2px !important",
        ":first-of-type": {
          mr: "2px !important",
          ml: "0px !important"
        },
        ":last-of-type": {
          mr: "0px !important",
          ml: "2px !important"
        }
      }
    },
    serviceIcon: {
      home: {
        bg: "transparent",
        border: "2px solid",
        borderColor: "springGreen",
        borderRadius: "40px",
        width: "96px",
        height: "96px",
        textAlign: "center"
      },
      landing: {
        p: "14px",
        bg: "transparent",
        border: "1px solid",
        borderColor: "labradorBlack",
        borderRadius: "24px",
        width: "79px",
        height: "79px",
        textAlign: "center"
      }
    },
    hero: {
      default: {
        position: "relative",
        height: [240, 240, 648]
      },
      home: {
        position: "relative",
        height: [246, 246, "calc(100vh - 128px)"],
        minHeight: [246, 246, 700]
      },
      landing: {
        position: "relative"
      },
      flexChildren: {
        width: "100%",
        zIndex: 2,
        position: "relative",
        alignItems: "center"
      },
      banner: {
        position: "relative",
        height: [400, 400, 866]
      }
    },
    addNew: {
      display: "block",
      cursor: "pointer",
      borderRadius: "50%",
      width: "48px",
      height: "48px",
      fontSize: "1em",
      lineHeight: "48px",
      textAlign: "center",
      color: "gray",
      border: "1px solid",
      borderColor: "springGreen",
      m: 0
    },
    badge: {
      display: "inline-block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "darkGray",
      fontSize: 1,
      border: "default",
      borderRadius: "default",
      height: "24px",
      px: 1,
      svg: {
        my: "auto !important",
        mr: "8px !important"
      }
    },
    caregiverReviewCard: {
      px: 2,
      py: 2,
      border: "default",
      borderRadius: "default"
    },
    caregiverReviewCardDesktop: {
      variant: "variants.caregiverReviewCard",
      "::before": {
        border: "8px solid",
        borderColor: "transparent #DDDDDD #DDDDDD transparent",
        content: `" "`,
        position: "absolute",
        top: 4,
        left: -2
      },
      "::after": {
        border: "7px solid",
        borderColor: "transparent  #ffffff  #ffffff transparent",
        content: `" "`,
        position: "absolute",
        top: "33px",
        left: "-14px"
      }
    },
    caregiverReviewCardMobile: {
      variant: "variants.caregiverReviewCard",
      "::before": {
        border: "8px solid",
        borderColor: "#DDDDDD transparent transparent  #DDDDDD",
        content: `" "`,
        position: "absolute",
        right: 1,
        bottom: -2
      },
      "::after": {
        border: "7px solid",
        borderColor: "#ffffff  transparent  transparent #ffffff",
        content: `" "`,
        position: "absolute",
        right: "9px",
        bottom: "-14px"
      }
    },
    dogOberservationCard: {
      bg: "samoyedWhite",
      border: "1px solid",
      borderColor: "lightGray",
      borderRadius: "4px 4px 4px 4px",
      flexGrow: 1,
      position: "relative",
      px: "2",
      py: "1",
      "&.withTail": {
        "::before": {
          border: "8px solid",
          borderColor: "#EBEBEB transparent transparent #EBEBEB",
          content: `" "`,
          position: "absolute",
          left: "24px",
          bottom: "-16px"
        },
        "::after": {
          border: "7px solid",
          borderColor: "#ffffff transparent transparent #ffffff",
          content: `" "`,
          position: "absolute",
          left: "25px",
          bottom: "-14px"
        }
      }
    },
    round: {
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      justifyContent: "center",
      alignItems: "center"
    },
    dogHair: {
      background: "url('/images/patterns/dog-hair.svg')",
      backgroundRepeat: "repeat",
      backgroundSize: [0, 0, "200px"]
    },
    messageForm: {
      bg: "lightGray",
      border: "1px solid",
      borderColor: "lightGray",
      borderRadius: "24px 24px 24px 24px",
      color: "darkGray",
      alignItems: "center",
      flex: "1",
      mr: "4px"
    },
    sendMessageButton: {
      backgroundColor: "springGreen",
      borderRadius: "50%",
      width: "56px",
      height: "56px",
      alignItems: "center",
      justifyContent: "center"
    },
    plusButton: {
      display: "block",
      cursor: "pointer",
      borderRadius: "circle",
      width: "48px",
      height: "48px",
      fontSize: 1,
      lineHeight: "48px",
      textAlign: "center",
      color: "gray",
      border: "default",
      borderColor: "springGreen",
      m: 0
    }
  },
  buttons: {
    large: {
      fontSize: "18px",
      lineHeight: "24px",
      px: 2,
      py: 0,
      minHeight: "48px"
    },
    medium: {
      fontSize: "18px",
      lineHeight: "24px",
      px: 2,
      py: 0,
      minHeight: "40px"
    },
    small: {
      fontSize: "18px",
      lineHeight: "24px",
      borderRadius: "dobule",
      minHeight: "24px",
      px: 2,
      py: 0
    },
    primary: {
      cursor: "pointer",
      fontWeight: "bold",
      color: "background",
      bg: "primary",
      borderRadius: "default",
      ":hover": {
        bg: lighten(0.05, colors.primary)
      },
      ":focus": {
        outline: "none"
      },
      ":disabled": {
        bg: lighten(0.2, colors.primary)
      }
    },
    secondary: {
      variant: "buttons.primary", //  Inherits from prev variant
      bg: "springGreen",
      ":hover": {
        bg: lighten(0.2, colors.springGreen) // TODO add transparency
      },
      ":disabled": {
        bg: lighten(0.2, colors.springGreen)
      }
    },
    terciary: {
      variant: "buttons.primary",
      color: "black",
      bg: "transparent",
      border: "default",
      ":hover": {
        borderColor: "black"
      },
      ":disabled": {
        color: lighten(0.2, "black"),
        borderColor: "default"
      }
    },
    link: {
      variant: "buttons.terciary",
      border: "0px solid",
      color: "inuOrange",
      fontWeight: "book",
      ":hover": {
        bg: "transparent"
      }
    }
  },
  modal: {
    verySmall: {
      width: "450px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    small: {
      width: "500px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    medium: {
      width: "800px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    large: {
      width: "1140px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    veryLarge: {
      width: "1350px",
      maxHeight: "95vh",
      maxWidth: "95vw"
    },
    full: {
      height: "100%",
      width: "100%"
    }
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  forms: {
    checkbox: {
      mr: 1,
      color: "springGreen",
      "input:checked + &": {
        display: "block"
      },
      "input:checked ~ &": {
        color: "springGreen"
      },
      "input:focus ~ &": {
        color: "springGreen"
      }
    },
    radio: {
      mr: 1,
      color: "springGreen",
      "input:checked + &": {
        display: "block"
      },
      "input:checked ~ &": {
        color: "springGreen"
      },
      "input:focus ~ &": {
        color: "springGreen"
      }
    }
  },
  textVeterinaryCover: {
    px: [2, 2, "100px"],
    py: [4, 4, 8],
    fontSize: ["30px", "30px", "32px"],
    lineHeight: "30px"
  },
  textRecruitingSitters: {
    pl: [2, 2, "280px"],
    pr: [2, 2, "300px"],
    py: [4, 4, 4],
    fontSize: ["30px", "30px", "32px"],
    lineHeight: "30px"
  },
  headline: {
    box: {
      veterinarycover: {
        px: [2, 2, "100px"],
        py: [4, 4, 8]
      },
      recruitingsitters: {
        pl: [2, 2, "280px"],
        pr: [2, 2, "300px"],
        py: [4, 4, 4]
      },
      about: {
        px: [2, 2, "290px"],
        py: ["40px", "40px", "8"]
      },
      adviceforsitters: {
        px: [2, 2, "200px"],
        pt: [4, 4, 8],
        pb: ["10px", "10px", 3]
      },
      ourservices: {
        px: [2, 2, "200px"],
        pt: [4, 4, "80px"],
        pb: [4, 4, "76px"]
      }
    },
    title: {
      fontSize: ["30px", "30px", "32px"],
      lineHeight: 1.2
    },
    subtitle: {
      pt: ["12px", "12px", "1"],
      fontSize: "3",
      lineHeight: ["30px", "30px", "32px"]
    },
    boxButton: {
      mt: "35px"
    },
    button: {
      px: "140px",
      py: "2",
      width: ["100%", "initial"],
      fontSize: ["18px", "24px"],
      fontWeight: 500,
      lineHeight: ["24px", "30px"]
    }
  },
  heroLanding: {
    mobile: {
      flex: {
        flexWrap: "wrap",
        alignSelf: "flex-end",
        p: "2"
      },
      text: {
        variant: "text.h1",
        color: "samoyedWhite"
      }
    },
    nonMobile: {
      flex: {
        m: "auto",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      },
      text: {
        fontWeight: "700",
        fontSize: "64px",
        lineHeight: "70px",
        textAlign: "center",
        color: "samoyedWhite",
        px: "30px"
      }
    },
    boxButton: {
      p: ["16px", "20px"],
      right: 0,
      bottom: 0,
      left: 0,
      position: ["fixed", "initial"],
      zIndex: 1,
      backgroundColor: ["white", "transparent"],
      borderTop: ["0.5px solid grey", "none"]
    },
    button: {
      px: ["0", "140px"],
      py: 2,
      width: "100%",
      borderRadius: "6px",
      variant: "primary",
      fontSize: ["18px", "24px"],
      fontWeight: 500,
      lineHeight: ["24px", "30px"]
    }
  },
  textWithImg: {
    flex: {
      flexWrap: "wrap",
      flexGrow: 1,
      width: "100%",
      color: "samoyedWhite"
    },
    boxTextLeft: {
      width: ["100%", "100%", "50%"],
      pt: [4, 4, 8],
      pb: [3, 3, 4],
      pl: [2, 2, 8],
      pr: [2, 2, 4]
    },
    boxTextRight: {
      width: ["100%", "100%", "50%"],
      pt: [4, 4, 8],
      pb: [3, 3, 4],
      pl: [2, 2, 8],
      pr: [2, 2, 4]
    },
    title: {
      mb: "4"
    },
    text: {
      whiteSpace: "pre-line"
    },
    boxImg: {
      width: ["100%", "100%", "50%"],
      height: ["320px", "320px", "inherit"],
      flexGrow: 1
    },
    img: {
      width: "100%",
      height: "100%",
      loading: "eager",
      priority: true,
      layout: "fill",
      objectFit: "cover"
    }
  },
  stepsGroup: {
    flex: {
      veterinarycover: {
        flexWrap: "wrap",
        px: ["2", "2", "104.5px"],
        py: ["4", "4", "64px"]
      },
      recruitingsitters: {
        flexWrap: "wrap",
        px: ["2", "2", "115.5px"],
        py: ["4", "4", "64px"]
      },
      advicefor: {
        flexWrap: "wrap",
        px: ["2", "2", "340px"],
        pt: ["4", "4", "7"],
        pb: ["44px", "44px", "7"]
      },
      dogminder: {
        flexWrap: "wrap",
        px: ["2", "2", "245px"],
        py: ["4", "4", "4"]
      }
    },
    heading: {
      fontSize: ["30px", "30px", "32px"],
      lineHeight: ["30px", "30px", "32px"],
      fontWeight: 600
    },
    mainBoxLeft: {
      width: ["100%", "100%", "50%"],
      mb: ["4", "4", 0],
      pr: [0, 0, "5"]
    },
    mainBoxRight: {
      width: ["100%", "100%", "50%"],
      pl: [0, 0, "5"],
      borderLeft: [0, 0, "solid 1px lightGrey"]
    },
    mainBoxUnique: {
      width: ["100%", "100%", "100%"]
    }
  },
  simpleStep: {
    flex: {
      mt: "3",
      alignItems: "center"
    },
    boxIcon: {
      width: "24px",
      height: "24px"
    },
    boxText: {
      width: "90%"
    },
    text: {
      pl: "12px"
    },
    stepIcon: {
      fontSize: "11px",
      color: "white",
      textAlign: "center",
      backgroundColor: "inuOrange",
      borderRadius: "circle",
      width: "24px",
      height: "24px",
      lineHeight: "24px"
    }
  },
  howToSteps: {
    mainBox: {
      px: [2, 2, "100px"],
      pt: [4, 4, 8],
      pb: "40px",
      backgroundColor: "springGreen",
      color: "white"
    },
    mainBoxDefault: {
      px: [2, 2, "100px"],
      pt: [4, 4, 8],
      pb: "24px",
      backgroundColor: "springGreen",
      color: "white"
    },
    title: {
      mb: [0, 0, "4"],
      fontSize: ["30px", "30px", "32px"],
      lineHeight: ["30px", "30px", "32px"]
    },
    hr: {
      height: "3px",
      position: "absolute",
      border: 0,
      bg: "aquamarine",
      zIndex: 0,
      marginTop: "24px"
    },
    mainFlex: {
      flexWrap: "wrap",
      position: "relative",
      zIndex: 1,
      pb: "8"
    },
    mainFlexDefault: {
      flexWrap: "wrap",
      position: "relative",
      zIndex: 1,
      pb: "3"
    },
    feature: {
      flexMobile: {
        alignItems: "flex-start",
        mt: "3",
        ml: "1"
      },
      flex: {
        flexDirection: "column"
      },
      stepNumber: {
        margin: [0, 0, "auto"],
        fontSize: ["11px", "11px", "24px"],
        color: "white",
        textAlign: "center",
        backgroundColor: "aquamarine",
        borderRadius: "circle",
        width: [24, 24, 52],
        height: [24, 24, 52],
        lineHeight: ["24px", "24px", "52px"]
      },
      infoBox: {
        width: "90%",
        pl: ["12px", "12px", "0px"],
        pt: ["0px", "0px", "24px"]
      },
      infoTitle: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: ["24px", "24px", "20px"]
      },
      infoDesc: {
        fontWeight: 400,
        lineHeight: "20px",
        pt: ["8px", "8px", "4px"]
      }
    },
    boxButton: {
      textAlign: "center",
      pt: "12px",
      pb: "4"
    },
    mainButton: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 500,
      borderRadius: "6px",
      px: "140px",
      py: "16px",
      backgroundColor: "samoyedWhite",
      color: "springGreen"
    }
  },
  stepDetail: {
    box: {
      width: "75%",
      pl: "12px",
      flexDirection: "column",
      justifyContent: "center"
    },
    heading: {
      color: "springGreen",
      fontSize: "16px",
      lineWeight: "24px",
      fontWeight: "600"
    },
    headingBenefits: {
      color: "samoyedWhite",
      fontSize: "24px",
      lineWeight: "30px",
      fontWeight: "400",
      pb: "4px"
    },
    text: {
      mt: "12px"
    }
  },
  featuredIn: {
    mainBox: {
      px: "2",
      pt: "2",
      pb: "1",
      backgroundColor: "#eee",
      color: "veryDarkGray"
    },
    heading: {
      textAlign: "center"
    },
    flex: {
      alignItems: "center",
      justifyContent: "space-around",
      flexWrap: ["wrap", "nowrap"]
    },
    boxImg: {
      width: ["50%", "100%"],
      px: "3",
      py: "2",
      textAlign: "center",
      verticalAlign: "center",
      "> span": {
        position: "unset !important",
        maxHeight: "32px"
      },
      ".featured-in-image": {
        "object-fit": "contain",
        width: "100% !important",
        position: "relative !important",
        height: "unset !important"
      },
      img: {
        maxHeight: "32px !important"
      }
    }
  },
  landingHeading: {
    fontSize: ["40px", "40px", "48px"],
    lineHeight: ["40px", "40px", "48px"],
    textAlign: "center",
    px: "2",
    py: ["12px", "12px", "24px"]
  },
  benefits: {
    ourservices: {
      box: {
        px: ["2", "2", "8"],
        py: ["4", "4", "8"],
        bg: "springGreen"
      },
      title: {
        fontSize: ["30px", "30px", "32px"],
        lineHeight: ["30px", "30px", "32px"],
        mb: "4",
        color: "samoyedWhite"
      }
    }
  },
  userDogs: {
    mainFlex: {
      flexDirection: ["column", "column", "row"],
      flexWrap: "wrap"
    },
    mainBox: {
      width: ["100%", "100%", "33.33%"]
    },
    linkFlex: {
      my: 1,
      ml: 1,
      fontSize: 3,
      alignItems: "center"
    },
    linkFlexBox: {
      ml: 4
    },
    linkText: {
      color: "springGreen",
      ml: 1,
      cursor: "pointer"
    },
    dogTile: {
      mainBox: {
        px: 2,
        py: 3,
        cursor: "pointer",
        color: "samoyedWhite"
      },
      flex: {
        flexWrap: "wrap"
      }
    },
    newDog: {
      mainBox: {
        width: "100%",
        p: 1
      },
      wrapBox: {
        pt: "198px",
        border: "1px dashed",
        borderColor: "springGreen",
        backgroundColor: "samoyedWhite",
        borderRadius: "double",
        overflow: "hidden",
        position: "relative"
      },
      detailBox: {
        position: "absolute",
        top: "72px",
        right: 0,
        left: 0,
        cursor: "pointer"
      },
      flex: {
        flexDirection: "column",
        alignItems: "center"
      },
      text: {
        mt: 1,
        color: "springGreen",
        fontSize: 3
      }
    }
  },
  bookingTile: {
    mainFlex: {
      color: "samoyedWhite",
      fontWeight: "bold",
      padding: 2,
      alignItems: "flex-start"
    },
    boxService: {
      flex: "0 0 auto"
    },
    boxIcon: {
      position: "relative",
      backgroundColor: "samoyedWhite",
      padding: [4, 4, 3, 4],
      svg: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: 6
      }
    },
    boxInfo: {
      flexGrow: 1,
      pl: 1,
      fontWeight: "regular",
      ".ellipsis": {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    boxPrice: {
      flex: "0 0 auto",
      alignSelf: "flex-end",
      flexGrow: 1
    },
    flexPrice: {
      width: 1,
      fontSize: 4,
      fontWeight: "book",
      flexDirection: "row-reverse",
      mr: [6, 6, 0]
    },
    labelBox: {
      py: "2px",
      px: 1,
      backgroundColor: "samoyedWhite",
      borderRadius: "circle",
      marginTop: 1,
      marginRight: 1,
      fontSize: 1
    }
  },
  numbersGrid: {
    default: {
      mainBox: {
        py: 4,
        px: [2, 2, "20%"]
      },
      title: {
        color: "inuOrange",
        mb: "36px",
        textAlign: ["left", "left", "center"],
        fontSize: ["30px", "30px", "32px"]
      },
      grid: {
        flexWrap: "wrap"
      },
      tile: {
        flexDirection: "column",
        textAlign: "center",
        px: 1,
        py: 2
      },
      tileBox: {
        width: ["100%", "100%", "60%"],
        mx: "auto"
      },
      tileTitle: {
        color: "inuOrange",
        fontSize: ["30px", "30px", "48px"],
        lineHeight: ["30px", "30px", "72px"],
        fontWeight: "medium",
        mb: ["4px", "4px", 0]
      },
      tileDesc: {
        fontWeight: "book"
      }
    },
    card: {
      mainBox: {
        width: "282px",
        py: 4,
        px: [2, 2, 2],
        backgroundColor: "inuOrange"
      },
      title: {
        color: "samoyedWhite",
        mb: 3,
        textAlign: ["left", "left", "center"],
        fontSize: ["30px", "30px", "32px"]
      },
      grid: {
        flexWrap: "wrap"
      },
      tile: {
        flexDirection: "column",
        textAlign: "center",
        py: "12px"
      },
      tileTitle: {
        color: "samoyedWhite",
        fontSize: ["30px", "30px", "25px"],
        lineHeight: ["30px", "30px", "32px"],
        fontWeight: 600,
        mb: ["4px", "4px", 0]
      },
      tileDesc: {
        color: "samoyedWhite",
        fontWeight: 500
      }
    }
  },
  optionsGrid: {
    mainBox: {
      px: [4, 4, "304px"],
      py: 4,
      backgroundColor: "aquamarine"
    },
    title: {
      color: "samoyedWhite",
      textAlign: "center",
      mb: 3
    },
    gridFlex: {
      flexDirection: ["column", "column", "row"],
      rowGap: [8, 8, 0],
      columnGap: [0, 0, 8],
      justifyContent: "center"
    },
    tile: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: ["100%", "100%", "384px"],
      minWidth: ["100%", "100%", "384px"]
    },
    tileText: {
      py: 2,
      width: "60%",
      color: "samoyedWhite",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: ["regular", "regular", "medium"],
      textAlign: "center"
    },
    tileButton: {
      backgroundColor: "springGreen",
      borderRadius: "6px",
      color: "samoyedWhite",
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: "book",
      width: ["256px", "256px", "332px"]
    }
  }
};

export default theme;

/* eslint-disable @typescript-eslint/camelcase */
import cookie from "cookie";
import Router from "next/router";
import validateLogin from "./validate-login";
import { setUser as setUserForErrorTracking } from "./error-tracking";
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult
} from "next";
import { ApolloClient, gql } from "@apollo/client";
import { ParsedUrlQuery } from "querystring";

import { CurrentUserFragment } from "../fragments/index.fragments";
import { initializeApollo } from "./apolloClient";
import { UserType } from "../enums";
import { setUserProperty } from "../lib/event-tracking";
import { Href, pushRoute } from "./routing";
import { addQueryParamToPath } from "./utils";

interface LoginProps {
  token: string;
  user: any;
  pathToRedirect: string;
  apolloClient: ApolloClient<object>;
  // locale: string;
}

interface LogoutProps {
  client: any;
  locale: string;
}

interface AuthOptions {
  authenticationRequired: boolean;
  authenticationUnallowed: boolean;
  authenticationAsCaregiver: boolean;
}

export function setupTooling(user: any) {
  // GTM
  setUserProperty("userId", user.id);
  setUserProperty("userType", user.type);

  // Intercom
  // Remember that Intercom is initialized in GTM
  // First update done on AuthContext
  window.Intercom("update", {
    email: user.email,
    user_id: user.id,
    user_hash: user.intercomUserHash
  });

  // Bugsnag
  // Remember that Bugsnag is initialized in GTM
  setUserForErrorTracking(user);
}

export function login({
  token,
  user,
  pathToRedirect,
  apolloClient
}: LoginProps) {
  // Store the token in cookie
  const cookieAge = 30 * 24 * 60 * 60;
  document.cookie = cookie.serialize("token", token, {
    maxAge: cookieAge,
    path: "/"
  });
  // Store the userId on a cookie to be read by Google Tag Manager
  document.cookie = cookie.serialize("userId", user.id, {
    maxAge: cookieAge,
    path: "/"
  });

  // TODO: reconsider whether this query is still necessary here
  const currentUserQuery = gql`
    query getCurrentUser {
      currentUser {
        ...CurrentUserParts
      }
    }
    ${CurrentUserFragment}
  `;
  apolloClient.writeQuery({
    query: currentUserQuery,
    data: { currentUser: user }
  });

  setupTooling(user);

  Router.push(pathToRedirect);
}

export function logout({ locale, client }: LogoutProps) {
  //root domain cookies
  ["token", "accountMode", "userId"].forEach(name => {
    const emptyCookie = cookie.serialize(name, "", {
      expires: new Date(0),
      path: "/"
    });
    document.cookie = emptyCookie;
  });

  //global cookies
  ["a_token", "accountMode"].forEach(name => {
    const emptyCookie = cookie.serialize(name, "", {
      expires: new Date(0),
      path: "/",
      domain: `.${window.location.hostname}`
    });
    document.cookie = emptyCookie;
  });

  setUserProperty("userId", null);
  setUserProperty("userType", null);

  window.Intercom("shutdown");

  client.clearStore().then(() => {
    Router.push("/login");
  });
}

async function auth(ctx: GetServerSidePropsContext) {
  let loggedInUser = null;
  const { req } = ctx;

  // TODO: change locale here
  const apolloClient = initializeApollo("es", req);

  loggedInUser = await validateLogin(apolloClient);

  if (loggedInUser) {
    //setting user for error report
    setUserForErrorTracking(loggedInUser);
  }

  return loggedInUser;
}

// Here we extend the Next.js GetServerSideProps function type
// to include a new prop `CurrentUser` in the `context`
type GetServerSidePropsWithCurrentUser<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery
> = (
  context: GetServerSidePropsContext<Q> & { currentUser: any }
) => Promise<GetServerSidePropsResult<P>>;

/**
 * A wrapper for a page's exported getServerSideProps that
 * provides the authed currentUser as a prop.
 * See this discussion on how best to use getServerSideProps
 * with a higher-order component pattern:
 * https://github.com/vercel/next.js/discussions/10925#discussioncomment-12471
 **/
export const withAuth = (
  authOptions = {
    authenticationRequired: true,
    authenticationUnallowed: false,
    authenticationAsCaregiver: false
  },
  redirect = {
    permanent: false,
    destination: "/login"
  }
) => (getServerSidePropsFn?: GetServerSidePropsWithCurrentUser) => async (
  ctx: GetServerSidePropsContext
) => {
  const currentUser = await auth(ctx);
  const { req, resolvedUrl } = ctx;

  // If req.url contains /_next/ the navigation is happening client side
  // so we use resolvedUrl for the redirection value
  let redirectTo: string;
  if (req.url.indexOf("/_next/") !== -1) {
    redirectTo = resolvedUrl;
  } else {
    redirectTo = req.url;
  }

  if (
    (authOptions.authenticationRequired && !currentUser) ||
    (authOptions.authenticationUnallowed && currentUser) ||
    (authOptions.authenticationAsCaregiver &&
      currentUser?.type != UserType.CAREGIVER)
  ) {
    return {
      redirect: {
        permanent: redirect.permanent,
        destination: addQueryParamToPath(
          redirect.destination,
          "redirect",
          redirectTo
        )
      }
    };
  }

  let returnData = { props: { currentUser } };

  // Evaluate the composed getServerSideProps().
  if (getServerSidePropsFn) {
    // Add the CurrentUser to Next.js context so pages can use
    // it in `getServerSideProps`, if needed.
    const getServerSidePropsResult = await getServerSidePropsFn({
      ...ctx,
      currentUser
    });

    if ("props" in getServerSidePropsResult) {
      returnData = {
        props: { ...returnData.props, ...getServerSidePropsResult.props }
      };
    }
    if ("redirect" in getServerSidePropsResult) {
      returnData["redirect"] = getServerSidePropsResult.redirect;
    }
  }
  return returnData;
};

export function withoutAuth() {
  return withAuth(
    {
      authenticationRequired: false,
      authenticationUnallowed: true,
      authenticationAsCaregiver: false
    },
    { destination: "/", permanent: false }
  );
}

export function withCaregiverAuth() {
  return withAuth({
    authenticationRequired: true,
    authenticationUnallowed: false,
    authenticationAsCaregiver: true
  });
}

export function withAuthForSignup() {
  return withAuth(
    {
      authenticationRequired: true,
      authenticationUnallowed: false,
      authenticationAsCaregiver: false
    },
    { destination: "/signup?type=User", permanent: false }
  );
}

import { useContext } from "react";
import NumberFormat from "react-number-format";
import Input from "./Input";
import { getCurrencySymbol, shouldPrefixCurrency } from "../../lib/utils";
import { LocaleContext } from "../locale";
import { CurrencyIso } from "../../interfaces";

interface Props {
  iso: CurrencyIso;
  value: string | number;
  onChange: (value) => void;
  [x: string]: any;
}

interface InputComponentProps {
  [x: string]: any;
}
const InputComponent = (props: InputComponentProps) => {
  return (
    <Input
      sx={{
        marginRight: "1",
        marginLeft: "1",
        display: "block",
        cursor: "pointer",
        borderRadius: "default",
        width: "96px",
        height: "48px",
        fontSize: 0,
        lineHeight: "48px",
        textAlign: "center",
        color: "labradorblack",
        border: "default",
        paddingLeft: 0,
        paddingRight: 0,
        boxShadow: "inputFocus",
        ":focus": {
          borderColor: "gray",
          outline: "none",
          boxShadow: "inputFocus",
          "~i": {
            opacity: 1
          }
        }
      }}
      {...props}
    ></Input>
  );
};
const CurrencyInput = ({ iso, value, onChange, ...rest }: Props) => {
  const { locale } = useContext(LocaleContext);
  const prefixCurrency = shouldPrefixCurrency(locale);

  return (
    <NumberFormat
      value={value}
      displayType={"input"}
      thousandSeparator={true}
      prefix={prefixCurrency ? getCurrencySymbol(iso) : ""}
      suffix={!prefixCurrency ? getCurrencySymbol(iso) : ""}
      customInput={InputComponent}
      onValueChange={onChange}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      {...rest}
    />
  );
};

export default CurrencyInput;

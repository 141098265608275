import { Box, Flex } from "rebass/styled-components";
import { Icon } from ".";
import styled, { css } from "styled-components";

interface Props {
  children: React.ReactNode;
  [x: string]: any;
}

const BadgeBase = (props: Props) => {
  const { children, icon, sx, ...rest } = props;

  return (
    <Box
      as="span"
      variant="badge"
      sx={{
        ...sx
      }}
      {...rest}
    >
      <Flex alignItems="center" justifyContent="center" as="span" height="100%">
        {icon && <Icon name={icon} color="darkGray" />}
        <Box
          as="span"
          flex={1}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

const Badge: typeof BadgeBase = styled(BadgeBase)`
  ${({ round }) =>
    round &&
    css`
      border-radius: 100px;
    `}
  ${({ primary, off, theme }) =>
    primary &&
    css`
      color: black;
      background-color: ${!off ? theme.colors.pugYellow : "intitial"};
      border-color: ${theme.colors.pugYellow};
      border: 1;
    `}
  ${({ secondary, theme }) =>
    secondary &&
    css`
      color: black;
      background-color: ${theme.colors.lightGray};
      border: none;
    `}
    ${({ tertiary, off, theme }) =>
      tertiary &&
      css`
        color: ${!off ? theme.colors.samoyedWhite : theme.colors.labradorBlack};
        background-color: ${!off ? theme.colors.springGreen : "intitial"};
        border-color: ${theme.colors.springGreen};
        border: 1;
      `}
`;

export default Badge;

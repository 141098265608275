import ContentLoader from "react-content-loader";
import { Flex, Box } from "rebass/styled-components";
import { useRef, useEffect, useState } from "react";

interface Props {
  count?: number;
  size?: number;
  space?: number;
  [x: string]: any;
}
const Line = ({ count, size, space, ...rest }: Props) => {
  let control = Array.from(Array(count).keys());
  const [width, setWidth] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  return (
    <Flex ref={ref} {...rest}>
      <Box width="100%" overflow="hidden">
        <ContentLoader
          height={count * (size + space) - space}
          width={width}
          speed={2}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          {control.map(step => (
            <rect
              key={step}
              x="0"
              y={step * (size + space)}
              width="100%"
              height={size.toString()}
            />
          ))}
        </ContentLoader>
      </Box>
    </Flex>
  );
};

Line.defaultProps = {
  count: 1,
  size: 16,
  space: 16
};

export default Line;

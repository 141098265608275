import { Button as BaseButton } from "rebass/styled-components";

interface Props {
  size?: string;
  variant?: string;
  [x: string]: any;
}

const Button = (props: Props) => {
  const { size, variant, sx, ...rest } = props;

  return (
    <BaseButton
      variant={variant}
      sx={{
        variant: `buttons.${size}`,
        ...sx
      }}
      {...rest}
    />
  );
};

Button.defaultProps = {
  size: "medium"
};

export default Button;

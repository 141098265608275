import { FormikErrors } from "formik";
import { ErrorProp } from "..";

export const toErrorProp = (
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[]
): ErrorProp =>
  ({
    hasError: !!error,
    message: error
  } as ErrorProp);

import { withTheme } from "styled-components";
import dynamic from "next/dynamic";
import { useMemo } from "react";

interface Props {
  name: string;
  [x: string]: any;
}

const Icon = (props: Props) => {
  const { theme, name, color, fontSize, sx, ...rest } = props;
  const IconComponent: any = useMemo(
    () =>
      dynamic(() => import(`./icons/${name}.svg`), {
        ssr: true
      }),
    [name]
  );
  return (
    <IconComponent
      color={theme.colors[color || "labradorBlack"]}
      {...rest}
      fontSize={theme.fontSizes[fontSize] || "1em"}
    />
  );
};
export default withTheme(Icon);

import { Flex } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";

import ButtonSelector from "./ButtonSelector";

interface Props {
  selected: any;
  onSelect: (value: string) => void;
}

function DogSizeSelector({ selected, onSelect }: Props) {
  const { t } = useTranslation("common");

  const dogSizes = [
    {
      value: "small",
      iconName: "dog_s",
      fontSize: 2,
      label: t("dog_s")
    },
    {
      value: "medium",
      iconName: "dog_m",
      fontSize: 2,
      label: t("dog_m")
    },
    {
      value: "large",
      iconName: "dog_l",
      fontSize: 5,
      label: t("dog_l")
    },
    {
      value: "giant",
      iconName: "dog_xl",
      fontSize: 6,
      label: t("dog_xl")
    }
  ];

  return (
    <Flex>
      <ButtonSelector
        items={dogSizes}
        selected={selected}
        onSelect={onSelect}
      />
    </Flex>
  );
}

export default DogSizeSelector;

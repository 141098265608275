import React from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import useCollapse from "react-collapsed";
import { Icon } from ".";

interface Item {
  icon?: string;
  title: string;
  description: string;
}

interface AdditionalInfo {
  iconColor?: string;
  textColor?: string;
  expandIcon?: string;
  retractIcon?: string;
  expandIconColor?: string;
}

interface Props {
  items: Item[];
  additionalInfo?: AdditionalInfo;
}

interface AccordionItemProps {
  item: Item;
  hideBorderBottom?: boolean;
  additionalInfo: AdditionalInfo;
}

function AccordionItem({
  item,
  hideBorderBottom = false,
  additionalInfo
}: AccordionItemProps) {
  const {
    iconColor,
    textColor,
    expandIcon,
    retractIcon,
    expandIconColor
  } = additionalInfo;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultOpen: false
  });

  return (
    <Box
      {...getToggleProps()}
      pb={1}
      sx={{
        borderBottom: hideBorderBottom ? undefined : "default",
        cursor: "pointer"
      }}
    >
      <Box>
        <Flex pb={1} pt={2} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            {item.icon && (
              <Box mr={1}>
                <Icon name={item.icon} color={iconColor} fontSize={5} />
              </Box>
            )}

            <Text variant="h4" fontWeight="bold" color={textColor}>
              {item.title}
            </Text>
          </Flex>

          {!isExpanded ? (
            <Icon name={expandIcon} color={expandIconColor}></Icon>
          ) : (
            <Icon name={retractIcon} color={expandIconColor}></Icon>
          )}
        </Flex>

        <Box {...getCollapseProps()}>
          <Box pb={1}>
            <Text color={textColor}>{item.description}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Accordion({ items, additionalInfo }: Props) {
  const defaultInfo = {
    iconColor: "inuOrange",
    textColor: "labradorBlack",
    expandIcon: "caret-down",
    retractIcon: "caret-up",
    expandIconColor: "springGreen"
  };
  const itemAdditionalInfo = Object.assign({}, defaultInfo, additionalInfo);

  return (
    <Box>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        return (
          <Box key={index}>
            <AccordionItem
              item={item}
              hideBorderBottom={isLastItem}
              additionalInfo={itemAdditionalInfo}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default Accordion;

import { useContext } from "react";
import { Box } from "rebass/styled-components";
import { LocaleContext } from "../locale";
import { CurrencyIso } from "../../interfaces";
import { formatCurrency } from "../../lib/utils";

interface Props {
  amount: string | number;
  currency: CurrencyIso;
  stripZeros?: boolean;
}

const Money = ({ currency, amount, stripZeros = false }: Props) => {
  const { locale } = useContext(LocaleContext);

  let moneyToS = formatCurrency(currency, amount, locale, stripZeros);

  return <Box as="span">{moneyToS}</Box>;
};

export default Money;

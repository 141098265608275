import { Box } from "rebass/styled-components";
import { Select, ErrorProp } from "..";
import useTranslation from "next-translate/useTranslation";

interface Props {
  selected: any;
  onSelect: (any) => void;
  error: ErrorProp;
}

function DogGenderSelector({ selected, onSelect, error }: Props) {
  const { t } = useTranslation("common");
  const options = [
    {
      value: "male",
      label: t("male")
    },
    {
      value: "female",
      label: t("female")
    }
  ];
  return (
    <Box>
      <Select
        id="dog_gender"
        name="dog_gender"
        placeholder={t("dog_gender")}
        value={
          selected
            ? options.filter(gender => {
                return gender.value == selected;
              })[0]
            : null
        }
        onChange={onSelect}
        options={options}
        error={error}
      />
    </Box>
  );
}

export default DogGenderSelector;

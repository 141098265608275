import { Text, Box } from "rebass/styled-components";
import { Label } from "@rebass/forms/styled-components";
import { forwardRef, ReactElement, RefObject } from "react";
import { FormikErrors } from "formik";
import React from "react";

interface InputWrapProps {
  label?: string;
  children: ReactElement;
  notLabeled?: boolean;
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
}

// eslint-disable-next-line react/display-name
const InputWrap = forwardRef(
  (props: InputWrapProps, ref: RefObject<HTMLElement>) => {
    const { label, notLabeled, children, error } = props;
    return (
      <Box ref={ref} mt={3}>
        <Box
          sx={{
            input: {
              borderColor: error ? "errorRed" : "gray"
            }
          }}
        >
          {label ? (
            <>
              <Label
                mb={1}
                flexWrap="wrap"
                sx={{
                  color: error ? "errorRed" : "labradorBlack"
                }}
              >
                <Text>{label}</Text>
                {!notLabeled && (
                  <Box mt={1} width={1}>
                    {children}
                  </Box>
                )}
              </Label>
              {notLabeled && (
                <Box mt={1} width={1}>
                  {children}
                </Box>
              )}
            </>
          ) : (
            children
          )}
          {error && (
            <Text
              sx={{
                color: "errorRed"
              }}
            >
              {error}
            </Text>
          )}
        </Box>
      </Box>
    );
  }
);

InputWrap.defaultProps = {
  notLabeled: false
};

export default InputWrap;

import { Box } from "rebass/styled-components";
import Icon from "./Icon";

interface Props {
  icon?: string;
  action?: React.ReactNode;
  iconColor?: string;
  iconRight?: any;
  sx?: any;
  placeholder?: string;
  [x: string]: any;
  isReadOnly?: boolean;
}
const Input = (props: Props) => {
  const {
    sx,
    icon,
    action,
    iconColor,
    iconRight,
    placeholder,
    isReadOnly = false,
    ...rest
  } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        autoComplete="off"
        as="input"
        readOnly={isReadOnly}
        placeholder={placeholder}
        sx={{
          color: isReadOnly ? "hsl(0,0%,60%)" : "auto",
          bg: "samoyedWhite",
          border: "default",
          m: 0,
          py: 0,
          px: 2,
          pl: (icon ? 5 : 2) + (iconRight && icon ? 1 : 0),
          pr: iconRight ? 5 : 2,
          height: "48px",
          lineHeight: "100%",
          fontSize: 2,
          borderRadius: "default",
          width: "100%",
          transition: "box-shadow 0.2s ease 0s",
          ":focus": {
            borderColor: isReadOnly ? "hsl(0,0%,60%)" : "springGreen",
            outline: "none",
            boxShadow: "inputFocus",
            "~i": {
              opacity: 1
            }
          },
          ":disabled": {
            backgroundColor: "lightGray"
          },
          "::-webkit-inner-spin-button,::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          },
          ...sx
        }}
        {...rest}
      />
      {action && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            zIndex: 2,
            button: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: "100%"
            }
          }}
        >
          {action}
        </Box>
      )}
      <Box
        as="i"
        sx={{
          position: "absolute",
          left: 2,
          top: 2,
          pointerEvents: "none",
          zIndex: 2,
          transition: "opacity 0.2s ease 0s",
          opacity: 0.5
        }}
      >
        {icon && <Icon color={iconColor} name={icon} />}
      </Box>
      {iconRight && (
        <Box
          as="i"
          sx={{
            position: "absolute",
            right: 2,
            top: 2,
            pointerEvents: "none",
            zIndex: 1
          }}
        >
          <Icon color={iconColor} name={iconRight} />
        </Box>
      )}
    </Box>
  );
};

Input.defaultProps = {
  placeholder: ""
};
export default Input;

import { Radio as BaseRadio, Label } from "@rebass/forms/styled-components";

interface Props {
  color: string;
  theme?: any;
  checked?: boolean;
  name: string;
  onChange?: any;
  value: any;
  sx?: any;
  id?: string;
}

// We need to use the withTheme HoC until this bug gets fixed
// https://github.com/rebassjs/rebass/issues/660
const Radio = (props: Props) => {
  const { ...rest } = props;
  return (
    <Label width="fit-content">
      <BaseRadio {...rest} />
    </Label>
  );
};

Radio.defaultProps = {
  color: "springGreen"
};

export default Radio;

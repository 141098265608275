import { Box } from "rebass/styled-components";

interface Props {
  [x: string]: any;
}
const BorderedBox = (props: Props) => (
  <Box variant="variants.borderedBox" {...props}></Box>
);

export default BorderedBox;

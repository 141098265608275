import { Flex, Box } from "rebass/styled-components";
import { Icon } from "../common";
import useTranslation from "next-translate/useTranslation";


const SearchNearbySelector = ({ onSelect }) => {
  const { t } = useTranslation("common");

  const searchNearby = () => {
    const sucess = pos => {
      onSelect({
        pid: null,
        near: t("nearby"),
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      });
    };

    const error = code => {};
    navigator.geolocation.getCurrentPosition(sucess, error, {
      maximumAge: 10000
    });
  };

  return (
    <Flex
      alignItems="center"
      my={1}
      sx={{
        color: "labradorBlack",
        cursor: "pointer",
        ".ellipsis": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
        svg: {
          flexShrink: 0
        }
      }}
      onClick={searchNearby}
    >
      <Icon name="location" color="springGreen" />
      <Box ml={1} className="ellipsis">
        {t("searchNearby")}
      </Box>
    </Flex>
  );
};

export default SearchNearbySelector;

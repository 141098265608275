import { Flex, Box } from "rebass/styled-components";
import ContentLoader from "react-content-loader";
import { useEffect, useRef, useState } from "react";

interface Props {
  height: number;
  [x: string]: any;
}

const Rectangle = ({ height, ...rest }: Props) => {
  const [width, setWidth] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  return (
    <Flex ref={ref} {...rest}>
      <Box width="100%" overflow="hidden">
        <ContentLoader
          speed={2}
          width={width}
          height={height}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
        </ContentLoader>
      </Box>
    </Flex>
  );
};

export default Rectangle;

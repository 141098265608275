import { Flex, Box, Image, Text } from "rebass/styled-components";
import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { Dog } from "../../generated/graphql";
import { Modal, Button } from "../common";
import CreateOrUpdateDog from "../common/dogs/CreateOrUpdateDog";
import Link from "./Link";
import { useIsMobile } from "../../lib/media";

interface Props {
  dog: Omit<Dog, "user">;
  size: {};
  editable: boolean;
  showProfileLink?: boolean;
}

interface ModalProps {
  dog: Omit<Dog, "user">;
  visible: boolean;
  dismiss: () => void;
}

const Spacer = () => (
  <Text as="span" ml="4px" mr="4px">
    &#183;
  </Text>
);

const EditDogModal = ({ dog, visible, dismiss }: ModalProps) => {
  const isMobileViewport = useIsMobile();

  return (
    <Modal
      open={visible}
      onClose={dismiss}
      onOutsideClick={dismiss}
      size={isMobileViewport ? "full" : undefined}
      HeaderButton={isMobileViewport && <></>}
    >
      <CreateOrUpdateDog dog={dog} afterSave={dismiss} slimView />
    </Modal>
  );
};

const DogCard = ({ dog, size, editable, showProfileLink }: Props) => {
  const { t } = useTranslation("common");

  const [modalVisible, setModalVisible] = useState(false);
  const showEditModal = () => setModalVisible(true);
  const hideEditModal = () => setModalVisible(false);

  const gender = {
    male: 9794,
    female: 9792
  };

  return (
    <>
      <Flex mt={2}>
        <Link
          href={{ pathname: "/dogprofile", query: { id: dog.slug || dog.id } }}
        >
          <Box>
            <Image
              src={dog.avatar?.circle}
              alt={`${dog.name} avatar`}
              height="auto"
              ml="auto"
              mr="auto"
              display="inherit"
              variant="avatar"
              width={size == "large" ? "48px" : "24px"}
            />
          </Box>
        </Link>
        <Flex flexWrap="wrap" ml={2}>
          <Box width={1}>
            {size == "large" && (
              <Box ml={2}>
                <Text fontWeight="book">{dog.name}</Text>
                {dog.breed ? (
                  <Text as="span">
                    {dog.breed?.tName}
                    <Spacer />
                  </Text>
                ) : null}
                {dog.size ? (
                  <Text as="span">
                    <Trans i18nKey={`common:${dog.size}`} />
                    <Spacer />
                  </Text>
                ) : null}
                <Text as="span">{dog.tAge}</Text>
              </Box>
            )}
            {size == "small" && (
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                <Text
                  as="span"
                  sx={{
                    fontSize: 1,
                    lineHeight: "body",
                    letterSpacing: "body"
                  }}
                >
                  {dog.name}
                </Text>
                <Spacer />
                {dog.size ? (
                  <Text
                    as="span"
                    sx={{
                      fontSize: 1,
                      lineHeight: "body",
                      letterSpacing: "body"
                    }}
                  >
                    <Trans i18nKey={`common:${dog.size}`} />
                    <Spacer />
                  </Text>
                ) : null}
                {dog.tAgeShort && (
                  <Text
                    as="span"
                    sx={{
                      fontSize: 1,
                      lineHeight: "body",
                      letterSpacing: "body"
                    }}
                  >
                    {dog.tAgeShort}
                    <Spacer />
                  </Text>
                )}
                {dog.gender && gender[dog.gender.toLowerCase()] && (
                  <Text
                    as="span"
                    sx={{
                      fontSize: 2,
                      lineHeight: "body",
                      letterSpacing: "body"
                    }}
                  >
                    {String.fromCharCode(gender[dog.gender.toLowerCase()])}
                  </Text>
                )}
              </Box>
            )}
          </Box>
          {showProfileLink && (
            <Link
              href={{
                pathname: "/dogprofile",
                query: { id: dog.slug || dog.id }
              }}
              target={`dog_${dog.id}`}
            >
              <Text color="springGreen" fontWeight="book" fontSize={1}>
                {t("viewCareInfo")}
              </Text>
            </Link>
          )}
        </Flex>
        {editable && (
          <Flex
            sx={{ justifyContent: "flex-end", flexGrow: 1 }}
            onClick={showEditModal}
          >
            <Button
              variant="link"
              color="springGreen"
              p={0}
              m={0}
              mr={1}
              height={0}
              minHeight={0}
            >
              {t("edit")}
            </Button>
          </Flex>
        )}
      </Flex>
      {editable && (
        <EditDogModal
          dog={dog}
          visible={modalVisible}
          dismiss={hideEditModal}
        />
      )}
    </>
  );
};

DogCard.defaultProps = {
  size: "large",
  editable: false,
  showProfileLink: false
};

export default DogCard;

import useTranslation from "next-translate/useTranslation";
import Select from "../Select";

interface Props {
  value: boolean;
  onSelect: (event) => void;
}

const BooleanSelect = ({ value, onSelect }: Props) => {
  const { t } = useTranslation("common");

  const options = [
    {
      value: true,
      label: t("yes")
    },
    {
      value: false,
      label: t("no")
    }
  ];

  return (
    <Select
      value={
        value != null
          ? options.filter(optionValue => {
              return optionValue.value == value;
            })[0]
          : null
      }
      onChange={onSelect}
      options={options}
    />
  );
};

export default BooleanSelect;

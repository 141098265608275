
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AppProps } from "next/app";
import Head from "next/head";
import cookie from "cookie";
import { ApolloProvider } from "@apollo/client";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { useApollo } from "../lib/apolloClient";
import theme from "../lib/theme";
import LocaleProvider from "../components/locale";
import "intl-pluralrules";

import TagManager from "../lib/react-gtm";

import { AuthProvider } from "../components/common/AuthContext";
import { GooglePlacesProvider } from "../components/google-places/GooglePlacesProvider";
import MetaProvider, { getMetaPropsForLocale } from "../components/meta";
import { trackEvent } from "../lib/event-tracking";

// Global CSS
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-image-crop/dist/ReactCrop.css";
import { useEffect } from "react";
import { leaveBreadcrumb } from "../lib/error-tracking";
import { getCountryForLocale } from "../lib/utils";
import Bugsnag from "@bugsnag/js";
import React from "react";
import CustomError from "./_error";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: Geomanist, sans-serif;
  }
  body {
    font-size: 16px;
    line-height: 1.43;
    margin: 0;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React)!;

function MyApp(props: AppProps) {
  const { Component, pageProps, router } = props;
  const locale = router.locale as string;
  const country = getCountryForLocale(locale);
  const isBrowser = typeof window !== "undefined";
  const loggedInAs = isBrowser && !!cookie.parse(document.cookie)["a_token"];
  const accountMode = isBrowser
    ? cookie.parse(document.cookie)["accountMode"]
    : undefined;

  const apolloClient = useApollo(locale, pageProps);

  const authProviderInitialValue = {
    userMenuVisibility: false,
    accountMode: accountMode,
    loggedInAsVisilibity: loggedInAs
  };

  const metaProps = getMetaPropsForLocale(locale);
  const metaProvider = {
    ...metaProps,
    url: new URL(router.asPath, metaProps.domain).href
  };

  const localeProvider = {
    country,
    locale
  };

  const handleRouteChangeComplete = (url: string) => {
    // Set a 1s timeout to wait for the document.title to change
    // https://github.com/zeit/next.js/issues/6025
    setTimeout(() => {
      trackEvent("route_change_complete");
    }, 1000);

    const metadata = {
      country,
      locale,
      domain: metaProps.domain
    };

    leaveBreadcrumb(url, metadata, "navigation");
  };

  // componentDidMount equivalent
  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the events with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          name="viewport"
        />
      </Head>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={CustomError}>
        <ThemeProvider theme={theme}>
          <MetaProvider value={metaProvider}>
            <LocaleProvider value={localeProvider}>
              <ApolloProvider client={apolloClient}>
                <AuthProvider value={authProviderInitialValue}>
                  <GooglePlacesProvider
                    apiKey={process.env.googlePlacesApiKey as string}
                  >
                    <GoogleReCaptchaProvider
                      language={locale}
                      reCaptchaKey={process.env.googleReCapthaV3ApiKey}
                    >
                      {TagManager.initialize()}
                      <Component {...pageProps} />
                    </GoogleReCaptchaProvider>
                  </GooglePlacesProvider>
                </AuthProvider>
              </ApolloProvider>
            </LocaleProvider>
          </MetaProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  
// This component defines the main app layout
import { ReactNode, ReactElement, useContext, useEffect } from "react";
import { Box, Flex } from "rebass/styled-components";
import { createGlobalStyle } from "styled-components";

import Head from "next/head";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import Footer from "./Footer";
import UserMenu from "./UserMenu";
import useTranslation from "next-translate/useTranslation";

import { LocaleContext } from "./locale";
import { MetaContext } from "./meta";
import Router from "next/router";
import { useAuthState, useAuthDispatch } from "./common/AuthContext";
import { useIsMobile } from "../lib/media";
import publicRuntimeConfig from "../lib/public-runtime-config";
import { Translate } from "next-translate";
import {
  CurrentUser,
  useGetCurrentUserForLayoutQuery,
  useGetCurrentUserNotificationsQuery
} from "../generated/graphql";
import { setUser } from "../lib/error-tracking";
import TopBanner from "./home/TopBanner";
interface Props {
  children: ReactNode;
  withTransparentHeader: boolean;
  hideFooter?: boolean;
  immersive: boolean;
  displayReCaptcha?: boolean;
  hideHeader?: boolean;
  title: string;
  description?: string;
  PreHeader?: ReactElement;
  displayBannerHome?: boolean;
  deviceType?: string;
}

interface MetaProps {
  locale: string;
  t: Translate;
  url: string;
  image?: string;
}

interface TwitterProps {
  twitterCardTitle?: string;
  twitterCardDescription?: string;
  image?: string;
  locale: string;
  t: Translate;
}

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

const Twitter = ({
  twitterCardTitle,
  twitterCardDescription,
  image,
  t
}: TwitterProps) => {
  return (
    <>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={t("twitterAccount")} />
      <meta name="twitter:creator" content={t("twitterAccount")} />
      <meta
        name="twitter:title"
        content={twitterCardTitle ? twitterCardTitle : t("title")}
      />
      <meta
        name="twitter:description"
        content={
          twitterCardDescription ? twitterCardDescription : t("description")
        }
      />
      <meta name="twitter:image:src" content={image} />
    </>
  );
};

const Facebook = ({ locale, image }: MetaProps) => {
  return (
    <>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Gudog" />
      <meta property="og:locale" content={locale} />
      <meta property="fb:app_id" content={publicRuntimeConfig.facebookAppId} />
      <meta property="fb:admins" content="100000094355899" />
    </>
  );
};

const OpenGraph = ({ t, url, image }: MetaProps) => {
  return (
    <>
      <meta property="og:title" content={t("title")} />
      <meta property="og:description" content={t("description")} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
    </>
  );
};

const Mobile = () => {
  return (
    <>
      <meta
        property="al:ios:app_store_id"
        content={publicRuntimeConfig.mobileIosAppStoreId}
      />
      <meta property="al:ios:app_name" content="Gudog" />
      <meta
        property="al:android:package"
        content={publicRuntimeConfig.mobileAppPackage}
      />
      <meta property="al:android:app_name" content="Gudog" />
    </>
  );
};

const OverwriteRecaptchaGlobalStyle = createGlobalStyle`
  body{
    .grecaptcha-badge {
      visibility: visible;
    }
  }
`;

const Layout = ({
  children,
  withTransparentHeader,
  immersive,
  hideFooter,
  hideHeader,
  title,
  description,
  PreHeader,
  displayReCaptcha,
  displayBannerHome = false,
  deviceType
}: Props) => {
  const { accountMode } = useAuthState();
  const isMobile = useIsMobile(deviceType);

  const { data } = useGetCurrentUserForLayoutQuery({
    skip: !accountMode, // Skip query if user is not logged in
    onCompleted: data => {
      if (data?.currentUser) setUser(data.currentUser as CurrentUser);
    }
  });

  useGetCurrentUserNotificationsQuery({
    skip: !accountMode, // Skip query if user is not logged in
    pollInterval: 60000
  });

  const { t } = useTranslation("header");
  const { locale } = useContext(LocaleContext);
  const { ogLocale, url, domain } = useContext(MetaContext);

  const renderFooter = !immersive && !hideFooter;
  const renderReCaptcha = immersive || displayReCaptcha;

  const authDispatch = useAuthDispatch();

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      authDispatch({ type: "hideUserMenu" });
    };

    Router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      Router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    window.onload = () => {
      if (isMobile && typeof window != "undefined" && window.Intercom) {
        window.Intercom("update", { hide_default_launcher: true });
      }
    };
  }, [isMobile]);

  // FOR THE DOGLEY BANNER
  const localeCountry = locale || ("" as string);
  const showBanner =
    ["da", "no", "sv"].includes(localeCountry) &&
    ((displayBannerHome && isMobile) || !displayBannerHome);

  return (
    <>
      {renderReCaptcha && <OverwriteRecaptchaGlobalStyle />}
      <Flex flexWrap="wrap">
        <Head>
          <title>{title}</title>
          {/* <link rel="canonical" href={url} /> */}
          <meta name="author" content="Gudog" />
          <meta
            name="description"
            content={description ? description : t("description")}
          />
          <meta
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
            name="viewport"
          />
          <meta charSet="utf-8" />
          <link rel="icon" href="/images/favicon.ico" />
          {/* TODO: add missing meta tags */}
          <style>
            {`
            * {
              box-sizing: border-box;
              font-family: Geomanist, sans-serif;
            }
            body {
              font-size: 16px;
              line-height: 1.43;
              margin: 0;

            }
          `}
          </style>

          <link rel="stylesheet" href="/fonts/Geomanist.css" />
          <link
            rel="preload"
            href="/fonts/Geomanist-Book.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/Geomanist-Medium.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="preload"
            href="/fonts/Geomanist-Regular.woff2"
            as="font"
            crossOrigin=""
          />

          {Twitter({
            t: t,
            locale: locale,
            image: new URL("/images/meta/twitter.jpg", domain).href
          })}
          {Facebook({ locale: ogLocale, t: t, url: url })}
          {OpenGraph({
            locale: ogLocale,
            t: t,
            url: url,
            image: new URL("/images/meta/facebook.jpg", domain).href
          })}
          {Mobile()}
        </Head>

        {data && data.currentUser && (
          <UserMenu currentUser={data.currentUser} />
        )}

        <ProgressBar fixed={!immersive} />

        {showBanner && (
          <TopBanner
            localeCountry={localeCountry}
            isMobile={isMobile}
            isHome={displayBannerHome}
          />
        )}

        {!hideHeader && (
          <Header
            transparent={showBanner ? false : withTransparentHeader}
            immersive={immersive}
            currentUser={data && data.currentUser}
            PreHeader={PreHeader}
          />
        )}

        <Box width={1}>{children}</Box>

        {renderFooter && <Footer isMobile={isMobile} />}
      </Flex>
    </>
  );
};

Layout.defaultProps = {
  withTransparentHeader: false,
  immersive: false,
  hideFooter: false,
  displayReCaptcha: false
};

export default Layout;

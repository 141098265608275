import Button from "./Button";
import Checkbox from "./Checkbox";
import CoverImage from "./CoverImage";
import Heading from "./Heading";
import Hide from "./Hide";
import Icon from "./Icon";
import ServiceIcon from "./ServiceIcon";
import Input from "./Input";
import OutsideClickHandler from "./OutsideClickHandler";
import Pagination from "./Pagination";
import Rating from "./Rating";
import RatingSelector from "./RatingSelector";
import TextArea from "./TextArea";
import Badge from "./Badge";
import Select from "./Select";
import Modal from "./Modal";
import Card from "./Card";
import ExpDateInput from "./ExpDateInput";
import CVCInput from "./CVCInput";
import CardNumberInput from "./CardNumberInput";
import Radio from "./Radio";
import AutocompleteInput from "./AutocompleteInput";
import Tile from "./Tile";
import AddCouponModal from "./AddCouponModal";
import CurrencyInput from "./CurrencyInput";
import InputInt from "./InputInt";
import FilterButton from "./FilterButton";
import Money from "./Money";
import Logo from "./Logo";
import Link from "./Link";
import TermsOfServiceAgreement from "./TermsOfServiceAgreement";
import { FullLoader } from "./loader";
import DogCard from "./DogCard";
import BorderedBox from "./BorderedBox";
import Accordion from "./Accordion";

export interface ErrorProp {
  hasError: boolean;
  message: string;
}

export const defaultErrorProp: ErrorProp = {
  hasError: false,
  message: ""
};

export {
  Button,
  Checkbox,
  CoverImage,
  Heading,
  Hide,
  Input,
  OutsideClickHandler,
  Icon,
  ServiceIcon,
  Pagination,
  Rating,
  RatingSelector,
  TextArea,
  Badge,
  Select,
  Modal,
  Card,
  ExpDateInput,
  CVCInput,
  CardNumberInput,
  AutocompleteInput,
  Radio,
  Tile,
  AddCouponModal,
  CurrencyInput,
  InputInt,
  FilterButton,
  Money,
  Logo,
  Link,
  TermsOfServiceAgreement,
  FullLoader,
  DogCard,
  BorderedBox,
  Accordion
};

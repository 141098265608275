import { Box } from "rebass/styled-components";
import { Label } from "@rebass/forms/styled-components";
import useTranslation from "next-translate/useTranslation";

interface Props {
  sx?: any;
  getExpiryDateProps: any;
  [x: string]: any;
  onChange?: (value) => void;
  onBlur?: (value) => void;
}
const ExpDateInput = (props: Props) => {
  const { sx, getExpiryDateProps, onChange, onBlur, ...rest } = props;
  const { t } = useTranslation("common");

  return (
    <Box sx={{ position: "relative" }}>
      <Label mb={1} htmlFor="frmCCExp">
        {t("expiryDate")}
      </Label>
      <Box
        {...getExpiryDateProps({
          onChange: onChange,
          onBlur: onBlur
        })}
        as="input"
        sx={{
          bg: "samoyedWhite",
          border: "default",
          m: 0,
          py: 0,
          px: 2,
          pl: 2,
          pr: 2,
          height: "48px",
          lineHeight: "100%",
          fontSize: 2,
          borderRadius: "default",
          width: "100%",
          transition: "box-shadow 0.2s ease 0s",
          ":focus": {
            borderColor: "springGreen",
            outline: "none",
            boxShadow: "inputFocus",
            "~i": {
              opacity: 1
            }
          },
          ...sx
        }}
        {...rest}
      />
    </Box>
  );
};

ExpDateInput.defaultProps = {};
export default ExpDateInput;
